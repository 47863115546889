import React, { useEffect, useState, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/modal_style.css'
import '../components/style.css'
import {
    Title,
    useDataProvider,
} from "react-admin";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { Card, } from "@mui/material";
import ReactToPrint from 'react-to-print'
export const TasimaEvrak2 = (props) => {
    let componentRef = useRef();
    const dataProvider = useDataProvider();
    const [urunler, setUrunler] = useState(null)
    const [urunlers, setUrunlers] = useState(null)
    const [data, setData] = useState(null);
    const [formName, setFormName] = useState(null)
    const [FormDate, setFormDate] = useState(null)
    const [DriverName, setDriverName] = useState(null)
    const [ReceiverCompanyAddress, setReceiverCompanyAddress] = useState(null)
    const [ReceiverCompanyCity, setReceiverCompanyCity] = useState(null)
    const [ReceiverCompanyName, setReceiverCompanyName] = useState(null)
    const [ReceiverCompanyTaxAdministration, setReceiverCompanyTaxAdministration] = useState(null)
    const [ReceiverCompanyTaxNumber, setReceiverCompanyTaxNumber] = useState(null)
    const [ReceiverCountry, setReceiverCountry] = useState(null)
    const [SenderCompanyAddress, setSenderCompanyAddress] = useState(null)
    const [SenderCompanyCity, setSenderCompanyCity] = useState(null)
    const [SenderCompanyDocumentNumberUATF, setSenderCompanyDocumentNumberUATF] = useState(null)
    const [SenderCompanyName, setSenderCompanyName] = useState(null)
    const [SenderCompanyTaxAdministration, setSenderCompanyTaxAdministration] = useState(null)
    const [SenderCompanyTaxNumber, setSenderCompanyTaxNumber] = useState(null)
    const [SenderCountry, setSenderCountry] = useState(null)
    const [SenderResponsibleName, setSenderResponsibleName] = useState(null)
    const [TMFBNumber, setTMFBNumber] = useState(null)
    const [TotalScore, setTotalScore] = useState(null)
    const [TransporterTitle, setTransporterTitle] = useState(null)
    const [VehicleContainer1Number, setVehicleContainer1Number] = useState(null)
	const [VehicleContainer2Number, setVehicleContainer2Number] = useState(null)
	const [VehicleTrailer1ContainerNumber, setVehicleTrailer1ContainerNumber] = useState(null)
	const [VehicleTrailer2ContainerNumber, setVehicleTrailer2ContainerNumber] = useState(null)
	const [VehiclePlate, setVehiclePlate] = useState(null)
	const [VehicleTrailer1Plate, setVehicleTrailer1Plate] = useState(null)
	const [VehicleTrailer2Plate, setVehicleTrailer2Plate] = useState(null)
    const [Exemption, setExemption] = useState(null)

    

    //const {componentRef} = "";
    useEffect(() => {
        if (!data)
            dataProvider.TasimaEvraklariGetir(pid)
                .then(({ data }) => {

                    console.log("data", data[0])
                    setData(data[0]);
                    setFormName(data[0].FormName);
                    setFormDate(data[0].FormDate);
                    setDriverName(data[0].DriverName);
                    setReceiverCompanyAddress(data[0].ReceiverCompanyAddress);
                    setReceiverCompanyCity(data[0].ReceiverCompanyCity);
                    setReceiverCompanyName(data[0].ReceiverCompanyName);
                    setReceiverCompanyTaxAdministration(data[0].ReceiverCompanyTaxAdministration);
                    setReceiverCompanyTaxNumber(data[0].ReceiverCompanyTaxNumber);
                    setReceiverCountry(data[0].ReceiverCountry.ENName);
                    setSenderCompanyAddress(data[0].SenderCompanyAddress);
                    setSenderCompanyCity(data[0].SenderCompanyCity);
                    setSenderCompanyDocumentNumberUATF(data[0].SenderCompanyDocumentNumberUATF);
                    setSenderCompanyName(data[0].SenderCompanyName);
                    setSenderCompanyTaxAdministration(data[0].SenderCompanyTaxAdministration);
                    setSenderCompanyTaxNumber(data[0].SenderCompanyTaxNumber);
                    setSenderCountry(data[0].SenderCountry.ENName);
                    setSenderResponsibleName(data[0].SenderResponsibleName);
                    setTMFBNumber(data[0].TMFBNumber);
                    setTotalScore(data[0].TotalScore);
                    setTransporterTitle(data[0].TransporterTitle);
                    setVehicleContainer1Number(data[0].VehicleContainer1Number)
					setVehicleContainer2Number(data[0].VehicleContainer2Number)
					setVehicleTrailer1ContainerNumber(data[0].VehicleTrailer1ContainerNumber)
					setVehicleTrailer2ContainerNumber(data[0].VehicleTrailer2ContainerNumber)
					setVehiclePlate(data[0].VehiclePlate)
					setVehicleTrailer1Plate(data[0].VehicleTrailer1Plate)
					setVehicleTrailer2Plate(data[0].VehicleTrailer2Plate)
                    
                    if(data[0].Exemption1136 != null){

                        setExemption(data[0].Exemption1136)
                    }
                    
                    var loopData = ''
                    var i = 0;
                    
                    while (i < data[0].Products.length) {
                        var paketgrubu = "-"
                        var pakettype = "-"
                        var klassCode = "-"
                        var TransportCategory = "-"
                        var PackageCount = data[0].Products[i].PackageCount
                        var score = data[0].Products[i].DangerScore
                        var TotalNetWeight = data[0].Products[i].TotalNetWeight

                        if (data[0].Products[i].TypeOfPackage !== null) {
                            paketgrubu = data[0].Products[i].TypeOfPackage
                        }
                        if (data[0].Products[i].PackageType !== null) {
                            pakettype = data[0].Products[i].PackageType
                        }
                        if (data[0].Products[i].UNCode.Class !== null) {
                            klassCode = data[0].Products[i].UNCode.Class
                        }
                        if (data[0].Products[i].TransportCategory !== null) {
                            TransportCategory = data[0].Products[i].TransportCategory
                        }
                        loopData += `<tr>
                        <td colspan="2">
                            <p>  ADR AND IMDG CODE ${klassCode}</p>
                        </td>
                        <td colspan="2">
                            <p> ${pakettype}</p>
                        </td>
                        <td colspan="2">
                            <p>${PackageCount}</p>
                        </td>
                        <td colspan="2">
                            <p> -</p>
                        </td>
                        <td colspan="2">
                            <p align="center">${TotalNetWeight}</p>
                        </td>
                        <td colspan="2">
                            <p align="center">-</p>
                        </td>
                        <td colspan="2">
                            <p align="center">${TransportCategory} </p>
                        </td>
                        <td colspan="2">
                            <p align="center">${score}</p>
                        </td></tr>`;
                        i++;
                    }
                    setUrunler(loopData);
                    var loopDatas = '';
                    var j= 0;
                    while (j < data[0].Products.length) {
                        var tunelcodu = "-"
                        var klaskodu = "-"
                        var unnum = "-"
                        var unadi = "-"
                        var g = 1;
					var tags = '';
                    //var tagss ='';
					while(g< data[0].Products[j].UNCode.Tag.length){
						console.log("TAG=",data[0].Products[j].UNCode.Tag.length);
                        //var tag1 = data[0].Products[j].UNCode.Tag[0].Definition.Name ;
						tags += "("+ data[0].Products[j].UNCode.Tag[g].Definition.Name +"),";
						g++;
                        //console.log("bum->",tag1);
                        //tags=tag1 + tagss;

					}

                        if (data[0].Products[j].UNCode.CarryingTunnelCode != null) {
                            tunelcodu = data[0].Products[j].UNCode.CarryingTunnelCode
                        }
                        if (data[0].Products[j].UNCode.ClassCode != null) {
                            klaskodu = data[0].Products[j].UNCode.Class
                        }
                        if (data[0].Products[j].UNCode.UNNumber != null) {
                            unnum = data[0].Products[j].UNCode.UNNumber
                        }
                        if (data[0].Products[j].ProperShipmentName != null) {
                            unadi = data[0].Products[j].ProperShipmentName
                        }
                        loopDatas += `<tr valign="center">
                        <td colspan="2">
                            <span align="center">ADR  AND IMDG CODE ${klaskodu}</span>
                        </td>
                        <td colspan="6">
                            <p>${unnum} ${unadi}, ${klaskodu}, ${tags},${tunelcodu}, P.G NON,(D)(LQ)</p>
                        </td>
                        </tr>`;
                        j++;
                    }
                    setUrunlers(loopDatas);

                });
    });
    const { pid } = getQueryParams();
    //console.log("bakalim =", formName)
    return (

        <Card style={{ marginTop: "1em" }} >
            <Container className="justify-content-md-center">

                <Col md="auto">
                    <Title title={formName} />
                    <div style={{ marginTop: "1em" }} className="yazdir" ref={(el) => (componentRef = el)}>
                        <table border="1" cellSpacing="0" cellPadding="5" width="100%" className="cizik">
                            <tr>
                                <td colspan="4" valign="top" >
                                    <p align="center">
                                        <b>Dangerous Goods Form</b></p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="4" height="35" valign="top" ><p>
                                    This form may be used as dangerous goods declarations as it meets the requirements of ADR 5.4 and SOLAS 74, chapter VII,
                                    regulation 5; MARPOL 73/78, Annex III, regulation 4.</p>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td colspan="2">
                                    <p><strong>1.Shipper</strong></p>
                                    <span>{SenderCompanyName}<br/>{SenderCompanyAddress} {SenderCompanyCity}/{SenderCountry}</span>
                                    <span>{SenderCompanyTaxAdministration} /{SenderCompanyTaxNumber}</span>
                                </td>
                                <td colspan="2" >
                                    <p> <strong>3.Shipper's Reference</strong> </p>
                                </td>
                            </tr>
                           
                            <tr valign="top">
                                <td colspan="2"  height="15" ><p>
                                    <span><strong>2.Consignee</strong></span></p>
                                    <span>{ReceiverCompanyName}<br/>{ReceiverCompanyAddress} {ReceiverCompanyCity}/{ReceiverCountry}</span>
                                    <span>{ReceiverCompanyTaxAdministration}/{ReceiverCompanyTaxNumber}</span>
                                </td>
                                <td  colspan="2">
                                    <p>
                                       <strong>4.Document Number</strong> 
                                    </p>
                                    <span>{TMFBNumber}</span>
                                </td>
                            </tr>
                           </table>
                           
                           <p align="center" style={{ fontSize: 9 }}> <b>5.Dangerous Goods Product Details</b></p>
                        <table border="1" cellSpacing="0" cellPadding="0" width="100%" className="cizik">
                            <tbody dangerouslySetInnerHTML={{ __html: urunlers }}></tbody>
                        </table>
                        <p align="center" style={{ fontSize: 9 }}><b>6.Package Description</b></p>

                        <table border="1" cellSpacing="0" cellPadding="5" width="100%" className="cizik">

                            <tr valign="top">
                                <td colspan="2"><p>
                                    <strong>ADR-IMO Class</strong></p>
                                </td>
                                <td colspan="2"><p>
                                <strong>Package Type</strong></p>
                                </td>
                                <td colspan="2" >
                                    <p> <strong>Number of Outer Packaging( BOX )</strong></p>
                                </td>
                                <td colspan="2" >
                                    <p> <strong>Number of Inner Packages(PIECE)</strong></p>
                                </td>
                                <td colspan="2" width="91" >
                                    <p >  <strong>Net mass (kg)</strong></p>
                                </td>
                                <td colspan="2">
                                    <p align="center">  <strong>Gross mass (kg)</strong></p>
                                </td>
                                <td colspan="2">
                                    <p align="center">  <strong>Transport Category</strong></p>
                                </td>
                                <td colspan="2" >
                                    <p align="center">  <strong>Danger Score</strong></p>
                                </td>
                            </tr>
                            <tbody  dangerouslySetInnerHTML={{ __html: urunler }}>
                            </tbody>
                            <tr>
                                <td colspan="16" ><p>
                                    ☐ Carriage under the exemption of ADR 1.1.3.6. ({TotalScore} POINTS)</p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="16"><p>
                                    ☐</p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="16"><p>
                                    ☐</p>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="16">
                                    <p align="center"> <b>7.Container and Vehicle Details</b></p>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td colspan="3" width="218" height="27" ><p>
                                <strong>Container and Vehicle Plate No</strong></p>
                                    <span>{VehicleContainer1Number}, {VehicleContainer2Number}, {VehicleTrailer1ContainerNumber}, {VehicleTrailer2ContainerNumber} / {VehiclePlate}, {VehicleTrailer1Plate}, {VehicleTrailer2Plate}</span>
                                </td>
                                <td colspan="3" width="231">
                                    <p> <strong>Vehicle Driver and Signature</strong></p>
                                    <span>{DriverName}</span>
                                </td>
                                <td  colspan="10" ><p align="center">
                                <strong>8.RECEIVING ORGANIZATION RECEIPT</strong></p>
                                    <p align="center">
                                        Received the above number of packages/ containers/trailers in apparent good order and condition, unless stated hereon: <br/>RECEIVING ORGANISATION REMARKS
                                    </p>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td colspan="8" width="369" height="20" >
                                    <p> <strong>9.Name of Company / <span>Signature</span></strong></p>
                                    <span>{TransporterTitle}</span>
                                </td>
                                <td colspan="8" width="369" ><p>
                                <strong>10.
                                    This form delivery / <span>Signature</span></strong></p>
                                    <span>{SenderCompanyName}</span>
                                </td>
                            </tr>
                            <tr valign="top">
                                <td colspan="8" width="369" height="12" ><p>
                                    <br />

                                </p>
                                </td>
                                <td colspan="8" width="369" ><p>
                                <strong><span>Date:</span></strong></p><span>{FormDate}</span>
                                </td>
                            </tr>
                          
                        </table>
                        <p class="western" style={{ fontSize: 9 }}> <strong>* DANGEROUS GOODS:</strong></p>
                        <p class="western" style={{ fontSize: 9 }}>You must specify: Proper Shipping Name, hazard class, UN No., packing group, (where assigned) marine pollutant and observe the
                            mandatory requirements under applicable national and international
                            governmental regulations. For the purpose of the IMDG Code see
                            5.4.1.1.** For the purposes of the IMDG Code, see 5.4.2.. For the
                            purpose of the ADR see 5.4.1.</p>
                    </div>
                    <ReactToPrint
                        trigger={() => <Button>Print this out!</Button>}
                        content={() => componentRef}
                    />
                </Col>

            </Container>
        </Card>
    );
};

const getQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.href);
    return {
        pid: urlParams.get("pid"),
    };
};


import './App.css';
import * as React from "react";
import { Admin, fetchUtils, Resource, CustomRoutes } from 'react-admin';
import { MyMenu } from './components/menu';
import authProvider from './admin/authProvider';
import simpleRestProvider from 'ra-data-json-server';
import { Route } from 'react-router-dom';
import MyLayout  from './components/mylayout';
import MyLoginPage from './MyLoginPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/style.css';
import { KaraDeniz } from './content/karadeniz';
import { KaraDeniz2 } from './content/karadeniz2';
import { UlkeList } from './admin/country';
import { EvrakListesi } from './content/evraklistesi';
import { YuklemeSertifika } from './content/yuklemesertifika';
import { YuklemeSertifika2 } from './content/yuklemesertifika2';
import { YuklemeSertifikaListesi } from './content/yuklemesertifikalar';
import { TasimaEvrak } from './content/tasimaevraki';
import { TasimaEvrak2 } from './content/tasimaevrak2';
import { TasimaEvrakListesi } from './content/tasimaevraklistesi';

const dataProvider = simpleRestProvider('https://uranus.logfiks.com/api');
const httpClient = fetchUtils.fetchJson;
const apiUrl = 'https://uranus.logfiks.com/api';
const jeton = localStorage.getItem("jwtToken");
//console.log("baktt",jeton);
const requestHeaders = (
    new Headers({
        Accept: 'application/json',
    })) ;
    requestHeaders.set('Content-Type', 'application/json');
  requestHeaders.set('Authorization', jeton);
  
const AppdataProvider = {...dataProvider,
  

  async DataCountry(){
    return httpClient(`${apiUrl}/Data/Country`,{  headers: requestHeaders }).then(({ headers, json }) => ({
      data: json,
  }
  ));
  },
  async DataVehicleType(){
  
    return httpClient(`${apiUrl}/Data/Vehicle/Types`,{  headers: requestHeaders }).then(({ headers, json }) => ({
      data: json,
  }
  ));
  },
  async DataVehicleContainerType(){
    return httpClient(`${apiUrl}/Data/Vehicle/ContainerTypes`,{  headers: requestHeaders }).then(({ headers, json }) => ({
      data: json,
  }
  ));
  }, 
  async DataTrailerTypes(){
    return httpClient(`${apiUrl}/Data/Vehicle/TrailerTypes`,{  headers: requestHeaders }).then(({ headers, json }) => ({
      data: json,
  }
  ));
  },
  async DataUNCode(){
    return httpClient(`${apiUrl}/Data/UNCode?Select=1`,{  headers: requestHeaders }).then(({ headers, json} ) => ({
      datas: json,
  
  }));
  },
  async Company(){
    
    return httpClient(`${apiUrl}/Company`, {  headers: requestHeaders }).then(({ headers, json} ) => ({
      data: json,
  }));
  },
  
  async CompanyAddress(){
    
    return httpClient(`${apiUrl}/Company/Address`, {  headers: requestHeaders }).then(({ headers, json} ) => ({
      data: json,
  }));
  },
  async AddressType(){
    
    return httpClient(`${apiUrl}/Company/AddressType`, {  headers: requestHeaders }).then(({ headers, json} ) => ({
      data: json,
  }));
  },
  
  async UsersType(){
    
    return httpClient(`${apiUrl}/Company/User/List`, {  headers: requestHeaders }).then(({ headers, json} ) => ({
      data: json,
  }));
  },
  
  async Evraklar(){
    
    return httpClient(`${apiUrl}/Form/LandAndSeaForm`, {  headers: requestHeaders }).then(({ headers, json} ) => ({
      data: json,
  }));
  },
  
  async TasimaEvraklari(){
    
    return httpClient(`${apiUrl}/Form/TransportDocumentForm`, {  headers: requestHeaders }).then(({ headers, json} ) => ({
      data: json,
  }));
  },
  
  async YuklemeSertifikalari(){
    
    return httpClient(`${apiUrl}/Form/DangerousGoodsPackingCertificateForm`, {  headers: requestHeaders }).then(({ headers, json} ) => ({
      data: json,
  }));
  },
  async YuklemeSertifikalariGetir(e){
    
    return httpClient(`${apiUrl}/Form/DangerousGoodsPackingCertificateForm/${e}`, {  headers: requestHeaders }).then(({ headers, json} ) => ({
      data: json,
  }));
  },
  async TasimaEvraklariGetir(e){
    
    return httpClient(`${apiUrl}/Form/TransportDocumentForm/${e}`, {  headers: requestHeaders }).then(({ headers, json} ) => ({
      data: json,
  }));
  },
  async DataVehicleSubType(e){
    return httpClient(`${apiUrl}/Data/Vehicle/SubTypes/${e}`,{  headers: requestHeaders }).then(({ headers, json }) => ({
      datavehicle: json,
  }
  ));
  },
  async KaraDenizGetir(e){
    
    return httpClient(`${apiUrl}/Form/LandAndSeaForm/${e}`, {  headers: requestHeaders }).then(({ headers, json} ) => ({
      data: json,
  }));
  },
  async DataUNCodeGetir(e){
    return httpClient(`${apiUrl}/Data/UNCode/${e}`,{  headers: requestHeaders }).then(({ headers, json} ) => ({
      dataUn: json,
  
  }));
  },
  
  async AddUser(data ) {
    const url = `${apiUrl}/${"Company/User"}`;
    return httpClient(url, {
      method: "POST",
      body: data,
    }).then(({ json }) => ({
      data: { ...json },
    }));
  },
  async Register(data ) {
    const url = `${apiUrl}/${"User/Register"}`;
    return httpClient(url, {
      method: "POST",
      body: data,
    }).then(({ json }) => ({
      data: { ...json },
    }));
  },
  async AddFAddress(data ) {
    const url = `${apiUrl}/${"Company/User"}`;
    return httpClient(url, {
      method: "POST",
      body: data,
    }).then(({ json }) => ({
      data: { ...json },
    }));
  },
  async KaraDeniz(data ) {
    const url = `${apiUrl}/${"Form/LandAndSeaForm"}`;
    //console.log("basilacak=",data);
    return httpClient(url, {
      method: "POST",
      headers: requestHeaders,
      body: data,
    }).then(({ json }) => ({
      data: { ...json },
    }));
  },
  async TasimaEvraki(data ) {
    const url = `${apiUrl}/${"Form/TransportDocumentForm"}`;
    //console.log("basilacak=",data);
    return httpClient(url, {
      method: "POST",
      headers: requestHeaders,
      body: data,
    }).then(({ json }) => ({
      data: { ...json },
    }));
  },
  async TehlikeHesabı(data ) {
    const url = `${apiUrl}/${"Form/TransportDocumentForm/CalculateScore"}`;
    //console.log("Tbasilacak=",data);
    return httpClient(url, {
      method: "POST",
      headers: requestHeaders,
      body: data,
    }).then(({ json }) => ({
      data: { ...json },
    }));
  },
  
  async YuklemeSertifikasi(data) {
    const url = `${apiUrl}/${"Form/DangerousGoodsPackingCertificateForm"}`;
    return httpClient(url, {
      method: "POST",
      headers: requestHeaders,
      body: data,
    }).then(({ json }) => ({
      data: { ...json },
    }));
  },

  }

const App = () => (
  <Admin title="LogFiks"   dataProvider={AppdataProvider} requireAuth authProvider={authProvider} layout={MyLayout} menu={MyMenu} loginPage={MyLoginPage}>
         <Resource name="KaraDeniz" >
         <Route path="/KaraDeniz" element="" />
         </Resource>
         
          <CustomRoutes >
          <Route path="/AddUsers"  element="" />
          <Route path="/KaraDeniz2"  element={<KaraDeniz2/>} />
          <Route path="/YuklemeSertifika2"  element={<YuklemeSertifika2/>} />
          <Route path="/KaraDeniz" element={<KaraDeniz/>}/>
          <Route path="/EvrakListesi" element={<EvrakListesi/>}/>
          <Route path="/TaşımaEvrakListesi" element={<TasimaEvrakListesi/>}/>
          <Route path="/TasimaEvrak2" element={<TasimaEvrak2/>}/>
          <Route path="/YuklemeSertifika" element={<YuklemeSertifika/>}/>
          <Route path="/YuklemeSertifikaListesi" element={<YuklemeSertifikaListesi/>}/>
          <Route path="/TasimaEvrak" element={<TasimaEvrak/>}/>
          <Route path="/Country" element={<UlkeList/>}/>
          
          </CustomRoutes>
  </Admin>
  );
  

export default App;
import React, { useEffect, useState, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/modal_style.css'
import '../components/style.css'
import {
	Title,
	useDataProvider,
} from "react-admin";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card, } from "@mui/material";
import ReactToPrint from 'react-to-print'
export const KaraDeniz2 = (props) => {

	const dataProvider = useDataProvider();
	const [data, setData] = useState(null);
	const [formName, setFormName] = useState(null)
	const [PreparedByDefinition, setPreparedByDefinition] = useState(null)
	const [SenderTitle, setSenderTitle] = useState(null)
	const [SenderCountryId, setSenderCountryId] = useState(null)
	const [SenderAddress, setSenderAddress] = useState(null)
	const [TransportDocumentNumber, setTransportDocumentNumber] = useState(null)
	const [ShippersReference, setShippersReference] = useState(null)
	const [FreightForwardersReference, setFreightForwardersReference] = useState(null)
	const [ConsigneeTitle, setConsigneeTitle] = useState(null)
	const [ConsigneeCountryId, setConsigneeCountryId] = useState(null)
	const [ConsigneeAddress, setConsigneeAddress] = useState(null)
	const [Carrier, setCarrier] = useState(null)
	const [ContactName, setContactName] = useState(null)
	const [ContactNumber, setContactNumber] = useState(null)
	const [AdditionalHandlingInformation, setAdditionalHandlingInformation] = useState(null)
	const [VesselDefinition, setVesselDefinition] = useState(null)
	const [VesselDate, setVesselDate] = useState(null)
	const [PortOfLoading, setPortOfLoading] = useState(null)
	const [PortOfDischarge, setPortOfDischarge] = useState(null)
	const [Destination, setDestination] = useState(null)
	const [ShippingMarks, setShippingMarks] = useState(null)
	const [VehicleContainer1Number, setVehicleContainer1Number] = useState(null)
	const [VehicleContainer2Number, setVehicleContainer2Number] = useState(null)
	const [VehicleTrailer1ContainerNumber, setVehicleTrailer1ContainerNumber] = useState(null)
	const [VehicleTrailer2ContainerNumber, setVehicleTrailer2ContainerNumber] = useState(null)
	const [VehiclePlate, setVehiclePlate] = useState(null)
	const [VehicleTrailer1Plate, setVehicleTrailer1Plate] = useState(null)
	const [VehicleTrailer2Plate, setVehicleTrailer2Plate] = useState(null)
	const [VehicleSealNumber, setVehicleSealNumber] = useState(null)
	const [VehicleContainer1SealNumber, setVehicleContainer1SealNumber] = useState(null)
	const [VehicleContainer2SealNumber, setVehicleContainer2SealNumber] = useState(null)
	const [VehicleTrailer1SealNumber, setVehicleTrailer1SealNumber] = useState(null)
	const [VehicleTrailer2SealNumber, setVehicleTrailer2SealNumber] = useState(null)
	const [DriverName, setDriverName] = useState(null)

	const [TareMass, setTareMass] = useState(null)
	const [TotalGross, setTotalGross] = useState(null)
	const [NameOfCompany, setNameOfCompany] = useState(null)
	const [NameOfDeclarant, setNameOfDeclarant] = useState(null)
	const [StatusOfDeclarant, setStatusOfDeclarant] = useState(null)
	const [Place, setPlace] = useState(null)
	const [Date, setDate] = useState(null)
	const [HauliersName, setHauliersName] = useState(null)
	const [HaulierVehicleRegistrationNo, setHaulierVehicleRegistrationNo] = useState(null)
	const [HaulierDate, setHaulierDate] = useState(null)
	const [ShipperNameOfCompany, setShipperNameOfCompany] = useState(null)
	const [ShipperNameOfDeclarant, setShipperNameOfDeclarant] = useState(null)
	const [ShipperStatusOfDeclarant, setShipperStatusOfDeclarant] = useState(null)
	const [ShipperPlace, setShipperPlace] = useState(null)
	const [ShipperDate, setShipperDate] = useState(null)
	const [urunler, setUrunler] = useState(null)

	const [VehicleType, setVehicleType] = useState(null);
	const [VehicleSubType, setVehicleSubType] = useState(null);
	const [VehicleTrailer1TypeName, setVehicleTrailer1TypeName] = useState(null);
	const [VehicleTrailer2Type, setVehicleTrailer2Type] = useState(null);
	const [VehicleTrailer1ContainerType, setVehicleTrailer1ContainerType] = useState(null);
	const [VehicleTrailer2ContainerType, setVehicleTrailer2ContainerType] = useState(null);
	const [VehicleContainer1Type, setVehicleContainer1Type] = useState(null);
	const [VehicleContainer2Type, setVehicleContainer2Type] = useState(null);


	let componentRef = useRef();
	useEffect(() => {
		if (!data)
			dataProvider.KaraDenizGetir(pid)
				.then(({ data }) => {

					console.log("data", data[0])

					setData(data[0]);
					setFormName(data[0].FormName);
					setPreparedByDefinition(data[0].PreparedByDefinition)
					setSenderTitle(data[0].SenderTitle)
					setSenderCountryId(data[0].SenderCountryId)
					setSenderAddress(data[0].SenderAddress)
					setTransportDocumentNumber(data[0].TransportDocumentNumber)
					setShippersReference(data[0].ShippersReference)
					setFreightForwardersReference(data[0].FreightForwardersReference)
					setConsigneeTitle(data[0].ConsigneeTitle)
					setConsigneeCountryId(data[0].ConsigneeCountryId)
					setConsigneeAddress(data[0].ConsigneeAddress)
					setCarrier(data[0].Carrier)
					setContactName(data[0].ContactName)
					setContactNumber(data[0].ContactNumber)
					setAdditionalHandlingInformation(data[0].AdditionalHandlingInformation)
					setVesselDefinition(data[0].VesselDefinition)
					setVesselDate(data[0].VesselDate)
					setPortOfLoading(data[0].PortOfLoading)
					setPortOfDischarge(data[0].PortOfDischarge)
					setDestination(data[0].EndDestination)
					setShippingMarks(data[0].ShippingMarks)
					setDriverName(data[0].DriverName)
					setVehicleContainer1Number(data[0].VehicleContainer1Number)
					setVehicleContainer2Number(data[0].VehicleContainer2Number)
					setVehicleTrailer1ContainerNumber(data[0].VehicleTrailer1ContainerNumber)
					setVehicleTrailer2ContainerNumber(data[0].VehicleTrailer2ContainerNumber)
					setVehiclePlate(data[0].VehiclePlate)
					setVehicleTrailer1Plate(data[0].VehicleTrailer1Plate)
					setVehicleTrailer2Plate(data[0].VehicleTrailer2Plate)
					setVehicleSealNumber(data[0].VehicleSealNumber)
					setVehicleContainer1SealNumber(data[0].VehicleContainer1SealNumber)
					setVehicleContainer2SealNumber(data[0].VehicleContainer2SealNumber)
					setVehicleTrailer1SealNumber(data[0].VehicleTrailer1SealNumber)
					setVehicleTrailer2SealNumber(data[0].VehicleTrailer2SealNumber)
					setVehicleSubType(data[0].VehicleSubType.Name)
					setVehicleTrailer1TypeName(data[0].VehicleTrailer1Type.Name)
					setVehicleTrailer2Type(data[0].VehicleTrailer2Type.Name)
					setVehicleTrailer1ContainerType(data[0].VehicleTrailer1ContainerType.Name)
					setVehicleTrailer2ContainerType(data[0].VehicleTrailer2ContainerType.Name)
					setVehicleContainer1Type(data[0].VehicleContainer1Type.Name)
					setVehicleContainer2Type(data[0].VehicleContainer2Type.Name)
					setTareMass(data[0].TareMass)
					setTotalGross(data[0].TotalGross)
					setNameOfCompany(data[0].NameOfCompany)
					setNameOfDeclarant(data[0].NameOfDeclarant)
					setStatusOfDeclarant(data[0].StatusOfDeclarant)
					setPlace(data[0].Place)
					setDate(data[0].Date)
					setHauliersName(data[0].HauliersName)
					setHaulierVehicleRegistrationNo(data[0].HaulierVehicleRegistrationNo)
					setHaulierDate(data[0].HaulierDate)
					setShipperNameOfCompany(data[0].ShipperNameOfCompany)
					setShipperNameOfDeclarant(data[0].ShipperNameOfDeclarant)
					setShipperStatusOfDeclarant(data[0].ShipperStatusOfDeclarant)
					setShipperPlace(data[0].ShipperPlace)
					setShipperDate(data[0].ShipperDate)
					var loopData = ''
					var i = 0;
					while (i < data[0].Products.length) {

						var k = 0;
						var emsk = ' ';
						while(k< data[0].Products[i].UNCode.IMDG.EMS.length){
							console.log("EMS=",data[0].Products[i].UNCode.IMDG.EMS.length);
							emsk +=  data[0].Products[i].UNCode.IMDG.EMS[k].Definition.Name +",";
							k++;

						}
					var g = 1;
					var tags = '';
					while(g< data[0].Products[i].UNCode.Tag.length){
						console.log("TAG=",data[0].Products[i].UNCode.Tag.length);
						tags +=  data[0].Products[i].UNCode.Tag[(g)].Definition.Name +",";
						g++;

					}

					var h = 1;
					var segregation = '';
					if(data[0].Products[i].UNCode.IMDG.Segregation != null){
					while(h< data[0].Products[i].UNCode.IMDG.Segregation.length){
						
						segregation +=  data[0].Products[i].UNCode.IMDG.Segregation[h].Definition.Name +",";
						h++;

						}
					}

						var paket = "-"
						var paketgrubu = "-"
						var parlama = "-"
						var kirletici = "-"
						if (data[0].Products[i].PackageType != null) {
							paket = data[0].Products[i].PackageType
						}
						if (data[0].Products[i].PackingGroup != null) {
							paketgrubu = data[0].Products[i].PackingGroup
						}
						if (data[0].Products[i].FlashPoint != null) {
							parlama = data[0].Products[i].PackingGroup
						}
						if (data[0].Products[i].MarinePollutant != null) {
							kirletici = data[0].Products[i].MarinePollutant
						}

						loopData += ` <td colSpan="2"  valign="center" align="center">
					${data[0].Products[i].PackageCount} - ${paket}
					</td>
					<td colSpan="2"  valign="center">
					<span><strong>UN NO:</strong> ${data[0].Products[i].UNCode.UNNumber},
					<span><strong>Proper Shipping Name:</strong> ${data[0].Products[i].ProperShippingName} ,
					<span><strong>Class:</strong> ${data[0].Products[i].UNCode.IMDG.ClassCode.Name}  ,
					<span><strong>SubClass:</strong> (${tags}),
					<span><strong>PackingGroup:</strong> ${paketgrubu},
					<span><strong>FlashPoint (<sup>o</sup>C):</strong> ${parlama},
					<span><strong>MarinePollutant</strong>:</strong> ${kirletici} ,
					<span><strong>EMS</strong>:</strong> ${emsk} ,
					<span><strong>Number and Type of Packages</strong>:</strong> ${data[0].Products[i].PackageCount} - ${paket} ,
					<span><strong>LimitedQuantity</strong>:</strong> ${data[0].Products[i].LimitedQuantity} ,		
					<span><strong>Segregation</strong>:</strong> ${segregation} ,		
					</td>
					<td colSpan="2" valign="center" align="center">
					${data[0].Products[i].GrossMass} 
					</td>
					<td ccolSpan="2" valign="center" align="center">
					${data[0].Products[i].NetMass} 
					</td>
					<td colSpan="2" valign="center" align="center">
					${data[0].Products[i].Cube} 
					</td>`;
						i++;
					}
					setUrunler(loopData);
					setVehicleType(data[0].VehicleType.Name)

					console.log("dataSet", loopData)
				});
	}, [data, dataProvider]);
	const { pid } = getQueryParams();
	console.log("bakalim =", formName)

	return (

		<Card style={{ marginTop: "1em" }} >
			<Container>
				<Row className="justify-content-md-center">
					<Col md="auto">
						<Title title={formName} />
						<div style={{ marginTop: "1em", marginRight: "1em", marginBottom: "5em", marginLeft: "1em" }} className="yazdir" ref={(el) => (componentRef = el)} >
							<Row className="justify-content-md-center">
								<Col md="auto">
									<span style={{ fontSize: 9 }}>
										<strong>MULTIMODAL DANGEROUS GOODS FORM</strong>
									</span>
								</Col>
							</Row>
							<span style={{ fontSize: 8 }}>
								Note: When this form is used as a container/vehicle packing certificate
								only, not a combined document, a dangerous goods declaration signed by the
								shipper or supplier must have been issued/received to cover each dangerous
								goods consignment packed in the container. The container/vehicle packing
								certificate is not required for tanks.
							</span>
							<table border="1" cellSpacing="0" cellPadding="5" width="100%" className="cizik">
								<tbody>
									<tr>
										<td colSpan="6" rowSpan="3" valign="top" >
											<span>
												<span><strong >1. {PreparedByDefinition}</strong></span>
											</span>
											<span >
												{SenderTitle} <br />{SenderAddress}
											</span>
										</td>
										<td colSpan="2" rowSpan="2" valign="top" >
											<span>
											<strong>2. Transport document number</strong><br />
											</span>
											<span>
												 {TransportDocumentNumber}
											</span>
										</td>
									</tr>
									<tr>
										<td colSpan="1" valign="top" >
											<span>
											<strong>3. Page of pages</strong>
											</span>
											<span>(page auto-numbers top right)
											</span>
										</td>
										<td colSpan="2" valign="top" >
											<span>
											<strong><br />4. Shipper's reference</strong>
											</span>
											<span>
												 {ShippersReference}
											</span>
										</td>
									</tr>
									<tr>
										<td colSpan="5" valign="top" >
											<span>
											<strong>5. Freight forwarder's reference</strong>
											</span>
											<span>
												{FreightForwardersReference}
											</span>
										</td>
									</tr>
									<tr>
										<td colSpan="6" rowSpan="2" valign="top" >
											<span>
											<strong>6. Consignee</strong>
											</span>
											<span>
												{ConsigneeTitle}<br />{ConsigneeAddress}
											</span>
										</td>
										<td colSpan="4" valign="top" >
											<span>
											<strong>7. Carrier (to be completed by the carrier)</strong>
											</span>
											<span>
												 {Carrier}
											</span>
										</td>
									</tr>
									<tr>
										<td colSpan="4" valign="top">
											<span>
												<strong>
													SHIPPER'S DECLARATION (refer to box 22 below)
												</strong>
											</span>
											<span>
												I hereby declare that the contents of this consignment are
												fully and accurately described below by the proper shipping
												name(s), and are classified, packaged, marked and
												labelled/placarded, and are in all respects in proper
												condition for transport according to the applicable
												international and national governmental regulations.
											</span>
										</td>
									</tr>
									<tr>
										<td colSpan="5" valign="top" >
											<span>
											<strong>8. 24 hour contact number:</strong>
											</span>
											<span>
												{ContactNumber} 
											</span>
											<span>
												{ContactName} 
											</span>
										</td>
										<td colSpan="6" rowSpan="3" valign="top" >
											<span>
											<strong>9. Additional handling information</strong>
											</span>
											<span>
												{AdditionalHandlingInformation}
											</span>
										</td>
									</tr>
									<tr>
										<td width="197" colSpan="3" valign="top" >
											<span>
											<strong>10. Vessel and date</strong>
											</span>
											<span>
												{VesselDefinition} <br />{VesselDate}
											</span>
										</td>
										<td width="180" colSpan="2" valign="top" >
											<span>
											<strong>11. Port of loading</strong>
											</span>
											<span>
												{PortOfLoading}
											</span>
										</td>
									</tr>
									<tr>
										<td width="197" colSpan="3" valign="top" >
											<span>
											<strong>12. Port of discharge</strong>
											</span>
											<span>
												{PortOfDischarge}
											</span>
										</td>
										<td width="180" colSpan="2" valign="top" >
											<span>
											<strong>13. Destination</strong>
											</span>
											<span>
												 {Destination}
											</span>
										</td>
									</tr>
									<tr>
										<td width="711" colSpan="11" valign="top" >
											<span>
											<strong>14. Shipping marks</strong>
											</span>
											<span>
												{ShippingMarks} 
											</span>
										</td>
									</tr>
									<tr >
										<td colSpan="10" >
											<table border="1" cellSpacing="0" cellPadding="10" width="100%" className="cizik">
												<tr>
													<td colSpan={2} align="center">
														<span>
														<strong>Number and kind of packages</strong>
														</span>
													</td>
													<td colSpan={2} valign="top" align="center">
														<span>
														<strong>
															Description of Goods* (UN number, proper shipping name,
															class or division and packing group (where assigned) marine
															pollutant and observe the mandatory requirements under
															applicable national and international governmental
															regulations.
															</strong>
														</span>
													</td>
													<td colSpan={2} align="center">
														<span>
														<strong>Gross Mass (kg)</strong>
														</span>
													</td>
													<td colSpan={2} align="center">
														<span>
														<strong>Net Mass (kg)</strong>
														</span>
													</td>
													<td colSpan={2} align="center">
														<span>
														<strong>Cube (m<sup>3</sup>)</strong>
														</span>
													</td>
												</tr>
												<tr dangerouslySetInnerHTML={{ __html: urunler }}>
												</tr>
											</table>
										</td>
									</tr>
									<tr>
										<td colSpan="3" valign="top" >
											<span>
											<strong>15. Container identification No./

												Vehicle registration No.</strong>
											</span>
											<span>
												{VehicleContainer1Number}, {VehicleContainer2Number}, {VehicleTrailer1ContainerNumber}, {VehicleTrailer2ContainerNumber} / {VehiclePlate}, {VehicleTrailer1Plate}, {VehicleTrailer2Plate} 
											</span>
										</td>
										<td colSpan="3" valign="top">
											<span>
											<strong>16. Seal number(s)</strong>
											</span>
											<span>
											{VehicleSealNumber}, {VehicleContainer1SealNumber}, {VehicleContainer2SealNumber}, {VehicleTrailer1SealNumber}, {VehicleTrailer2SealNumber} 
											</span>
										</td>
										<td colSpan="2" valign="top">
											<span>
											<strong>17. Container/vehicle size &amp; type</strong>
											</span>
											<span>
												{VehicleTrailer1ContainerType},{VehicleTrailer2ContainerType}, {VehicleContainer1Type},{VehicleContainer2Type} / {VehicleType}-{VehicleSubType}, {VehicleTrailer1TypeName}, {VehicleTrailer2Type}
											</span>
										</td>
										<td colSpan="1" valign="top">
											<span>
											<strong>18. Tare mass (kg)</strong>
											</span>
											<span>{TareMass}</span>
										</td>
										<td colSpan="3" valign="top" >
											<span>
											<strong>19. Total gross
												(incl tare) (kg)</strong>
											</span>
											<span>{TotalGross}</span>
										</td>
									</tr>
									<tr>
										<td colSpan="4" valign="top">
											<span>
												<strong>CONTAINER/VEHICLE PACKING CERTIFICATE</strong>
											</span>
											<span>
												I hereby declare that the goods described above have been
												packed/loaded into the container/vehicle identified above
												in accordance with the applicable provisions&#8224;
											</span>
											<span>
												<strong>
													Must be completed and signed for all container/ vehicle
													loads by person responsible for packing/loading.
												</strong>
											</span>
										</td>
										<td colSpan="7" valign="top">
											<span>
												<strong>21. RECEIVING ORGANIZATION RECEIPT</strong>
											</span>
											<span>
												Received the above number of packages/containers/trailers
												in apparent good order and condition unless stated hereon.
											</span>
											<span>
												<strong>Receiving organization remarks: </strong>
												{ShippingMarks}
											</span>
										</td>
									</tr>
									<tr>
										<td colSpan="4" valign="top" >
											<span>
												<strong>
													20. Name of company (see note 2 on notes page)
												</strong>
											</span>
											<span>
												 {NameOfCompany} 
											</span>
										</td>
										<td colSpan="2" valign="top" >
											<span>
											<strong>Haulier's name</strong>
											</span>
											<span>{HauliersName}</span>
										</td>
										<td colSpan="5" valign="top" >
											<span>
												<strong>22. Name of company</strong>
											</span>
											<span>
												<strong>(of shipper preparing this note)</strong>
											</span>
											<span>{ShipperNameOfCompany}</span>
										</td>

									</tr>
									<tr>
										<td colSpan="4" valign="top" >
											<span>
											<strong>Name/status of declarant</strong>
											</span>
											<span>
												{NameOfDeclarant} / {StatusOfDeclarant}
											</span>
										</td>
										<td colSpan="2" valign="top" >
											<span>
											<strong>Vehicle reg no.</strong>
											</span>
											<span>{HaulierVehicleRegistrationNo}</span>
										</td>
										<td colSpan="5" valign="top" >
											<span>
												<strong>Name/status of declarant</strong>
											</span>
											<span>{ShipperNameOfDeclarant}/{ShipperStatusOfDeclarant}</span>
										</td>
									</tr>
									<tr>
										<td colSpan="4" valign="top" >
											<span>
											<strong>Place and date</strong>
											</span>
											<span>
												 {Place} / {Date}
											</span>
										</td>
										<td colSpan="2" valign="top" >
											<span>
											<strong>Signature and date</strong>
											</span>
											<span>{HaulierDate}</span>
										</td>
										<td colSpan="5" valign="top" >
											<span>
												<strong>Place and date</strong>
											</span>
											<span>{ShipperPlace} <br /> {ShipperDate}</span>
										</td>
									</tr>
									<tr>
										<td colSpan="4" valign="top">
											<span><strong>
												Signature of declarant</strong>
												<br />
												<br />
											</span>
										</td>
										<td colSpan="2" valign="top" >
											<span>
												<strong>Driver's Signature</strong>
											</span>
											<span>{DriverName}</span>
										</td>
										<td colSpan="5" valign="top">
											<span>
												<strong>
													Signature of declarant
													<br /><br /><br /><br />
												</strong>
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<ReactToPrint
							trigger={() => <Button>Print this out!</Button>}
							content={() => componentRef}
						/>
					</Col>
				</Row>
			</Container>
		</Card>
	);
};

const getQueryParams = () => {
	const urlParams = new URLSearchParams(window.location.href);
	return {
		pid: urlParams.get("pid"),
	};
};

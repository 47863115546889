import React, { useEffect, useState, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

import {
	Title,
	useDataProvider,
} from "react-admin";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { Card, } from "@mui/material";
import ReactToPrint from 'react-to-print'
export const YuklemeSertifika2 = (props) => {
	let componentRef = useRef();
	const dataProvider = useDataProvider();
	const [urunler, setUrunler] = useState(null)
	const [data, setData] = useState(null);
	const [formName, setFormName] = useState(null)
	const [IsFormPackingCertificate, setIsFormPackingCertificate] = useState(null)
	const [IsFormContainerOrVehicleLoadingCertificate, setIsFormContainerOrVehicleLoadingCertificate] = useState(null)
	const [IsContainerClean, setIsContainerClean] = useState(null)
	const [IsPackagesSegregated, setIsPackagesSegregated] = useState(null)
	const [IsPackagesInspectedForDamage, setIsPackagesInspectedForDamage] = useState(null)
	const [IsDrumsStowed, setIsDrumsStowed] = useState(null)
	const [IsGoodsEvenlyDistributed, setIsGoodsEvenlyDistributed] = useState(null)
	const [IsContainerStructurallyServiceable, setIsContainerStructurallyServiceable] = useState(null)
	const [IsContainerAndPackagesProperlyMarked, setIsContainerAndPackagesProperlyMarked] = useState(null)
	const [Is5536Compliant, setIs5536Compliant] = useState(null)
	const [IsTransportDocumentsAvailable, setIsTransportDocumentsAvailable] = useState(null)
	const [VanningSurvey, setVanningSurvey] = useState(null)
	const [PackagingIMDGCodeClass, setPackagingIMDGCodeClass] = useState(null)
	const [PackagingIMDGCodeDivision, setPackagingIMDGCodeDivision] = useState(null)
	const [PackagingConditions, setPackagingConditions] = useState(null)
	const [PackagingCompany, setPackagingCompany] = useState(null)
	const [PackagingNameOfDeclarant, setPackagingNameOfDeclarant] = useState(null)
	const [PackagingCTUCertificateNumber, setPackagingCTUCertificateNumber] = useState(null)
	const [PackagingDate, setPackagingDate] = useState(null)
	const [LoadingIMDGCodeDivision, setLoadingIMDGCodeDivision] = useState(null)
	const [LoadingContainerVehicleRegistrationNumber, setLoadingContainerVehicleRegistrationNumber] = useState(null)
	const [LoadingPortOfLoading, setLoadingPortOfLoading] = useState(null)
	const [LoadingDate, setLoadingDate] = useState(null)
	const [LoadingPortOfUnloading, setLoadingPortOfUnloading] = useState(null)
	const [VehicleRegistrationNumber, setVehicleRegistrationNumber] = useState(null)
	const [LoadingNameOfDeclarant, setLoadingNameOfDeclarant] = useState(null)
	const [LoadingCompany, setLoadingCompany] = useState(null)
	const [LoadingTypeOfPackage, setLoadingTypeOfPackage] = useState(null)
	const [LoadingCTUCertificateNumber, setLoadingCTUCertificateNumber] = useState(null)
	const [LoadingPlace, setLoadingPlace] = useState(null)
	const [UNCodeId, setUNCodeId] = useState(null)
	const [ProperShippingName, setProperShippingName] = useState(null)
	const [NumberOfPackage, setNumberOfPackage] = useState(null)
	const [TypeOfPackage, setTypeOfPackage] = useState(null)
	const [GrossWeightOfEachPackage, setGrossWeightOfEachPackage] = useState(null)
	const [IMDGCodeClass, setIMDGCodeClass] = useState(null)
	const [IMDGCodeDivision, setIMDGCodeDivision] = useState(null)
	const [UNHomologationOfPackage, setUNHomologationOfPackage] = useState(null)
	const [Specifications, setSpecifications] = useState(null)
	const [VehicleContainer1Number, setVehicleContainer1Number] = useState(null)
	const [VehicleContainer2Number, setVehicleContainer2Number] = useState(null)
	const [VehicleTrailer1ContainerNumber, setVehicleTrailer1ContainerNumber] = useState(null)
	const [VehicleTrailer2ContainerNumber, setVehicleTrailer2ContainerNumber] = useState(null)
	const [VehiclePlate, setVehiclePlate] = useState(null)
	const [VehicleTrailer1Plate, setVehicleTrailer1Plate] = useState(null)
	const [VehicleTrailer2Plate, setVehicleTrailer2Plate] = useState(null)
	const [VehicleSealNumber, setVehicleSealNumber] = useState(null)
	const [VehicleContainer1SealNumber, setVehicleContainer1SealNumber] = useState(null)
	const [VehicleContainer2SealNumber, setVehicleContainer2SealNumber] = useState(null)
	const [VehicleTrailer1SealNumber, setVehicleTrailer1SealNumber] = useState(null)
	const [VehicleTrailer2SealNumber, setVehicleTrailer2SealNumber] = useState(null)
	const [DriverName, setDriverName] = useState(null)
	const [VehicleType, setVehicleType] = useState(null);
	const [VehicleSubType, setVehicleSubType] = useState(null);
	const [VehicleTrailer1TypeName, setVehicleTrailer1TypeName] = useState(null);
	const [VehicleTrailer2Type, setVehicleTrailer2Type] = useState(null);
	const [VehicleTrailer1ContainerType, setVehicleTrailer1ContainerType] = useState(null);
	const [VehicleTrailer2ContainerType, setVehicleTrailer2ContainerType] = useState(null);
	const [VehicleContainer1Type, setVehicleContainer1Type] = useState(null);
	const [VehicleContainer2Type, setVehicleContainer2Type] = useState(null);
	const [PackagingPlace, setPackagingPlace] = useState(null);

	//const {componentRef} = "";
	useEffect(() => {
		if (!data)
			dataProvider.YuklemeSertifikalariGetir(pid)
				.then(({ data }) => {

					console.log("data", data[0])
					setData(data[0]);
					setFormName(data[0].FormName);
					setIsFormPackingCertificate(data[0].IsFormPackingCertificate)
					setIsFormContainerOrVehicleLoadingCertificate(data[0].IsFormContainerOrVehicleLoadingCertificate)
					setIsContainerClean(data[0].IsContainerClean)
					setIsPackagesSegregated(data[0].IsPackagesSegregated)
					setIsPackagesInspectedForDamage(data[0].IsPackagesInspectedForDamage)
					setIsDrumsStowed(data[0].IsDrumsStowed)
					setIsGoodsEvenlyDistributed(data[0].IsGoodsEvenlyDistributed)
					setIsContainerStructurallyServiceable(data[0].IsContainerStructurallyServiceable)
					setIsContainerAndPackagesProperlyMarked(data[0].IsContainerAndPackagesProperlyMarked)
					setIs5536Compliant(data[0].Is5536Compliant)
					setIsTransportDocumentsAvailable(data[0].IsTransportDocumentsAvailable)
					setVanningSurvey(data[0].VanningSurvey)
					setPackagingIMDGCodeClass(data[0].PackagingCTUCertificateNumber)
					setPackagingIMDGCodeDivision(data[0].PackagingIMDGCodeDivision)
					setPackagingConditions(data[0].PackagingConditions)
					setPackagingCompany(data[0].PackagingCompany)
					setPackagingPlace(data[0].PackagingPlace)
					setPackagingNameOfDeclarant(data[0].PackagingNameOfDeclarant)
					setPackagingCTUCertificateNumber(data[0].PackagingCTUCertificateNumber)
					setPackagingDate(data[0].PackagingDate)
					setLoadingDate(data[0].LoadingDate)
					setLoadingNameOfDeclarant(data[0].LoadingNameOfDeclarant)
					setLoadingIMDGCodeDivision(data[0].LoadingIMDGCodeDivision)
					setLoadingContainerVehicleRegistrationNumber(data[0].LoadingContainerVehicleRegistrationNumber)
					setLoadingPlace(data[0].LoadingPlace)
					setLoadingPortOfLoading(data[0].LoadingPortOfLoading)
					setLoadingPortOfUnloading(data[0].LoadingPortOfUnloading)
					setVehicleRegistrationNumber(data[0].VehicleRegistrationNumber)
					setLoadingCompany(data[0].LoadingCompany)
					setLoadingTypeOfPackage(data[0].LoadingTypeOfPackage)
					setLoadingCTUCertificateNumber(data[0].LoadingCTUCertificateNumber)
					setUNCodeId(data[0].UNCodeId)
					setProperShippingName(data[0].ProperShippingName)
					setNumberOfPackage(data[0].NumberOfPackage)
					setTypeOfPackage(data[0].TypeOfPackage)
					setGrossWeightOfEachPackage(data[0].GrossWeightOfEachPackage)
					setIMDGCodeClass(data[0].IMDGCodeClass)
					setIMDGCodeDivision(data[0].IMDGCodeDivision)
					setUNHomologationOfPackage(data[0].UNHomologationOfPackage)
					setSpecifications(data[0].Specifications)
					setDriverName(data[0].DriverName)
					setVehicleContainer1Number(data[0].VehicleContainer1Number)
					setVehicleContainer2Number(data[0].VehicleContainer2Number)
					setVehicleTrailer1ContainerNumber(data[0].VehicleTrailer1ContainerNumber)
					setVehicleTrailer2ContainerNumber(data[0].VehicleTrailer2ContainerNumber)
					setVehiclePlate(data[0].VehiclePlate)
					setVehicleTrailer1Plate(data[0].VehicleTrailer1Plate)
					setVehicleTrailer2Plate(data[0].VehicleTrailer2Plate)
					setVehicleSealNumber(data[0].VehicleSealNumber)
					setVehicleContainer1SealNumber(data[0].VehicleContainer1SealNumber)
					setVehicleContainer2SealNumber(data[0].VehicleContainer2SealNumber)
					setVehicleTrailer1SealNumber(data[0].VehicleTrailer1SealNumber)
					setVehicleTrailer2SealNumber(data[0].VehicleTrailer2SealNumber)
					setVehicleSubType(data[0].VehicleSubType.Name)
					setVehicleTrailer1TypeName(data[0].VehicleTrailer1Type.Name)
					setVehicleTrailer2Type(data[0].VehicleTrailer2Type.Name)
					setVehicleTrailer1ContainerType(data[0].VehicleTrailer1ContainerType.Name)
					setVehicleTrailer2ContainerType(data[0].VehicleTrailer2ContainerType.Name)
					setVehicleContainer1Type(data[0].VehicleContainer1Type.Name)
					setVehicleContainer2Type(data[0].VehicleContainer2Type.Name)
					var loopData = ''
					var i = 0;
					while (i < data[0].Products.length) {
						var paketgrubu = "-"
						var unnum = "-"
						if (data[0].Products[i].TypeOfPackage != null) {
							paketgrubu = data[0].Products[i].TypeOfPackage
						}
						if (data[0].Products[i].UNCode.UNNumber != null) {
							unnum = data[0].Products[i].UNCode.UNNumber
						}
						loopData += `<tr>
						<td colSpan="3">
							<p align="center">
								<strong>Dangerous Goods ${i + 1} <br/>(Tehlikeli Y&#252;k ${i + 1})</strong>

							</p>
						</td>
						<td colSpan="1">
							<p>
							${data[0].Products[i].NumberOfPackage}
							</p>
						</td>
						
						<td colSpan="1">
							<p>
							
							${paketgrubu}
							</p>
						</td>
						<td colSpan="2">
							<p>
							${data[0].Products[i].GrossWeightOfEachPackage}
							</p>
						</td>
						
						<td colSpan="1">
							<p>
							${unnum}
							</p>
						</td>
						<td colSpan="2">
							<p>
							${data[0].Products[i].ProperShippingName}
							</p>
						</td>
						<td colSpan="2">
							<p>
							${data[0].Products[i].IMDGCodeClass} - ${data[0].Products[i].IMDGCodeDivision}
							</p>
						</td>
						<td colSpan="1">
						<p>
							${data[0].Products[i].UNHomologationOfPackage}
							</p>
						</td>
						<td colSpan="2">
						<p>
							${data[0].Products[i].Specifications}
							</p>
						</td>
					</tr>
					`;
						i++;
					}
					setUrunler(loopData);
					//console.log("dataSet", loopData)

				});
	});
	const { pid } = getQueryParams();
	//console.log("bakalim =", formName)
	return (

		<Card style={{ marginTop: "1em" }} >
			<Container className="justify-content-md-center">

				<Col md="auto">
					<Title title={formName} />
					<div style={{ marginTop: "1em" }} className="yazdir" ref={(el) => (componentRef = el)}>

						<table border="0" cellSpacing="0" cellPadding="0" style={{ width: "100%" }}>
							<tbody>
								<tr>
									<td colSpan="2" valign="top">
										<p align="center">
											{
												IsFormPackingCertificate === 1 ?
													<span>
														<input type="radio" checked disabled className="yazilanda" />
														<span></span></span>

													: <span>
														<input type="radio" disabled className="yazilanda" />
														<span></span></span>
											}
											Packaging Certificate (Paketleme Sertifikas&#305;)
										</p>
									</td>
									<td colSpan="3" valign="top">
										<p align="center">
											{
												IsFormContainerOrVehicleLoadingCertificate === 1 ?
													<span>
														<input type="radio" checked disabled className="yazilanda" />
														<span></span></span>

													: <span>
														<input type="radio" disabled className="yazilanda" />
														<span></span></span>
											}
											Container/Vehicle Packing Certificate (Konteyner/Ara&#231; Y&#252;kleme Sertifikas&#305;)
										</p>
									</td>

								</tr>
							</tbody>
						</table>

						<table border="1" cellSpacing="0" cellPadding="0" style={{ width: "100%" }}>
							<tbody>
								<tr>
									<td >
									</td>
									<td colSpan="3" valign="top">
										<p align="center"><span>
											<strong>
												CONTAINER / VEHICLE PACKING CERTIFICATE

											</strong></span>
										</p>
									</td>
									<td colSpan="3" valign="top">
										<p align="center">
											<strong>

												KONTEYNER/ARA&#199; Y&#220;KLEME SERT&#304;F&#304;KASI
											</strong>
										</p>
									</td>
								</tr>
								<tr>
									<td width="85">
									</td>
									<td colSpan="3" valign="top">
										<p>
											Whenever hazardous goods are packed or loaded into a
											container or on a vehicle, those responsible for packing
											the container or vehicle shall provide a CONTAINER/VEHICLE
											PACKING CERTIFICATE specifying the container/vehicle
											identification number(s) and certifying that the operation
											has been carried out in accordance with the following
											conditions (cf. cl. 5.4.2.1 of the IMDG code).
										</p>
									</td>
									<td colSpan="3" valign="top">
										<p>
											Konteynere veya araca tehlikeli y&#252;k y&#252;klenmesi
											durumunda y&#252;k&#252;n konteyner veya arac&#305;n
											y&#252;klenmesinden sorumlu ki&#351;i / ki&#351;iler
											taraf&#305;ndan; konteyner numaralar&#305; / ara&#231;
											plakalar&#305; da belirtilen bu KONTEYNER / ARA&#199;
											Y&#220;KLEME SERT&#304;F&#304;KASI d&#252;zenlenmi&#351;tir
											ve a&#351;a&#287;&#305;da belirtilen &#351;artlar&#305;n
											IMDG Kod 5.4.2.1 h&#252;k&#252;mleri uyar&#305;nca uygun
											olarak y&#252;klemenin yap&#305;ld&#305;&#287;&#305;n&#305;
											onaylanm&#305;&#351;t&#305;r.
										</p>
									</td>
								</tr>
								<tr>
									<td width="85">

										<p><strong>YES&nbsp;&nbsp;&nbsp;&nbsp;NO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;N/A</strong></p>
									</td>
									<td colSpan="3" width="302">
										<p>
											<strong>
												Mark the items below by when the conditions have been
												met.
											</strong>
										</p>
									</td>
									<td colSpan="3" width="340">
										<p>
											<strong>
												A&#351;a&#287;&#305;da belirtilen &#351;artlardan
												sa&#287;lananlar&#305; i&#351;aretleyin.
											</strong>
										</p>
									</td>
								</tr>
								<tr>
									<td width="100">
										<p>
											{
												IsContainerClean === "yes" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsContainerClean === "no" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsContainerClean === "na" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}</p>
									</td>
									<td colSpan="3" width="302">
										<p>
											The container/vehicle was clean, dry, and apparently fit to
											receive the goods.
										</p>
									</td>
									<td colSpan="3" width="340">
										<p>
											Konteyner / ara&#231;, temiz, kuru ve y&#252;klerin
											yerle&#351;tirilmesine uygundur.
										</p>
									</td>
								</tr>
								<tr>
									<td width="100">
										<p>
											{
												IsPackagesSegregated === "yes" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsPackagesSegregated === "no" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsPackagesSegregated === "na" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}</p>
									</td>
									<td colSpan="3" width="302">
										<p>
											Packages, which need to be segregated in according with
											applicable segregation requirements, have not been packed
											together onto or in the container/vehicle (unless approved
											by the competent authority concerned in accordance with cl.
											7.3.4.1 (in the IMDG code)).
										</p>
									</td>
									<td colSpan="3" width="340">
										<p>
											Uygulanabilir ayr&#305;&#351;t&#305;rma kurallar&#305;
											uyar&#305;nca birbirinden
											ayr&#305;&#351;t&#305;r&#305;lmas&#305; gereken paketler
											ilgili d&#252;zenlemelere uygun olarak konteyner /
											ara&#231; i&#231;inde
											ayr&#305;&#351;t&#305;r&#305;lm&#305;&#351;t&#305;r. (Bu
											duruma IMDG Kod 7.3.4.1 g&#246;re ilgili yetkili otorite
											taraf&#305;ndan onay verilmi&#351;se
											m&#252;mk&#252;nd&#252;r.)
										</p>
									</td>
								</tr>
								<tr>
									<td width="100">
										<p>
											{
												IsPackagesInspectedForDamage === "yes" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsPackagesInspectedForDamage === "no" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsPackagesInspectedForDamage === "na" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}</p>
									</td>
									<td colSpan="3" width="302">
										<p>
											All packages have been externally inspected for damage, and
											only sound packages have been loaded.
										</p>
									</td>
									<td colSpan="3" width="340">
										<p>
											T&#252;m paketler i&#231;in g&#246;zle d&#305;&#351;tan
											hasar kontrol&#252; yap&#305;lm&#305;&#351; ve sadece
											sa&#287;lam olan paketler y&#252;klenmi&#351;tir.
										</p>
									</td>
								</tr>
								<tr>
									<td width="100">
										<p>
											{
												IsDrumsStowed === "yes" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsDrumsStowed === "no" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsDrumsStowed === "na" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}</p>
									</td>
									<td colSpan="3" width="302">
										<p>
											Drums have been stowed in and upright position, unless
											otherwise authorized by the competent authority, and all
											goods have been properly loaded and, where necessary,
											adequately braced with securing material to suit the
											mode(s) of transport for the intended journey.
										</p>
									</td>
									<td colSpan="3" width="340">
										<p>
											Yetkili otorite taraf&#305;ndan onaylanm&#305;&#351; bir
											h&#252;k&#252;m bulunmuyorsa, y&#252;klenen variller, dik
											konumda istiflenmi&#351;tir ve t&#252;m y&#252;kler
											do&#287;ru yerle&#351;tirilmi&#351;tir ve gerekli
											oldu&#287;u durumlarda ta&#351;&#305;ma s&#305;ras&#305;nda
											ta&#351;&#305;ma modu/modlar&#305;na uygun g&#252;venlik ve
											emniyet &#351;artlar&#305;
											sa&#287;lanm&#305;&#351;t&#305;r.
										</p>
									</td>
								</tr>
								<tr>
									<td width="100">
										<p>
											{
												IsGoodsEvenlyDistributed === "yes" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsGoodsEvenlyDistributed === "no" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsGoodsEvenlyDistributed === "na" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}</p>
									</td>

									<td colSpan="3" width="302">
										<p>
											Goods loaded in bulk have been evenly distributed within
											the container/vehicle.
										</p>
									</td>
									<td colSpan="3" width="340">
										<p>
											D&#246;kme olarak y&#252;klenen y&#252;kler konteyner /
											ara&#231; i&#231;inde d&#252;zg&#252;n olarak
											da&#287;&#305;t&#305;lm&#305;&#351;t&#305;r.
										</p>
									</td>
								</tr>
								<tr>
									<td width="100">
										<p>
											{
												IsContainerStructurallyServiceable === "yes" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsContainerStructurallyServiceable === "no" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsContainerStructurallyServiceable === "na" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}</p>
									</td>
									<td colSpan="3" width="302">
										<p>
											For consignments including goods of class 1 other than
											division 1.4, the container/vehicle is structurally
											serviceable in accordance with 7.1.2 (in the IMDG code).
										</p>
									</td>
									<td colSpan="3" width="340">
										<p>
											Alt b&#246;l&#252;m 1.4 d&#305;&#351;&#305;nda
											s&#305;n&#305;f 1 olarak
											s&#305;n&#305;fland&#305;r&#305;lm&#305;&#351; y&#252;kler
											i&#231;in, konteyner / ara&#231; IMDG Kod 7.1.2. maddesine
											uyar&#305;nca yap&#305;sal uygunluktad&#305;r.
										</p>
									</td>
								</tr>
								<tr>
									<td width="100">
										<p>
											{
												IsContainerAndPackagesProperlyMarked === "yes" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsContainerAndPackagesProperlyMarked === "no" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsContainerAndPackagesProperlyMarked === "na" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}</p>
									</td>
									<td colSpan="3" width="302">
										<p>
											The container/vehicle and packages are properly marked,
											labelled and placarded, as appropriate;
										</p>
									</td>
									<td colSpan="3" width="340">
										<p>
											Konteyner / ara&#231; ve paketler uygun &#351;ekilde
											i&#351;aretlenmi&#351;, etiketlenmi&#351; ve
											plakartland&#305;r&#305;lm&#305;&#351;t&#305;r.
										</p>
									</td>
								</tr>
								<tr>
									<td width="100">
										<p>
											{
												Is5536Compliant === "yes" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												Is5536Compliant === "no" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												Is5536Compliant === "na" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}</p>
									</td>
									<td colSpan="3" width="302">
										<p>
											When substances presenting a risk of asphyxiation are used
											for cooling or conditioning purposes (such as dry ice (UN
											1845) or nitrogen, refrigerated liquid (UN 1977) or argon,
											refrigerated liquid (UN 1951)), the container /vehicle is
											externally marked in accordance with 5.5.3.6; and;
										</p>
									</td>
									<td colSpan="3" width="340">
										<p>
											So&#287;utma ve iklimlendirme amac&#305; ile
											kullan&#305;lan maddeler (KURU BUZ (UN 1845) veya AZOT,
											SO&#286;UTULMU&#350; SIVI (UN 1977) veya ARGON,
											SO&#286;UTULMU&#350; SIVI (UN 1951) gibi)
											y&#252;klendi&#287;inde 5.5.3.6 uyar&#305;nca
											konteyner/arac&#305;n d&#305;&#351;&#305;nda bo&#287;ucu
											&#246;zellik riskini belirten i&#351;aret
											bulunmaktad&#305;r.
										</p>
									</td>
								</tr>
								<tr>
									<td width="100">
										<p>
											{
												IsTransportDocumentsAvailable === "yes" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsTransportDocumentsAvailable === "no" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												IsTransportDocumentsAvailable === "na" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}</p>
									</td>
									<td colSpan="3" width="302">
										<p>
											A dangerous goods transport document, as indicated in 5.4.1
											(in the IMDG code) has been received for each dangerous
											goods consignment loaded in the container/vehicle.
										</p>
									</td>
									<td colSpan="3" width="340">
										<p>
											Konteyner / Araca y&#252;klenen her bir tehlikeli y&#252;k,
											IMDG Kod 5.4.1'de belirtilen tehlikeli y&#252;kler
											i&#231;in bir ta&#351;&#305;ma belgesine sahiptir.
										</p>
									</td>
								</tr>
							</tbody>
						</table>
						<p style={{ fontSize: 10 }}>&nbsp;&nbsp;</p>

						<table border="1" cellSpacing="0" cellPadding="0" style={{ width: "100%" }}>
						<tr>
									<td width="100">
									<p><strong>YES&nbsp;&nbsp;&nbsp;&nbsp;NO</strong></p>
										<p>
											{
												VanningSurvey === 1 ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
											{
												VanningSurvey === "2" ?
													<span align="center">
														<input type="checkbox" checked disabled className="yazilanda" />
														<span></span>
													</span>
													: <span align="center">
														<input type="checkbox" disabled className="yazilanda" />
														<span></span>
													</span>
											}
										</p>
									</td>
									<td colSpan="3" width="302">
										<p>
											Vanning Survey
										</p>
									</td>

								</tr>
								</table>
								<p style={{ fontSize: 10 }}>&nbsp;&nbsp;</p>
						<table border="1" cellSpacing="0" cellPadding="0" style={{ width: "100%" }}>
							<tbody>
								<tr>
									<td width="359" colSpan="2">
										<p>
											<strong>Responsible Person of Packaging (Paketlemeden Sorumlu Ki&#351;i)</strong>
										</p>
									</td>
									<td width="369" colSpan="2">
										<p>
											<strong>Responsible Person of Loading (Y&#252;klemeden Sorumlu Ki&#351;i)</strong>
										</p>
									</td>
								</tr>
								<tr>
									<td valign="top">
										<p>
											<strong>Place (Yer)/Date (Tarih)</strong>
										</p>
									</td>
									<td width="151" valign="top">
										<p>{PackagingPlace} / {PackagingDate}</p>
									</td>
									<td width="151" valign="top">
										<p>
											<strong>Place (Yer) / Date (Tarih)</strong>
										</p>
									</td>
									<td width="217" valign="top">
										<p>{LoadingPlace} / {LoadingDate}</p>
									</td>
								</tr>
								<tr>
									<td width="359" colSpan="2">
										<p>
											The conditions above have been met at time of packing.<br /> (Paketleme s&#305;ras&#305;nda yukar&#305;da yer alan
											ko&#351;ullar yerine getirilmi&#351;tir.)
										</p>

									</td>
									<td width="369" colSpan="2">
										<p>
											The conditions above have been met at time of loading the
											container/vehicle.
											<br />
											(Konteyner/Ara&#231; y&#252;kleme s&#305;ras&#305;nda
											yukar&#305;da yer alan ko&#351;ullar yerine
											getirilmi&#351;tir.)
										</p>
									</td>
								</tr>
								<tr>
									<td valign="top">
										<p>
											<strong> </strong>
										</p>
									</td>
									<td width="151" valign="top">
										<p>
											<strong> </strong>
										</p>
									</td>
									<td width="151" valign="top">
										<p>
											<strong>Container/Vehicle Registration Nr: (Konteyner Kay&#305;t No/Ara&#231; Plakas&#305;)
											</strong>
										</p>
									</td>
									<td width="217" valign="top">
										<p>
											{VehicleContainer1Number}, {VehicleContainer2Number}, {VehicleTrailer1ContainerNumber}, {VehicleTrailer2ContainerNumber} / {VehiclePlate}, {VehicleTrailer1Plate}, {VehicleTrailer2Plate}
										</p>
									</td>
								</tr>
								<tr>
									<td valign="top">
										<p>
											<strong> </strong>
										</p>
									</td>
									<td width="151" valign="top">
										<p>
											<strong> </strong>
										</p>
									</td>
									<td width="151" valign="top">
										<p>
											<strong>Port of Loading / Unloading (Y&#252;kleme Liman&#305; / Teslim Liman&#305;)</strong>
										</p>
									</td>
									<td width="217" valign="top">
										<p> {LoadingPortOfLoading} / {LoadingPortOfUnloading}</p>
									</td>
								</tr>
								<tr>
									<td width="208">
										<p>
											<strong>Company(Firma)</strong>
										</p>
									</td>
									<td width="151">
										<p>{PackagingCompany}</p>
									</td>
									<td width="151">
										<p>
											<strong>Company (Firma)</strong>
										</p>
									</td>
									<td width="217">
										<p> {LoadingCompany}</p>
									</td>
								</tr>
								<tr>
									<td width="208">
										<p>
											<strong>Signature (&#304;mza)</strong>
										</p>
									</td>
									<td width="151">
										<p>
											<strong> </strong>
										</p>
									</td>
									<td width="151">
										<p>
											<strong>Signature (&#304;mza)</strong>
										</p>
									</td>
									<td width="217">
										<p>
											<strong> </strong>
										</p>
									</td>
								</tr>
								<tr>
									<td valign="top">
										<p>
											<strong>Name of Declarant (Beyan Edenin Ad&#305; Soyad&#305;)</strong>
										</p>
									</td>
									<td width="151" valign="top">
										<p>{PackagingNameOfDeclarant}</p>
									</td>
									<td width="151" valign="top">
										<p>
											<strong>Name of Declarant (Beyan Edenin Ad&#305; Soyad&#305;)</strong>
										</p>
									</td>
									<td width="217" valign="top">
										<p>{LoadingNameOfDeclarant}</p>
									</td>
								</tr>
								
							</tbody>
						</table>
						<p style={{ fontSize: 10 }}>&nbsp;&nbsp;</p>
						<p style={{ fontSize: 10 }}>&nbsp;&nbsp;</p>
						<p style={{ fontSize: 10 }}><strong>*Ürünler Ektedir</strong></p>
						<p style={{ fontSize: 10 }}>&nbsp;&nbsp;</p>
						<p style={{ fontSize: 10 }}>&nbsp;&nbsp;</p>

						<p style={{ fontSize: 10 }}>
							<strong>Products (Ürünler)</strong>
						</p>
						<table border="1" cellSpacing="0" cellPadding="0" width="100%" className="cizik" style={{ width: "100%" }}>
							<thead>
								<tr>
									<td colSpan="3" >
										<p align="center">
											<strong>Package Information <br />(Ambalaj Bilgileri)</strong>

										</p>
									</td>
									<td colSpan="1" >
										<p>
											<strong>Number of Package <br />(Paket Say&#305;s&#305;)</strong>
										</p>
									</td>
									<td colSpan="1">
										<p>
											<strong>Type of Package<br /> (Paket T&#252;r&#252;)</strong>
										</p>
									</td>
									<td colSpan="2">
										<p>
											<strong>Gross Weight of Each Package<br /> (Her Bir Paketin Br&#252;t
												A&#287;&#305;rl&#305;&#287;&#305;)</strong>
										</p>
									</td>
									<td colSpan="1">
										<p>
											<strong>UN No. <br />(BM Nr.)</strong>
										</p>
									</td>
									<td colSpan="2">
										<p>
											<strong>Proper Shipping Name <br />(Uygun Ta&#351;&#305;mac&#305;l&#305;k Ad&#305;)</strong>
										</p>
									</td>
									<td colSpan="2">
										<p>
											<strong>IMDG Code Class - Division IMDG Kod <br />(Tehlike S&#305;n&#305;f&#305; -B&#246;l&#252;m&#252;)</strong>
										</p>
									</td>
									<td colSpan="1">
										<p>
											<strong>UN Homologation of Package<br />(Paketin UN Onay Kodu)</strong>
										</p>
									</td>
									<td colSpan="2">
										<p>
											<strong>Specifications <br />(&#214;zellikleri)</strong>
										</p>
									</td>
								</tr>
							</thead>
							<tbody dangerouslySetInnerHTML={{ __html: urunler }}>

							</tbody>
						</table>

					</div>
					<br />
					<ReactToPrint
						trigger={() => <Button>Print this out!</Button>}
						content={() => componentRef}
					/>
				</Col>

			</Container>
		</Card>
	);
};

const getQueryParams = () => {
	const urlParams = new URLSearchParams(window.location.href);
	return {
		pid: urlParams.get("pid"),
	};
};

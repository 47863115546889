import React, { useState, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Title,
  useDataProvider, useNotify
} from "react-admin";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card, CardContent, Typography } from "@mui/material";
import Modal from 'react-bootstrap/Modal';
export const TasimaEvrak = (props) => {
  const notify = useNotify();
  const [validated, setValidated] = useState(null);
  const dataProvider = useDataProvider();
  const [data, setData] = useState(null);
  const [datas, setDatas] = useState(null);
  const [vehicleType, setVehicleType] = useState(null);
  const [trailerType, settrailerType] = useState(null);
  const [vehicleSubType, setVehicleSubType] = useState(null);
  const [vehicleContainerType, setVehicleContainerType] = useState(null);
  const [dataUn, setDataUn] = useState(null);
  const [form, setForm] = useState({});
  const [isShown, setIsShown] = useState(false);
  const [muafmi, setsetMuafmi] = useState(false);
  const [Varmi, setVarmi] = useState(false);
  const [inputList, setInputList] = useState([]);
  const [UnName, setUnName] = useState([])
  const [UnCodeId0, setUnCodeId0] = useState([])
  const [Class0, setClass0] = useState([])
  const [SubClass0, setSubClass0] = useState([])
  const [PackingGroup0, setPackingGroup0] = useState([])
  const [FlashPoint0, setFlashPoint0] = useState([])
  const [EMS0, setEMS0] = useState([])
  const [Package0, setPackage0] = useState([])
  const [PackageType0, setPackageType0] = useState([])
  const [LimitedQuantity0, setLimitedQuantity0] = useState([])
  const [ProperShipmentName0, setProperShipmentName0] = useState([])
  const [Hesaplamak, setHesaplamak] = useState([])
  const [ProductScore, setProductScore] = useState([])
  const [Exemption, setExemption] = useState(null)
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
  }
  const getValuesss = (index) => {
    const val = UnCodeId0.filter(x => x.key === index);
    if (val.length > 0)
      return val[0].value;

    return "";

  }
  const getClassValue = (index) => {
    const val = Class0.filter(x => x.key === index);
    if (val.length > 0)
      return val[0].value;

    return "";

  }
  const getSubClassValue = (index) => {

    const val = SubClass0.filter(x => x.key === index);
    if (val.length > 0)
      return val[0].value;

    return "";

  }
  const getUnNameValue = (index) => {
    const val = UnName.filter(x => x.key === index);
    if (val.length > 0)
      return val[0].value;

    return "";

  }
  const getPackingGroup = (index) => {
    const val = PackingGroup0.filter(x => x.key === index);
    if (val.length > 0)
      return val[0].value;

    return "";

  }
  const getFlashPoint = (index) => {
    console.log("FFskor->", FlashPoint0);
    /* const hukumler = FlashPoint0.filter(x => x.key === index);
    if (hukumler.length > 0)
      return  hukumler[0].value
       */
    const hukumler = FlashPoint0.map(function (item, i) {
      for (let j = 0; j < FlashPoint0.length - 1; j++) {
        if (item.value[j] != null)
          var text = item.value[j].DefinitionId + ",";

      }
      console.log("geldi1->", text);
      return text

    });

    return hukumler;

  }
  const getEMS0 = (i) => {

    console.log("ems", JSON.stringify(EMS0))
    const val = EMS0.map(x=> x?.Definition?.Name).join(", ");
  
    return val

  }

  const getPackageType0 = (index) => {
    const val = PackageType0.filter(x => x.key === index);
    if (val.length > 0)
      return val[0].value;
    return "";

  }
  const getProductScore = (index) => {
    /* console.log("skor->", ProductScore);
   const getScore = ProductScore.map(function(item, i ) {

      return item[i].Score
      
    });*/
    const val = ProductScore.filter(x => x.key === index);
    //console.log("skorU->", val);
    if (val.length > 0)
      return val[0].value[index];
    return "";

  }

  const getProduct = () => {
    const product = inputList.map((item, i) => {
      return {
        'UNCodeId': getValuesss(i),
        'ProperShipmentName': form["ProperShippingName" + i],
        'PackageCount': form["PackageCount" + i],
        'TotalNetWeight': form["TotalNetWeight" + i],
        'DangerScore': getProductScore(i),
      }

    });

    return product;
  }

  const getCalc = () => {
    const calc = inputList.map((item, i) => {
      return {
        'UNCodeId': getValuesss(i),
        'PackageCount': form["PackageCount" + i],
        'TotalNetWeight': form["TotalNetWeight" + i],

      }
    });

    return calc;
  }


  const formdatass = {

  }
  const formdatas = {

    'Form': {
      'FormName': form.FormName,
      'FormDate': form.FormDate,
      'SenderCompanyName': form.SenderCompanyName,
      'SenderCompanyAddress': form.SenderCompanyAddress,
      'SenderCompanyCountryId': form.SenderCompanyCountryId,
      'SenderCompanyCity': form.SenderCompanyCity,
      'SenderCompanyTaxAdministration': form.SenderCompanyTaxAdministration,
      'SenderCompanyTaxNumber': form.SenderCompanyTaxNumber,
      'SenderCompanyDocumentNumberUATF': form.SenderCompanyDocumentNumberUATF,
      'SenderResponsibleName': form.SenderResponsibleName,
      'TMFBNumber': form.TMFBNumber,
      'ReceiverCompanyName': form.ReceiverCompanyName,
      'ReceiverCompanyAddress': form.ReceiverCompanyAddress,
      'ReceiverCompanyCountryId': form.ReceiverCompanyCountryId,
      'ReceiverCompanyCity': form.ReceiverCompanyCity,
      'ReceiverCompanyTaxAdministration': form.ReceiverCompanyTaxAdministration,
      'ReceiverCompanyTaxNumber': form.ReceiverCompanyTaxNumber,
      'ReceivingOrganizationReceipt': form.ReceivingOrganizationReceipt,
      'DriverName': form.DriverName,
      'VehiclePlate': form.VehiclePlate,
      'VehicleTypeId': form.VehicleTypeId,
      'VehicleSubTypeId': form.VehicleSubTypeId,
      'VehicleContainer1TypeId': form.VehicleContainerTypeId,
      'VehicleContainerNumber': form.VehicleContainerNumber,
      'VehicleTrailer1TypeId': form.VehicleTrailer1TypeId,
      'VehicleTrailer1ContainerTypeId': form.VehicleTrailer1ContainerTypeId,
      'VehicleTrailer1ContainerNumber': form.VehicleTrailer1ContainerNumber,
      'VehicleTrailer1Plate': form.VehicleTrailer1Plate,
      'VehicleTrailer2TypeId': form.VehicleTrailer2TypeId,
      'VehicleTrailer2ContainerTypeId': form.VehicleTrailer2ContainerTypeId,
      'VehicleTrailer2ContainerNumber': form.VehicleTrailer2ContainerNumber,
      'VehicleTrailer2Plate': form.VehicleTrailer2Plate,
      'TransporterTitle': form.TransporterTitle,
      'TotalScore': Hesaplamak,
      'Exemption1136': form.Exemption,
    },
    'Products': [
      {

      }]

  }

  formdatas.Products = getProduct();
  formdatass.Product = getCalc();

  const handleOnChanges = (key, e) => {
    const tempDataUN = [...UnCodeId0, { key: key, value: e.target.value }];
    setUnCodeId0(tempDataUN);

    dataProvider.DataUNCodeGetir(e.target.value)

      .then(({ dataUn }) => {
        if (dataUn.ADRTankCode === "TAŞINMASI YASAK") {
          alert("This product is not allowed to transport (TAŞINMASI YASAK!)")
        } else {

          setDataUn(dataUn);
          const resultUnName = dataUn.Name;
          const resultsClass = dataUn.CarryingTunnelCode;
          const resultsSubClass = dataUn.ClassCode;
          const resultsPackingGroup = dataUn.PackageGroup;
          const resultsSpecialPackagingProvision = dataUn.SpecialPackagingProvision;
          const resultsCarryingCategory = dataUn.CarryingCategory;
          const resultsPackage = dataUn.PackageCount;
          const resultsPackageType = dataUn.PackageType;
          const resultsLimitedQuantity = dataUn.LimitedExceptionalAmountsSevenA;
          const resultsProperShipmentName = dataUn.ProperShipmentName;

          console.log("unlist->", dataUn)
          const tempDataUNName = [...UnName, { key: key, value: resultUnName }];
          setUnName(tempDataUNName);
          console.log("subclass->", resultsLimitedQuantity)
          const tempDataCarryingTunnelCode = [...Class0, { key: key, value: resultsClass }];
          setClass0(tempDataCarryingTunnelCode);
          const tempDataSubClass = [...SubClass0, { key: key, value: resultsSubClass }];
          setSubClass0(tempDataSubClass);
          const tempPackingGroup = [...PackingGroup0, { key: key, value: resultsPackingGroup }];
          setPackingGroup0(tempPackingGroup);
          const tempSpecialPackagingProvision = [...FlashPoint0, { key: key, value: resultsSpecialPackagingProvision }];
          setFlashPoint0(tempSpecialPackagingProvision);
          console.log("Özel->", tempSpecialPackagingProvision, "-", resultsSpecialPackagingProvision)
          const tempCarryingCategory = [...EMS0, { key: key, value: resultsCarryingCategory }];
          setEMS0(tempCarryingCategory);
          const tempPackage = [...Package0, { key: key, value: resultsPackage }];
          setPackage0(tempPackage);
          const tempPackageType0 = [...PackageType0, { key: key, value: resultsPackageType }];
          setPackageType0(tempPackageType0);
          const tempLimitedQuantity = [...LimitedQuantity0, { key: key, value: resultsLimitedQuantity }];
          setLimitedQuantity0(tempLimitedQuantity);
          const tempProperShipmentName = [...ProperShipmentName0, { key: key, value: resultsProperShipmentName[0] }];
          setProperShipmentName0(tempProperShipmentName);
        }

      });
  }

  const onOptionChange = (event) => {
    var isaretlemes = event.target.id;
    console.log("radio=", isaretlemes);
    if (isaretlemes === "Exemption") {
      console.log("bumu-",Exemption);
        setField("Exemption", true);
    }

};
  const handleCalculate = (e) => {
    console.log("giden=", getCalc());
    if (getCalc()[0].PackageCount != null || getCalc()[0].TotalNetWeight != null) {
      dataProvider
        .TehlikeHesabı(
          JSON.stringify(formdatass)
        )
        .then(({ data }) => {

          const resultTotalScore = data.TotalScore;
          const resultProductScore = data.Product;
          const verisi = resultProductScore.length;

          const getScore = resultProductScore.map(function (item, i) {

            return item.Score

          });
          const tempDataProductScore = [...ProductScore, { key: verisi - 1, value: getScore }];
          setProductScore(tempDataProductScore);
          console.log("unlist->", resultProductScore)

          setHesaplamak(resultTotalScore);
          if(resultTotalScore < 1000){
            setsetMuafmi(true)
          }else{
            setsetMuafmi(false)
          }
          //notify(`Form Saved Successfully`, { type: "success", undoable: false });

        });
    } else {
      notify(`Paket Sayısı ve Toplam Net Ağırlık Doldurulmalı`, { type: "error", undoable: false });
    }
  }

  const handleSubmit = (event) => {
    console.log("gidenform=>", formdatas)
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    dataProvider
      .TasimaEvraki(
        JSON.stringify(formdatas)
      ).catch((error) => {
        console.log('Error ' + error);
        alert("Eksik Alanları Doldurunuz... ("+error+")");
        //window.location.reload();
      })
      .then(({ data }) => {
        notify(`Form Saved Successfully`, { type: "success", undoable: false });

      });
  }
  useEffect(() => {

    Promise.all([
     
      (() =>{
        if (!datas)
        dataProvider.DataUNCode()
          .then(({ datas }) => {
  
            //console.log("datas", datas)
            setDatas(datas.data);
          });
      } )(),
      (() =>{
        if (!data)
        dataProvider.DataCountry()
          .then(({ data }) => {
            const tr =  data.data?.filter(x=> x.Code=="TR")[0];
            const newCodes =[ tr, ...data.data?.filter(x=> x.Code!="TR")];
            console.log("ulkeler", newCodes)
            setData(newCodes);
          });
      } )(),
      (() =>{
        if (!data)
        dataProvider.DataVehicleType()
          .then(({ data }) => {
            setVehicleType(data);
  
            setVehicleSubType([]);
          });
      } )(),
      (() =>{
        if (!data)
      dataProvider.DataVehicleContainerType()
        .then(({ data }) => {
          setVehicleContainerType(data);
          console.log("container=>",data)
        });
      } )(),
      (() =>{
        if (!data)
        dataProvider.DataTrailerTypes()
          .then(({ data }) => {
            settrailerType(data);
          })
      } )()
    ])
  }, [])
  
 
  //ARAÇLAR

  const vehicleOnChanges = (e) => {

    dataProvider.DataVehicleSubType(e.target.value)

      .then(({ datavehicle }) => {
        console.log("arac=>", datavehicle);

        setVehicleSubType(datavehicle);
      });

  }
  
  const sorucevap = (event) => {
    setVarmi(true)
  }
  const sorucevap1 = (event) => {
    setVarmi(false)
  }
  //ARAÇLAR
  useState(() => {
    if (!datas)
      dataProvider.DataUNCode()
        .then(({ datas }) => {
          setDatas(datas.data);
        });



  });

  const onAddBtnClick = event => {
    setIsShown(true);
    console.log("kaç defa =>", inputList.length);
    setInputList(inputList.concat(<div key={inputList.length} >



    </div>
    ));
  };

  return data && datas && (
    <Card style={{ marginTop: "1em" }} >
      <Title title={"Transport Document Form"} />
      <CardContent>

        <Form noValidate validated={validated} onSubmit={handleSubmit}>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="FormName">
              <Form.Label>Form Name (Form Adı)</Form.Label>
              <Form.Control required
                value={form.FormName}
                onChange={(v) => setField('FormName', v.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="FormDate">
              <Form.Label>Form Date (Form Tarihi)</Form.Label>
              <Form.Control required
                value={form.FormDate}
                onChange={(v) => setField('FormDate', v.target.value)} type="date" />
            </Form.Group>

          </Row>
          <div>
            <Typography variant="h5" gutterBottom className="mb-4">
              Sender (Gönderen)
            </Typography>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="SenderCompanyName">
                <Form.Label>Company Name (Firma Adı)</Form.Label>
                <Form.Control required
                  value={form.SenderCompanyName}
                  onChange={(v) => setField('SenderCompanyName', v.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="SenderCompanyAddress">
                <Form.Label>Company Address (Firma Adresi)</Form.Label>
                <Form.Control required
                  value={form.SenderCompanyAddress}
                  onChange={(v) => setField('SenderCompanyAddress', v.target.value)} as="textarea" rows={2} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="SenderCompanyCountryId">
                <Form.Label>Company Country (Firma Ülke)</Form.Label>
                <Form.Control
                  as="select" required
                  value={form.SenderCompanyCountryId}
                  onChange={(v) => setField('SenderCompanyCountryId', v.target.value)} type="number"
                >
                  <option>Select (Seçiniz)</option>
                  {Object.keys(data).map(element => <option key={element} value={data[element].Id}>{data[element].ENName}</option>)}
                </Form.Control>

              </Form.Group>
              <Form.Group as={Col} controlId="SenderCompanyCity">
                <Form.Label>Company City (Firma Şehir)</Form.Label>
                <Form.Control required
                  value={form.SenderCompanyCity}
                  onChange={(v) => setField('SenderCompanyCity', v.target.value)} type="text" />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="SenderCompanyTaxAdministration">
                <Form.Label>Company Tax Administration (Firma Vergi Dairesi)</Form.Label>
                <Form.Control required
                  value={form.SenderCompanyTaxAdministration}
                  onChange={(v) => setField('SenderCompanyTaxAdministration', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="SenderCompanyTaxNumber">
                <Form.Label>Company Tax Number (Firma Vergi Numarası)</Form.Label>
                <Form.Control required
                  value={form.SenderCompanyTaxNumber}
                  onChange={(v) => setField('SenderCompanyTaxNumber', v.target.value)} type="number" />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="SenderCompanyDocumentNumberUATF">
                <Form.Label>Company Document Number UATF (Firma Doküman Numarası UATF)</Form.Label>
                <Form.Control required
                  value={form.SenderCompanyDocumentNumberUATF}
                  onChange={(v) => setField('SenderCompanyDocumentNumberUATF', v.target.value)} type="text" />
              </Form.Group>

              <Form.Group as={Col} controlId="SenderResponsibleName">
                <Form.Label>Responsible Name (Sorumlunun Adı)</Form.Label>
                <Form.Control required
                  value={form.SenderResponsibleName}
                  onChange={(v) => setField('SenderResponsibleName', v.target.value)} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="TMFBNumber">
                <Form.Label>TMFB Number (TMFB Numarası) </Form.Label>
                <Form.Control required
                  value={form.TMFBNumber}
                  onChange={(v) => setField('TMFBNumber', v.target.value)} type="number" />
              </Form.Group>

              <Form.Group as={Col} controlId="ConsigneeCountryId">
                <Form.Label>Consignee Country (Alıcı Ülke)</Form.Label>
                <Form.Control
                  as="select"
                  value={form.ConsigneeCountryId}
                  onChange={(v) => setField('ConsigneeCountryId', v.target.value)} type="number"
                >
                  <option>Select (Seçiniz)</option>
                  {Object.keys(data).map(element => <option key={element} value={data[element].Id}>{data[element].ENName}</option>)}

                </Form.Control>

              </Form.Group>

            </Row>
          </div>
          <hr />
          <div>
            <Typography variant="h5" gutterBottom className="mb-4">
              Receiver (Alıcı)
            </Typography>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="ReceiverCompanyName">
                <Form.Label>Company Name (Firma Adı)</Form.Label>
                <Form.Control
                  value={form.ReceiverCompanyName}
                  onChange={(v) => setField('ReceiverCompanyName', v.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="ReceiverCompanyAddress">
                <Form.Label>Company Address (Firma Adresi)</Form.Label>
                <Form.Control
                  value={form.ReceiverCompanyAddress}
                  onChange={(v) => setField('ReceiverCompanyAddress', v.target.value)} as="textarea" rows={2} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="ReceiverCompanyCountryId">
                <Form.Label>Company Country (Firma Ülkesi)</Form.Label>
                <Form.Control
                  as="select"
                  value={form.ReceiverCompanyCountryId}
                  onChange={(v) => setField('ReceiverCompanyCountryId', v.target.value)} type="number"
                >
                  <option>Select (Seçiniz)</option>
                  {Object.keys(data).map(element => <option key={element} value={data[element].Id}>{data[element].ENName}</option>)}
                </Form.Control>

              </Form.Group>
              <Form.Group as={Col} controlId="ReceiverCompanyCity">
                <Form.Label>Company City (Firma Şehri)</Form.Label>
                <Form.Control
                  value={form.ReceiverCompanyCity}
                  onChange={(v) => setField('ReceiverCompanyCity', v.target.value)} type="text" />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="ReceiverCompanyTaxAdministration">
                <Form.Label>Company Tax Administration (Firma Vergi Dairesi)</Form.Label>
                <Form.Control
                  value={form.ReceiverCompanyTaxAdministration}
                  onChange={(v) => setField('ReceiverCompanyTaxAdministration', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="ReceiverCompanyTaxNumber">
                <Form.Label>Company Tax Number (Firma VErgi Numarası)</Form.Label>
                <Form.Control
                  value={form.ReceiverCompanyTaxNumber}
                  onChange={(v) => setField('ReceiverCompanyTaxNumber', v.target.value)} type="number" />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="ReceivingOrganizationReceipt">
                <Form.Label>Receiving Organisatıon Remarks (Alıcı Açıklamaları)</Form.Label>
                <Form.Control
                  value={form.ReceivingOrganizationReceipt}
                  onChange={(v) => setField('ReceivingOrganizationReceipt', v.target.value)} />
              </Form.Group>

            </Row>
          </div>
          <hr />
          <div>
            <Typography variant="h5" gutterBottom className="mb-4">
              Transporter (Taşıyıcı)
            </Typography>


            <Row className="mb-3">
              <Form.Group as={Col} controlId="DriverName">
                <Form.Label>Driver Name (Sürücü Adı Soyadı)</Form.Label>
                <Form.Control required
                  value={form.DriverName}
                  onChange={(v) => setField('DriverName', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="TransporterTitle">
                <Form.Label>Transporter Title (Taşıyıcı Adı)</Form.Label>
                <Form.Control required
                  value={form.TransporterTitle}
                  onChange={(v) => setField('TransporterTitle', v.target.value)} />
              </Form.Group>
            </Row>
            <Row className="mb-3">

              <Form.Group as={Col} controlId="VehicleTypeId">
                <Form.Label>Vehicle Type (Araç Tipi)</Form.Label>
                <Form.Control
                  as="select" required
                  value={form.VehicleTypeId}
                  onChange={e => vehicleOnChanges(e)}
                >
                  <option >Select</option>
                  {Object.keys(vehicleType).map(elements => <option key={elements} value={vehicleType[elements].Id}>{vehicleType[elements].Name}</option>)}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleSubTypeId">
                <Form.Label>Vehicle Subtype (Araç Alt Tipi)</Form.Label>
                <Form.Control
                  as="select" required
                  value={form.VehicleSubTypeId}
                  onChange={(v) => setField('VehicleSubTypeId', v.target.value)}
                >
                  <option value={""}>Select</option>
                  {Object.keys(vehicleSubType).map(elements => <option key={elements} value={vehicleSubType[elements].Id}>{vehicleSubType[elements].Name}</option>)}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="VehiclePlate">
                <Form.Label>Vehicle Plate (Araç Plakası)</Form.Label>
                <Form.Control required
                  value={form.VehiclePlate1}
                  onChange={(v) => setField('VehiclePlate', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleSealNumbers">
                <Form.Label>Vehicle Seal Numbers (Araç Mühür Numarası)</Form.Label>
                <Form.Control
                  value={form.SealNumbers}
                  onChange={(v) => setField('VehicleSealNumbers', v.target.value)} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="row" >
                <Form.Label>Is There a Container in the Vehicle? (Araçta Konteyner Var mı ?)</Form.Label>

                <Form.Group className="col-3" >
                  <Form.Check type="radio"
                    inline
                    label="YES"
                    name="sorucevap"
                    onChange={sorucevap}
                  />
                </Form.Group>
                <Form.Group className="col-3">
                  <Form.Check type="radio"
                    inline
                    label="No"
                    name="sorucevap"
                    onChange={sorucevap1}
                  />
                </Form.Group>
              </Form.Group>

            </Row>
            {Varmi ? (
              <div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="VehicleContainerTypeId">
                    <Form.Label>Vehicle 1. Container Type (Araç 1.Konteyner Tipi)</Form.Label>
                    <Form.Control
                      as="select"
                      value={form.VehicleContainerTypeId}
                      onChange={(v) => setField('VehicleContainerTypeId', v.target.value)}
                    >
                      <option value={""}>Select</option>
                      {Object.keys(vehicleContainerType).map(elements => <option key={elements} value={vehicleContainerType[elements].Id}>{vehicleContainerType[elements].Name}</option>)}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} controlId="VehicleContainerNumber">
                    <Form.Label>Vehicle 1. Container Number (Araç 1.Konteyner Numarası)</Form.Label>
                    <Form.Control
                      value={form.VehicleContainerNumber}
                      onChange={(v) => setField('VehicleContainerNumber', v.target.value)} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="VehicleContainerSealNumbers">
                    <Form.Label>Vehicle 1. Container Seal Numbers (Araç 1.Konteyner Mühür Numarası)</Form.Label>
                    <Form.Control
                      value={form.SealNumbers}
                      onChange={(v) => setField('VehicleContainerSealNumbers', v.target.value)} />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="VehicleContainer2TypeId">
                    <Form.Label>Vehicle 2. Container Type (Araç 2.Konteyner Tipi)</Form.Label>
                    <Form.Control
                      as="select"
                      value={form.VehicleContainer2TypeId}
                      onChange={(v) => setField('VehicleContainer2TypeId', v.target.value)}
                    >
                      <option value={""}>Select</option>
                      {Object.keys(vehicleContainerType).map(elements => <option key={elements} value={vehicleContainerType[elements].Id}>{vehicleContainerType[elements].Name}</option>)}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} controlId="VehicleContainer2Number">
                    <Form.Label>Vehicle 2. Container Number (Araç 2.Konteyner Numarası)</Form.Label>
                    <Form.Control
                      value={form.VehicleContainer2Number}
                      onChange={(v) => setField('VehicleContainer2Number', v.target.value)} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="SealNumbers">
                    <Form.Label>Vehicle 2. Container Seal Numbers (Araç 2.Konteyner Mühür Numarası)</Form.Label>
                    <Form.Control
                      value={form.SealNumbers}
                      onChange={(v) => setField('VehicleContainer2SealNumbers', v.target.value)} />
                  </Form.Group>
                </Row>
              </div>
            ) : null}
            <Row className="mb-3">
              <Form.Group as={Col} controlId="VehicleTrailer1Plate">
                <Form.Label>1. Semi Trailer Plate (1. Römork Plakası)</Form.Label>
                <Form.Control
                  value={form.VehicleTrailer1Plate}
                  onChange={(v) => setField('VehicleTrailer1Plate', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleTrailer1TypeId" className="col-2">
                <Form.Label>1. Semi Trailer Type (1. Römork Tipi)</Form.Label>
                <Form.Control
                  as="select"
                  value={form.VehicleTrailer1TypeId}
                  onChange={(v) => setField('VehicleTrailer1TypeId', v.target.value)}
                >
                  <option >Select</option>
                  {Object.keys(trailerType).map(elements => <option key={elements} value={trailerType[elements].Id}>{trailerType[elements].Name}</option>)}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleTrailer1ContainerTypeId">
                <Form.Label>1. Semi Trailer Container Type (1. Römork Konteyner Tipi)</Form.Label>
                <Form.Control
                  as="select"
                  value={form.VehicleTrailer1ContainerTypeId}
                  onChange={(v) => setField('VehicleTrailer1ContainerTypeId', v.target.value)}
                >
                  <option value={""}>Select</option>
                  {Object.keys(vehicleContainerType).map(elements => <option key={elements} value={vehicleContainerType[elements].Id}>{vehicleContainerType[elements].Name}</option>)}
                </Form.Control>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="VehicleTrailer1ContainerNumber">
                <Form.Label>1. Semi Trailer Container Number (1. Römork Konteyner Numarası)</Form.Label>
                <Form.Control
                  value={form.VehicleTrailer1ContainerNumber}
                  onChange={(v) => setField('VehicleTrailer1ContainerNumber', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleTrailer1ContainerSealNumbers">
                <Form.Label>1. Semi Trailer Container Seal Numbers (1. Römork Konteyner Mühür Numarası)</Form.Label>
                <Form.Control
                  value={form.SealNumbers}
                  onChange={(v) => setField('VehicleTrailer1ContainerSealNumbers', v.target.value)} />
              </Form.Group>
            </Row><hr />
            <Row className="mb-3">
              <Form.Group as={Col} controlId="VehicleTrailer2Plate">
                <Form.Label>2. Trailer Plate (2. Römork Plakası)</Form.Label>
                <Form.Control
                  value={form.VehicleTrailer2Plate}
                  onChange={(v) => setField('VehicleTrailer2Plate', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleTrailer2TypeId" >
                <Form.Label>2. Trailer Type (2. Römork Tipi)</Form.Label>
                <Form.Control
                  as="select"
                  value={form.VehicleTrailer2TypeId}
                  onChange={(v) => setField('VehicleTrailer2TypeId', v.target.value)}
                >
                  <option >Select</option>
                  {Object.keys(trailerType).map(elements => <option key={elements} value={trailerType[elements].Id}>{trailerType[elements].Name}</option>)}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleTrailer2ContainerTypeId">
                <Form.Label>2. Trailer Container Type (2. Römork Konteyner Tipi)</Form.Label>
                <Form.Control
                  as="select"
                  value={form.VehicleTrailer2ContainerTypeId}
                  onChange={(v) => setField('VehicleTrailer2ContainerTypeId', v.target.value)}
                >
                  <option value={""}>Select</option>
                  {Object.keys(vehicleContainerType).map(elements => <option key={elements} value={vehicleContainerType[elements].Id}>{vehicleContainerType[elements].Name}</option>)}
                </Form.Control>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="VehicleTrailer1ContainerNumber">
                <Form.Label>2. Trailer Container Number (2. Römork Konteyner Numarası)</Form.Label>
                <Form.Control
                  value={form.VehicleTrailer2ContainerNumber}
                  onChange={(v) => setField('VehicleTrailer2ContainerNumber', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleTrailer2ContainerSealNumbers">
                <Form.Label>2. Trailer Container Seal Numbers (2. Römork Konteyner Mühür Numarası)</Form.Label>
                <Form.Control
                  value={form.VehicleTrailer2ContainerSealNumbers}
                  onChange={(v) => setField('VehicleTrailer2ContainerSealNumbers', v.target.value)} />
              </Form.Group>
            </Row>
          </div>
          <hr />
          <Typography variant="h5" gutterBottom className="mb-4">
            Products (Ürünler)
          </Typography>
          {inputList.map((item, i) => {

            return <div key={inputList.length} >

              <Row className="mb-3">
                <Form.Group as={Col} controlId={"UNCodeId" + i}>
                  <Form.Label>UN Code Id (UN Kod Kimliği)</Form.Label>
                  <Form.Control
                    as="select" required
                    value={getValuesss(i)}
                    onChange={e => handleOnChanges(i, e)}
                  >
                    <option >Select</option>
                    {Object.keys(datas).map(elements => <option key={elements} value={datas[elements].Id}>{datas[elements].UNNumber} - {datas[elements].MinistryCode} - {datas[elements].Name}</option>)}
                  </Form.Control>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId={"SubClass" + i}>
                  <Form.Label>Sub Class (Alt Sınıf)</Form.Label>
                  <Form.Control required disabled
                    value={getSubClassValue(i)}
                    onChange={(v) => setField('SubClass' + i, v.target.value)} />
                </Form.Group>
                <Form.Group as={Col} controlId={"PackingGroup" + i}>
                  <Form.Label>Packing Group (Paketleme Grubu)</Form.Label>
                  <Form.Control required disabled
                    value={getPackingGroup(i)}
                    onChange={(v) => setField('PackingGroup' + i, v.target.value)} />
                </Form.Group>

              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId={"TunelCode" + i}>
                  <Form.Label>Tunnel Code (Tünel Kodu)</Form.Label>
                  <Form.Control required disabled
                    value={getClassValue(i)}
                    onChange={(v) => setField('TunelCode' + i, v.target.value)} />
                </Form.Group>

                <Form.Group as={Col} controlId={"SpecialProvision" + i}>
                  <Form.Label>Special Provision (Özel Hükümler)</Form.Label>
                  <Form.Control required disabled
                    value={getFlashPoint(i)}
                    onChange={(v) => setField('SpecialProvision' + i, v.target.value)} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId={"PackageType" + i}>
                  <Form.Label>Package Type (Paket Tipi)</Form.Label>
                  <Form.Control
                    value={getPackageType0(i)}
                    onChange={(v) => setField('PackageType' + i, v.target.value)} />
                </Form.Group>
                <Form.Group as={Col} controlId={"CarryingCategory" + i}>
                  <Form.Label>Carrying Category (Taşıma Kategorisi)</Form.Label>
                  <Form.Control required disabled
                    value={getEMS0(i)}
                    onChange={(v) => setField('CarryingCategory' + i, v.target.value)} type="number" />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId={"ProperShippingName" + i}>
                  <Form.Label>Proper Shipping Name (Uygun Sevkiyat Adı)  </Form.Label>
                  <Form.Control required
                    value={form["ProperShippingName" + i]}
                    onChange={(v) => setField('ProperShippingName' + i, v.target.value)} />
                  <p><strong>Suggestion (Öneri):</strong> {getUnNameValue(i)}</p>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId={"PackageCount" + i}>
                  <Form.Label>Package (Paket Sayısı)</Form.Label>
                  <Form.Control required
                    value={form["PackageCount" + i]}
                    onChange={(v) => setField('PackageCount' + i, v.target.value)} type="number" />
                </Form.Group>
                <Form.Group as={Col} controlId={"TotalNetWeight" + i}>
                  <Form.Label>Total Net Weight (Toplam Net Ağırlık)</Form.Label>
                  <Form.Control
                    value={form["TotalNetWeight" + i]}
                    onChange={(v) => setField('TotalNetWeight' + i, v.target.value)} type="number" />
                </Form.Group>
                <Form.Group as={Col} controlId={"DangerScore" + i}>
                  <Form.Label>Score (Puanı)</Form.Label>
                  <Form.Control disabled
                    value={getProductScore(i)}
                    onChange={(v) => setField('DangerScore' + i, v.target.value)} />
                </Form.Group>
                <Form.Group as={Col} style={{ alignSelf: "self-end" }}>

                  <Button variant="success" onClick={handleCalculate} >Calculate (Hesapla)</Button>
                </Form.Group>

              </Row>
            </div>
          })
          }
          <hr />

          <Button variant="secondary" onClick={onAddBtnClick}>Add Product (Ürün Ekle)</Button>

          <Row className="mb-3">
            <Typography variant="h5" gutterBottom className="mb-4" style={{ textAlign: "center" }}>
              <strong>Danger Rating (Tehlike Puanı)</strong> <br /><strong style={{ color: "blue" }}>{Hesaplamak}</strong>
              <Form.Group as={Col} controlId={"TotalScore"} style={{ display: "none" }}>
                <Form.Control required
                  value={Hesaplamak}
                  onChange={(v) => setField('TotalScore', v.target.value)} type="hidden" />
              </Form.Group>
            </Typography>
            {isShown ? (
              <Form.Group className="mb-3" as={Col} style={{ textAlign: "center" }}><br />

              </Form.Group>
            ) : null}
          </Row>
          {muafmi ? (
          <Row className="mb-3">
            <Form.Group as={Col} className="row" >
              <Form.Group as={Col} controlId="Exemption">
                <Form.Check type="radio" 
                  inline
                  id="Exemption"
                  label="Transportation Compliant with ADR 1.1.3.6 (1000 POINTS) Exemption.(ADR 1.1.3.6 (1000 PUAN) Muafiyetine Uygun Taşımacılık.)"
                  name="grup2"
                  value={Exemption}
                  onChange={onOptionChange}
                />
              </Form.Group>
            </Form.Group>

          </Row>
          ): null}
          <hr />
          {isShown ? (
            <Modal.Footer>
              <Button variant="primary" type="submit">
                Save (Kaydet)
              </Button>

            </Modal.Footer>
          ) : null}
        </Form>
      </CardContent>
    </Card>
  );

};


import axios from 'axios';
// other imports


const register = (props) => {
    console.log("reg=",props);
  try {
    axios.post('https://uranus.logfiks.com/api/User/Register', props)
        .then(props => {
            setTimeout(() => {
                window.location.reload();
            }, 500);
            return Promise.resolve();
        }).catch(err =>{
            if (err.response && 419 === err.response.status) {
                window.location.reload()
            }
            console.log(err)
            setTimeout(() => {
                window.location.reload();
            }, 500);
            return Promise.reject(err)
        });
  } catch (err) {
    if (err.response && 419 === err.response.status) {
        window.location.reload()
    }
    console.log(err)
    return Promise.reject(err)
  }
};
export default register;
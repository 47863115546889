import { useDataProvider  } from "react-admin";
  import React, { useEffect, useState } from "react";

export const UlkeList = () => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState(null);
    useEffect(() => {
if(!data)
        dataProvider.DataCountry()
            .then(({ data  }) => {

                console.log("data",data)
                setData(data.data);
                
            });
    },[data, dataProvider] );

    return data && (
      <select>
    {Object.keys(data).map(
      element => <option key={element} value={data[element].Id}>{data[element].ENName}</option>

      )
      }
      </select>
      
    );

    };
import React, {  useEffect, useState, } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Title,
  useDataProvider, useNotify
} from "react-admin";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card, CardContent, Typography } from "@mui/material";
import Modal from 'react-bootstrap/Modal';
export const KaraDeniz = (props) => {
  const notify = useNotify();
  const [validated, setValidated] = useState(null);
  const dataProvider = useDataProvider();
  const [data, setData] = useState(null);
  const [dataUn, setDataUn] = useState(null);
  const [datas, setDatas] = useState(null);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [inputList, setInputList] = useState([]);
  const [UnName, setUnName] = useState([])
  const [UnCodeId0, setUnCodeId0] = useState([])
  const [Class0, setClass0] = useState([])
  const [SubClass0, setSubClass0] = useState([])
  const [PackingGroup0, setPackingGroup0] = useState([])
  const [FlashPoint0, setFlashPoint0] = useState([])
  const [EMS0, setEMS0] = useState([])
  const [Package0, setPackage0] = useState([])
  const [PackageType0, setPackageType0] = useState([])
  const [LimitedQuantity0, setLimitedQuantity0] = useState([])
  const [MarinePollutant, setMarinePollutant] = useState([])
  const [ProperShipmentName0, setProperShipmentName0] = useState([])
  const [vehicleType, setVehicleType] = useState(null);
  const [vehicleTypeId, setVehicleTypeId] = useState([]);
  const [trailerType, settrailerType] = useState([]);
  const [vehicleSubType, setVehicleSubType] = useState([]);
  const [vehicleContainerType, setVehicleContainerType] = useState([]);
  const [Varmi, setVarmi] = useState(true);


  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    })
    if(!!errors[field])
      setErrors({
        ...errors,
        [field]:null
  })
  }

  useEffect(() => {

    Promise.all([
     
      (() =>{
        if (!datas)
        dataProvider.DataUNCode()
          .then(({ datas }) => {
  
            //console.log("datas", datas)
            setDatas(datas.data);
          });
      } )(),
      (() =>{
        if (!data)
        dataProvider.DataCountry()
          .then(({ data }) => {
            const tr =  data.data?.filter(x=> x.Code=="TR")[0];
            const newCodes =[ tr, ...data.data?.filter(x=> x.Code!="TR")];
            console.log("ulkeler", newCodes)
            setData(newCodes);
          });
      } )(),
      (() =>{
        if (!data)
        dataProvider.DataVehicleType()
          .then(({ data }) => {
            setVehicleType(data);
  
            setVehicleSubType([]);
          });
      } )(),
      (() =>{
        if (!data)
      dataProvider.DataVehicleContainerType()
        .then(({ data }) => {
          setVehicleContainerType(data);
          console.log("container=>",data)
        });
      } )(),
      (() =>{
        if (!data)
        dataProvider.DataTrailerTypes()
          .then(({ data }) => {
            settrailerType(data);
          })
      } )()
    ])
  }, [])
  

  const getValuesss = (index) => {
    const val = UnCodeId0.filter(x => x.key === index);
    if (val.length > 0)
      return val[0].value;

    return "";

  }
  const getClassValue = (index) => {
    const val = Class0.filter(x => x.key === index);
    if (val.length > 0)
      return val[0].value;

    return "";

  }
  const getSubClassValue = (index) => {
    //console.log("veriCss=", SubClass0);
    const val = SubClass0.filter(x => x.key === index);
    if (val.length > 0)
      return val[0].value;

    return "";

  }
  const getUnNameValue = (index) => {
    //console.log("veriCssName=", UnName);
    const val = UnName.filter(x => x.key === index);
    if (val.length > 0)
      return val[0].value;

    return "";

  }
  const getPackingGroup = (index) => {
    const val = PackingGroup0.filter(x => x.key === index);
    if (val.length > 0)
      return val[0].value;

    return "";

  }
  const getFlashPoint = (index) => {
    const val = FlashPoint0.filter(x => x.key === index);
    if (val.length > 0)
      return val[0].value;

    return "";

  }
  const getEMS0 = (i) => {

    console.log("ems", JSON.stringify(EMS0))
    const val = EMS0.map(x=> x?.Definition?.Name).join(", ");
  
    return val

  }
  const getPackage0 = (index) => {
    const val = Package0.filter(x => x.key === index);
    if (val.length > 0)
      return val[0].value;

    return "";
  }
  const getPackageType0 = (index) => {
    const val = PackageType0.filter(x => x.key === index);
    if (val.length > 0)
      return val[0].value;

    return "";

  }
  const getLimitedQuantity0 = (index) => {
    console.log("veriMa=", LimitedQuantity0);
    const val = LimitedQuantity0.filter(x => x.key === index);
    if (val.length > 0)
    if(val[LimitedQuantity0.length-1].value === "true"){
      return true;
    }else{
      return false;
    }
      //return val[LimitedQuantity0.length-1].value;

    return "";

  }
  const getMarinePollutant = (index) => {
    console.log("veriMa=", MarinePollutant);
    const val = MarinePollutant.filter(x => x.key === index);
    if (val.length > 0){
      if(val[MarinePollutant.length-1].value === "true"){
        return true;
      }else{
        return false;
      }
      //return val[MarinePollutant.length-1].value;
    }
    return "";

  }
  const getProduct = () => {
    const product = inputList.map((item, i) => {
      return {
        'UNCodeId': getValuesss(i),
        'ProperShippingName': form["ProperShippingName" + i],
        'Class': getClassValue(i),
        'SubClass': getSubClassValue(i),
        'PackingGroup': getPackingGroup(i),
        'FlashPoint': getFlashPoint(i),
        'MarinePollutant': getMarinePollutant(i),
        'EMS': getEMS0(i),
        'PackageCount': form["PackageCount" + i],
        'PackageType': getPackageType0(i),
        'LimitedQuantity': getLimitedQuantity0(i),
        'GrossMass': form["GrossMass" + i],
        'NetMass': form["NetMass" + i],
        'Cube': form["Cube" + i],
      }

    });

    return product;
  }


  const formdatas = {

    'Form': {
      'FormName': form.FormName,
      'PreparedByDefinition': form.PreparedByDefinition,
      'SenderTitle': form.SenderTitle,
      'SenderCountryId': form.SenderCountryId,
      'SenderAddress': form.SenderAddress,
      'TransportDocumentNumber': form.TransportDocumentNumber,
      'ShippersReference': form.ShippersReference,
      'FreightForwardersReference': form.FreightForwardersReference,
      'ConsigneeTitle': form.ConsigneeTitle,
      'ConsigneeCountryId': form.ConsigneeCountryId,
      'ConsigneeAddress': form.ConsigneeAddress,
      'Carrier': form.Carrier,
      'ShippersDeclaration': form.ShippersDeclaration,
      'ContactName': form.ContactName,
      'ContactNumber': form.ContactNumber,
      'AdditionalHandlingInformation': form.AdditionalHandlingInformation,
      'VesselName': form.VesselName,
      'VesselIMONumber': form.VesselIMONumber,
      'VesselDate': form.VesselDate,
      'PortOfLoading': form.PortOfLoading,
      'PortOfDischarge': form.PortOfDischarge,
      'EndDestination': form.Destination,
      'ShippingMarks': form.ShippingMarks,
      'DriverName': form.DriverName,
      'VehiclePlate': form.VehiclePlate,
      'VehicleTypeId': vehicleTypeId,
      'VehicleSubTypeId': form.VehicleSubTypeId,
      'VehicleSealNumber': form.VehicleSealNumber,
      'VehicleContainer1TypeId': form.VehicleContainerTypeId,
      'VehicleContainer1Number': form.VehicleContainerNumber,
      'VehicleContainer1SealNumber': form.VehicleContainer1SealNumber,
      'VehicleContainer2TypeId': form.VehicleContainer2TypeId,
      'VehicleContainer2SealNumber': form.VehicleContainer2SealNumber,
      'VehicleContainer2Number': form.VehicleContainer2Number,
      'VehicleTrailer1TypeId': form.VehicleTrailer1TypeId,
      'VehicleTrailer1ContainerTypeId': form.VehicleTrailer1ContainerTypeId,
      'VehicleTrailer1ContainerNumber': form.VehicleTrailer1ContainerNumber,
      'VehicleTrailer1Plate': form.VehicleTrailer1Plate,
      'VehicleTrailer1SealNumber': form.VehicleTrailer1ContainerSealNumber,
      'VehicleTrailer2TypeId': form.VehicleTrailer2TypeId,
      'VehicleTrailer2ContainerTypeId': form.VehicleTrailer2ContainerTypeId,
      'VehicleTrailer2ContainerNumber': form.VehicleTrailer2ContainerNumber,
      'VehicleTrailer2Plate': form.VehicleTrailer2Plate,
      'VehicleTrailer2SealNumber': form.VehicleTrailer2ContainerSealNumber,
      'TareMass': form.TareMass,
      'TotalGross': form.TotalGross,
      'NameOfCompany': form.NameOfCompany,
      'NameOfDeclarant': form.NameOfDeclarant,
      'StatusOfDeclarant': form.StatusOfDeclarant,
      'Place': form.Place,
      'Date': form.Date,
      'HauliersName': form.HauliersName,
      'HaulierVehicleRegistrationNo': form.HaulierVehicleRegistrationNo,
      'HaulierDate': form.HaulierDate,
      'ShipperNameOfCompany': form.ShipperNameOfCompany,
      'ShipperNameOfDeclarant': form.ShipperNameOfDeclarant,
      'ShipperStatusOfDeclarant': form.ShipperStatusOfDeclarant,
      'ShipperPlace': form.ShipperPlace,
      'ShipperDate': form.ShipperDate,
    },
    'Products': [
      {
        
      }]

  }

  formdatas.Products = getProduct();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    dataProvider
      .KaraDeniz(
        JSON.stringify(formdatas)
      ).catch((error) => {
        console.log('Error ' + error);
        alert("Eksik Alanları Doldurunuz... ("+error+")");
        //window.location.reload();
      })
      .then(({ sonuc }) => {
       
        notify(`Form Saved Successfully`, { type: "success", undoable: false });

      });
  };


  const handleOnChanges = (key, e) => {
    const tempDataUN = [...UnCodeId0, { key: key, value: e.target.value }];
    setUnCodeId0(tempDataUN);

    dataProvider.DataUNCodeGetir(e.target.value)

      .then(({ dataUn }) => {
        if (dataUn.IsSubjectForADR === 0) {
          alert("ADR'YE TABİ DEĞİLDİR.")
        } else {
          setDataUn(dataUn);
          const resultUnName = dataUn.Name;
          const resultsClass = dataUn.Class;
          const resultsSubClass = dataUn.ClassCode;
          const resultsPackingGroup = dataUn.PackageGroup;
          const resultsFlashPoint = dataUn.FlashPoint;
          const resultsEMS = dataUn.IMDG.EMS;
          const resultsPackage = dataUn.PackageCount;
          const resultsPackageType = dataUn.PackageType;
          //const resultsLimitedQuantity = dataUn.LimitedExceptionalAmountsSevenA;
          const resultsProperShipmentName = dataUn.ProperShipmentName;

          console.log("unlist->", dataUn.IMDG.EMS)
          const tempDataUNName = [...UnName, { key: key, value: resultUnName }];
          setUnName(tempDataUNName);
          //console.log("subclass->", resultsLimitedQuantity)
          const tempDataClass = [...Class0, { key: key, value: resultsClass[0] }];
          setClass0(tempDataClass);
          const tempDataSubClass = [...SubClass0, { key: key, value: resultsSubClass }];
          setSubClass0(tempDataSubClass);
          const tempPackingGroup = [...PackingGroup0, { key: key, value: resultsPackingGroup }];
          setPackingGroup0(tempPackingGroup);
          const tempFlashPoint = [...FlashPoint0, { key: key, value: resultsFlashPoint }];
          setFlashPoint0(tempFlashPoint);
          const tempEMS = resultsEMS;
          setEMS0(tempEMS);
          const tempPackage = [...Package0, { key: key, value: resultsPackage }];
          setPackage0(tempPackage);
          const tempPackageType0 = [...PackageType0, { key: key, value: resultsPackageType }];
          setPackageType0(tempPackageType0);
          const tempProperShipmentName = [...ProperShipmentName0, { key: key, value: resultsProperShipmentName[0] }];
          setProperShipmentName0(tempProperShipmentName);
          console.log("SONunlist->", EMS0);
        }

      });
  }
  const handleCheck = (event) => {
    var isaretleme = event.target.value;
    if (event.target.checked) {
      isaretleme = true
    } else {
      isaretleme = false
    }
    setField(event.target.name, Boolean(isaretleme))
  };
  const onAddBtnClick = event => {
    //console.log("kaç defa =>", inputList.length);
    setInputList(inputList.concat(1
    ));
  };
  const onOptionChange = (key,event) => {
    const tempMarin = [...MarinePollutant, { key: key, value: event.target.value }];
    setMarinePollutant(tempMarin);
    
  };
  const onOptionChanges = (key,event) => {
    const templim = [...LimitedQuantity0, { key: key, value: event.target.value }];
    setLimitedQuantity0(templim);
    
  };
  const vehicleOnChanges = (e) => {
    setVehicleTypeId(e.target.value)
    dataProvider.DataVehicleSubType(e.target.value)

      .then(({ datavehicle }) => {
        //console.log("arac=>", datavehicle);

        setVehicleSubType(datavehicle);
      });

  }
  
  const sorucevap = (event) => {
    setVarmi(true)
  }
  const sorucevap1 = (event) => {
    setVarmi(false)
  }

  return data && datas && (

    <Card style={{ marginTop: "1em" }} >
      <Title title={"Multimodal Dangerous Goods Form"} />
      <CardContent>

        <Form noValidate validated={validated} onSubmit={handleSubmit}>

          <div>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="PreparedByDefinition">
                <Form.Label>Shipper/Consignor/Sender (Gönderici/Gönderici/Gönderen)</Form.Label>
                <Form.Control
                  as="select"
                  value={form.PreparedByDefinition}
                  onChange={(v) => setField('PreparedByDefinition', v.target.value)}
                >
                  <option>Select</option>
                  <option value="Shipper">Shipper</option>
                  <option value="Consignor">Consignor</option>
                  <option value="Sender">Sender</option>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="FormName">
                <Form.Label>Form Name (Form Adı)</Form.Label>
                <Form.Control required
                  value={form.FormName}
                  onChange={(v) => setField('FormName', v.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="SenderTitle">
                <Form.Label>Sender Title (Gönderen Unvanı)</Form.Label>
                <Form.Control
                  value={form.SenderTitle}
                  onChange={(v) => setField('SenderTitle', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="TransportDocumentNumber">
                <Form.Label>Transport Document Number (Taşıma Belge Numarası)</Form.Label>
                <Form.Control
                  value={form.TransportDocumentNumber}
                  onChange={(v) => setField('TransportDocumentNumber', v.target.value)} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="SenderCountryId">
                <Form.Label>Country (Ülke)</Form.Label>
                <Form.Control
                  as="select"
                  value={form.SenderCountryId}
                  onChange={(v) => setField('SenderCountryId', v.target.value)} type="number"
                >
                  <option>Select</option>
                  {Object.keys(data).map(element => <option key={element} value={data[element].Id}>{data[element].ENName}</option>)}
                </Form.Control>

              </Form.Group>
              <Form.Group as={Col} controlId="ShippersReference">
                <Form.Label>Shipper's Reference (Göndericinin Referansı)</Form.Label>
                <Form.Control
                  value={form.ShippersReference}
                  onChange={(v) => setField('ShippersReference', v.target.value)} type="text" />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="SenderAddress">
                <Form.Label>Sender Address (Gönderen Adresi)</Form.Label>
                <Form.Control
                  value={form.SenderAddress}
                  onChange={(v) => setField('SenderAddress', v.target.value)} as="textarea" rows={3} />
              </Form.Group>
              <Form.Group as={Col} controlId="FreightForwardersReference">
                <Form.Label>Freight Forwarder Reference (Nakliyeci Referansı)</Form.Label>
                <Form.Control
                  value={form.FreightForwardersReference}
                  onChange={(v) => setField('FreightForwardersReference', v.target.value)} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="ConsigneeTitle">
                <Form.Label>Consignee Title (Alıcı Unvanı)</Form.Label>
                <Form.Control
                  value={form.ConsigneeTitle}
                  onChange={(v) => setField('ConsigneeTitle', v.target.value)} type="text" />
              </Form.Group>


            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="ConsigneeCountryId">
                <Form.Label>Consignee Country (Alıcı Ülke)</Form.Label>
                <Form.Control
                  as="select"
                  value={form.ConsigneeCountryId}
                  defaultOptionLabel={"Turkey"}
                  onChange={(v) => setField('ConsigneeCountryId', v.target.value)} type="number"
                >
                  <option>Select</option>
                  {Object.keys(data).map(element => <option key={element} value={data[element].Id}>{data[element].ENName}</option>)}

                </Form.Control>

              </Form.Group>
              <Form.Group as={Col} controlId="ConsigneeAddress">
                <Form.Label>Consignee Address (Alıcı Adresi)</Form.Label>
                <Form.Control
                  value={form.ConsigneeAddress}
                  onChange={(v) => setField('ConsigneeAddress', v.target.value)} as="textarea" rows={3} />
              </Form.Group>

            </Row>
            <Row className="mb-3">

              <Form.Group as={Col} controlId="ShipperNameOfCompany">
                <Form.Label>Shipper Name Of Company (Gönderici Adı Firma)</Form.Label>
                <Form.Control
                  value={form.ShipperNameOfCompany}
                  onChange={(v) => setField('ShipperNameOfCompany', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="ShipperNameOfDeclarant">
                <Form.Label>Shipper Name Of Declarant (Göndericinin Adı Beyan Edenin)</Form.Label>
                <Form.Control
                  value={form.ShipperNameOfDeclarant}
                  onChange={(v) => setField('ShipperNameOfDeclarant', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="ShipperStatusOfDeclarant">
                <Form.Label>Shipper Status Of Declarant (Bildiricinin Gönderici Durumu)</Form.Label>
                <Form.Control
                  value={form.ShipperStatusOfDeclarant}
                  onChange={(v) => setField('ShipperStatusOfDeclarant', v.target.value)} />
              </Form.Group>

            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="ShipperPlace">
                <Form.Label>Shipper Place (Gönderici Yeri)</Form.Label>
                <Form.Control
                  value={form.ShipperPlace}
                  onChange={(v) => setField('ShipperPlace', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="ShipperDate">
                <Form.Label>Shipper Date (Gönderici Tarihi)</Form.Label>
                <Form.Control
                  value={form.ShipperDate}
                  onChange={(v) => setField('ShipperDate', v.target.value)} type="date" />
              </Form.Group>

            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="ShippingMarks">
                <Form.Label>Shipping Marks (Gönderi İşaretleri)</Form.Label>
                <Form.Control
                  value={form.ShippingMarks}
                  onChange={(v) => setField('ShippingMarks', v.target.value)} />
              </Form.Group>
            </Row>


            <Row className="mb-12">
              <Form.Group as={Col} controlId="ShippersDeclaration">
                <Form.Label>SHIPPER'S DECLARATION (GÖNDERİCİ BEYANNAMESİ)</Form.Label>
                <Form.Group className="col-12" style={{ textAlign: "center" }} controlId="ShippersDeclaration">
                  <Form.Check inline
                    name="ShippersDeclaration" onChange={handleCheck}
                    label="I hereby declare that the contents of this consignment are fully and accurately described below by the proper shipping name(s), and are classified, packaged, marked and labelled/placarded, and are in all respects in proper condition for transport according to the applicable international and national governmental regulations. (Bu sevkıyatın içeriğinin uluslararası ve ulusal hükümet düzenlemelerine uygun sevkiyat ad(lar)ı ile tam ve doğru bir şekilde tanımlandığını ve sınıflandırıldığını, paketlendiğini, işaretlendiğini ve etiketlendiğini/yapıştırıldığını ve her bakımdan geçerli olana göre nakliye için uygun durumda olduğunu beyan ederim. )" />

                </Form.Group>

              </Form.Group>


            </Row>
            <Row className="mb-12">
              <Form.Group as={Col} controlId="Carrier">
                <Form.Label>Carrier (to be completed by the carrier) (Taşıyıcı)</Form.Label>
                <Form.Control
                  value={form.Carrier}
                  onChange={(v) => setField('Carrier', v.target.value)} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="VesselName">
                <Form.Label>Vessel Name (Gemi Adı)</Form.Label>
                <Form.Control
                  value={form.VesselName}
                  onChange={(v) => setField('VesselName', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="VesselIMONumber">
                <Form.Label>Vessel IMO Number (Gemi IMO Numarası)</Form.Label>
                <Form.Control
                  value={form.VesselIMONumber}
                  onChange={(v) => setField('VesselIMONumber', v.target.value)} type="text" />
              </Form.Group>


              <Form.Group as={Col} controlId="VesselDate">
                <Form.Label>Vessel Date (Gemi Tarihi)</Form.Label>
                <Form.Control
                  value={form.VesselDate}
                  onChange={(v) => setField('VesselDate', v.target.value)} type="date" />
              </Form.Group>

            </Row>
            <Row className="mb-3">

              <Form.Group as={Col} controlId="PortOfLoading">
                <Form.Label>Port Of Loading (Yükleme Limanı)</Form.Label>
                <Form.Control
                  value={form.PortOfLoading}
                  onChange={(v) => setField('PortOfLoading', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="PortOfDischarge">
                <Form.Label>Port Of Discharge (Boşaltma Limanı)</Form.Label>
                <Form.Control
                  value={form.PortOfDischarge}
                  onChange={(v) => setField('PortOfDischarge', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="Destination">
                <Form.Label>End Destination (Hedef Lokasyon)</Form.Label>
                <Form.Control
                  value={form.Destination}
                  onChange={(v) => setField('Destination', v.target.value)} />
              </Form.Group>

            </Row>


            <Row className="mb-3">
              <Form.Group as={Col} controlId="DriverName">
                <Form.Label>Driver Name (Sürücü Adı Soyadı)</Form.Label>
                <Form.Control 
                  value={form.DriverName}
                  onChange={(v) => setField('DriverName', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="TransporterTitle">
                <Form.Label>Transporter Title (Taşıyıcı Adı)</Form.Label>
                <Form.Control 
                  value={form.TransporterTitle}
                  onChange={(v) => setField('TransporterTitle', v.target.value)} />
              </Form.Group>
            </Row>
            <Row className="mb-3">

              <Form.Group as={Col} controlId="VehicleTypeId">
                <Form.Label>Vehicle Type (Araç Tipi)</Form.Label>
                <Form.Control
                  as="select" 
                  value={vehicleTypeId}
                  onChange={e => vehicleOnChanges(e)}
                >
                  <option >Select</option>
                  {Object.keys(vehicleType).map(elements => <option key={elements} value={vehicleType[elements].Id}>{vehicleType[elements].Name}</option>)}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleSubTypeId">
                <Form.Label>Vehicle Subtype (Araç Alt Tipi)</Form.Label>
                <Form.Control
                  as="select" 
                  value={form.VehicleSubTypeId}
                  onChange={(v) => setField('VehicleSubTypeId', v.target.value)}
                >
                  <option value={""}>Select</option>
                  {Object.keys(vehicleSubType).map(elements => <option key={elements} value={vehicleSubType[elements].Id}>{vehicleSubType[elements].Name}</option>)}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="VehiclePlate">
                <Form.Label>Vehicle Plate (Araç Plakası)</Form.Label>
                <Form.Control 
                  value={form.VehiclePlate1}
                  onChange={(v) => setField('VehiclePlate', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleSealNumber">
                <Form.Label>Vehicle Seal Numbers (Araç Mühür Numarası)</Form.Label>
                <Form.Control
                  value={form.VehicleSealNumber}
                  onChange={(v) => setField('VehicleSealNumber', v.target.value)} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} className="row" >
                <Form.Label>Is There a Container in the Vehicle? (Araçta Konteyner Var mı ?)</Form.Label>

                <Form.Group className="col-3" >
                  <Form.Check type="radio"
                    inline
                    label="YES"
                    name="sorucevap"
                    checked
                    onChange={sorucevap}
                  />
                </Form.Group>
                <Form.Group className="col-3">
                  <Form.Check type="radio"
                    inline
                    label="No"
                    name="sorucevap"
                    onChange={sorucevap1}
                  />
                </Form.Group>
              </Form.Group>

            </Row>
            {Varmi ? (
              <div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="VehicleContainerTypeId">
                    <Form.Label>Vehicle 1. Container Type (Araç 1.Konteyner Tipi)</Form.Label>
                    <Form.Control
                      as="select"
                      value={form.VehicleContainerTypeId}
                      onChange={(v) => setField('VehicleContainerTypeId', v.target.value)}
                    >
                      <option value={""}>Select</option>
                      {Object.keys(vehicleContainerType).map(elements => <option key={elements} value={vehicleContainerType[elements].Id}>{vehicleContainerType[elements].Name}</option>)}
                    </Form.Control>{vehicleContainerType?.length}
                  </Form.Group>
                  <Form.Group as={Col} controlId="VehicleContainerNumber">
                    <Form.Label>Vehicle 1. Container Number (Araç 1.Konteyner Numarası)</Form.Label>
                    <Form.Control
                      value={form.VehicleContainerNumber}
                      onChange={(v) => setField('VehicleContainerNumber', v.target.value)} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="VehicleContainer1SealNumber">
                    <Form.Label>Vehicle 1. Container Seal Numbers (Araç 1.Konteyner Mühür Numarası)</Form.Label>
                    <Form.Control
                      value={form.VehicleContainer1SealNumber}
                      onChange={(v) => setField('VehicleContainer1SealNumber', v.target.value)} />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="VehicleContainer2TypeId">
                    <Form.Label>Vehicle 2. Container Type (Araç 2.Konteyner Tipi)</Form.Label>
                    <Form.Control
                      as="select"
                      value={form.VehicleContainer2TypeId}
                      onChange={(v) => setField('VehicleContainer2TypeId', v.target.value)}
                    >
                      <option value={""}>Select</option>
                      {Object.keys(vehicleContainerType).map(elements => <option key={elements} value={vehicleContainerType[elements].Id}>{vehicleContainerType[elements].Name}</option>)}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} controlId="VehicleContainer2Number">
                    <Form.Label>Vehicle 2. Container Number (Araç 2.Konteyner Numarası)</Form.Label>
                    <Form.Control
                      value={form.VehicleContainer2Number}
                      onChange={(v) => setField('VehicleContainer2Number', v.target.value)} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="VehicleContainer2SealNumber">
                    <Form.Label>Vehicle 2. Container Seal Numbers (Araç 2.Konteyner Mühür Numarası)</Form.Label>
                    <Form.Control
                      value={form.VehicleContainer2SealNumber}
                      onChange={(v) => setField('VehicleContainer2SealNumber', v.target.value)} />
                  </Form.Group>
                </Row>
              </div>
            ) : null}
            <Row className="mb-3">
              <Form.Group as={Col} controlId="VehicleTrailer1Plate">
                <Form.Label>1. Semi Trailer Plate (1. Römork Plakası)</Form.Label>
                <Form.Control
                  value={form.VehicleTrailer1Plate}
                  onChange={(v) => setField('VehicleTrailer1Plate', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleTrailer1TypeId" className="col-2">
                <Form.Label>1. Semi Trailer Type (1. Römork Tipi)</Form.Label>
                <Form.Control
                  as="select"
                  value={form.VehicleTrailer1TypeId}
                  onChange={(v) => setField('VehicleTrailer1TypeId', v.target.value)}
                >
                  <option >Select</option>
                  {Object.keys(trailerType).map(elements => <option key={elements} value={trailerType[elements].Id}>{trailerType[elements].Name}</option>)}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleTrailer1ContainerTypeId">
                <Form.Label>1. Semi Trailer Container Type (1. Römork Konteyner Tipi)</Form.Label>
                <Form.Control
                  as="select"
                  value={form.VehicleTrailer1ContainerTypeId}
                  onChange={(v) => setField('VehicleTrailer1ContainerTypeId', v.target.value)}
                >
                  <option value={""}>Select</option>
                  {Object.keys(vehicleContainerType).map(elements => <option key={elements} value={vehicleContainerType[elements].Id}>{vehicleContainerType[elements].Name}</option>)}
                </Form.Control>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="VehicleTrailer1ContainerNumber">
                <Form.Label>1. Semi Trailer Container Number (1. Römork Konteyner Numarası)</Form.Label>
                <Form.Control
                  value={form.VehicleTrailer1ContainerNumber}
                  onChange={(v) => setField('VehicleTrailer1ContainerNumber', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleTrailer1ContainerSealNumber">
                <Form.Label>1. Semi Trailer Container Seal Numbers (1. Römork Konteyner Mühür Numarası)</Form.Label>
                <Form.Control
                  value={form.VehicleTrailer1ContainerSealNumber}
                  onChange={(v) => setField('VehicleTrailer1ContainerSealNumber', v.target.value)} />
              </Form.Group>
            </Row><hr />
            <Row className="mb-3">
              <Form.Group as={Col} controlId="VehicleTrailer2Plate">
                <Form.Label>2. Trailer Plate (2. Römork Plakası)</Form.Label>
                <Form.Control
                  value={form.VehicleTrailer2Plate}
                  onChange={(v) => setField('VehicleTrailer2Plate', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleTrailer2TypeId" >
                <Form.Label>2. Trailer Type (2. Römork Tipi)</Form.Label>
                <Form.Control
                  as="select"
                  value={form.VehicleTrailer2TypeId}
                  onChange={(v) => setField('VehicleTrailer2TypeId', v.target.value)}
                >
                  <option >Select</option>
                  {Object.keys(trailerType).map(elements => <option key={elements} value={trailerType[elements].Id}>{trailerType[elements].Name}</option>)}
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleTrailer2ContainerTypeId">
                <Form.Label>2. Trailer Container Type (2. Römork Konteyner Tipi)</Form.Label>
                <Form.Control
                  as="select"
                  value={form.VehicleTrailer2ContainerTypeId}
                  onChange={(v) => setField('VehicleTrailer2ContainerTypeId', v.target.value)}
                >
                  <option value={""}>Select</option>
                  {Object.keys(vehicleContainerType).map(elements => <option key={elements} value={vehicleContainerType[elements].Id}>{vehicleContainerType[elements].Name}</option>)}
                </Form.Control>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="VehicleTrailer1ContainerNumber">
                <Form.Label>2. Trailer Container Number (2. Römork Konteyner Numarası)</Form.Label>
                <Form.Control
                  value={form.VehicleTrailer2ContainerNumber}
                  onChange={(v) => setField('VehicleTrailer2ContainerNumber', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="VehicleTrailer2ContainerSealNumber">
                <Form.Label>2. Trailer Container Seal Numbers (2. Römork Konteyner Mühür Numarası)</Form.Label>
                <Form.Control
                  value={form.VehicleTrailer2ContainerSealNumber}
                  onChange={(v) => setField('VehicleTrailer2ContainerSealNumber', v.target.value)} />
              </Form.Group>
            </Row><hr />
            <Row className="mb-3">
              <Form.Group as={Col} controlId="TareMass">
                <Form.Label>Tare Mass (Dara Kütlesi)</Form.Label>
                <Form.Control
                  value={form.TareMass}
                  onChange={(v) => setField('TareMass', v.target.value)} type="number" />
              </Form.Group>
              <Form.Group as={Col} controlId="TotalGross">
                <Form.Label>Total Gross (Toplam Brüt)</Form.Label>
                <Form.Control
                  value={form.TotalGross}
                  onChange={(v) => setField('TotalGross', v.target.value)} type="number" />
              </Form.Group>

            </Row>
            <hr />
            <Row className="mb-3">
              <Typography variant="h5" gutterBottom className="mb-4 col-6">
                CONTAINER/VEHICLE PACKING CERTIFICATE
              </Typography>
              <Typography variant="h5" gutterBottom className="mb-4 col-6">
                RECEIVING ORGANIZATION RECEIPT
              </Typography>

            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="NameOfCompany">
                <Form.Label>Name Of Company (Firma Adı)</Form.Label>
                <Form.Control
                  value={form.NameOfCompany}
                  onChange={(v) => setField('NameOfCompany', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="HauliersName">
                <Form.Label>Hauliers Name (Taşıyıcı Adı)</Form.Label>
                <Form.Control
                  value={form.HauliersName}
                  onChange={(v) => setField('HauliersName', v.target.value)} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="NameOfDeclarant">
                <Form.Label>Name Of Declarant (Beyan Edenin Adı)</Form.Label>
                <Form.Control 
                  value={form.NameOfDeclarant}
                  onChange={(v) => setField('NameOfDeclarant', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="HaulierVehicleRegistrationNo">
                <Form.Label>Haulier Vehicle Registration No (Taşıyıcı Araç Kayıt No)</Form.Label>
                <Form.Control
                  value={form.FormNHaulierVehicleRegistrationNoame}
                  onChange={(v) => setField('HaulierVehicleRegistrationNo', v.target.value)} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="StatusOfDeclarant">
                <Form.Label>Status Of Declarant (Beyan Edenin Durumu)</Form.Label>
                <Form.Control
                  value={form.StatusOfDeclarant}
                  onChange={(v) => setField('StatusOfDeclarant', v.target.value)} />
              </Form.Group>
              <Form.Group as={Col} controlId="HaulierDate">
                <Form.Label>Haulier Date (Taşıyıcı Tarihi)</Form.Label>
                <Form.Control
                  value={form.HaulierDate}
                  onChange={(v) => setField('HaulierDate', v.target.value)} type="date" />
              </Form.Group>

            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="Place">
                <Form.Label>Place (Yer)</Form.Label>
                <Form.Control
                  value={form.Place}
                  onChange={(v) => setField('Place', v.target.value)} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="Date">
                <Form.Label>Date (Tarih)  </Form.Label>
                <Form.Control
                  value={form.Date}
                  onChange={(v) => setField('Date', v.target.value)} type="date" />
              </Form.Group>
            </Row>
            <hr />
            <Row className="mb-3">
              <Form.Group as={Col} controlId="ContactName">
                <Form.Label>Contact Name (İletişim İsmi)</Form.Label>
                <Form.Control
                  value={form.ContactName}
                  onChange={(v) => setField('ContactName', v.target.value)} type="text" />
              </Form.Group>

              <Form.Group as={Col} controlId="ContactNumber">
                <Form.Label>Contact Number (İletişim Numarası)</Form.Label>
                <Form.Control
                  value={form.ContactNumber}
                  onChange={(v) => setField('ContactNumber', v.target.value)} />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="AdditionalHandlingInformation">
                <Form.Label>Additional Handling Information (Ek Kullanım Bilgileri)</Form.Label>
                <Form.Control
                  value={form.AdditionalHandlingInformation}
                  onChange={(v) => setField('AdditionalHandlingInformation', v.target.value)} type="text" as="textarea" rows={3} />
              </Form.Group>


            </Row>





          </div>
          <Typography variant="h5" gutterBottom className="mb-4">

            Products (Ürünler)
          </Typography>

          {inputList.map((item, i) => {
            return <div key={inputList.length} >

              <Row className="mb-3">
                <Form.Group as={Col} controlId={"UNCodeId" + i}>
                  <Form.Label>UN Code Id (UN Kod Kimliği)</Form.Label>
                  <Form.Control
                    as="select" required
                    value={getValuesss(i)}
                    onChange={e => handleOnChanges(i, e)}
                  >
                    <option >Select</option>
                    {Object.keys(datas).map(elements => <option key={elements} value={datas[elements].Id}>{datas[elements].UNNumber} - {datas[elements].MinistryCode} - {datas[elements].Name}</option>)}
                  </Form.Control>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId={"Class" + i}>
                  <Form.Label>Class (Sınıf)</Form.Label>
                  <Form.Control disabled
                    value={getClassValue(i)}
                    onChange={(v) => setField('Class' + i, v.target.value)} type="number" />
                </Form.Group>

                <Form.Group as={Col} controlId={"SubClass" + i}>
                  <Form.Label>Sub Class (Alt Sınıf)</Form.Label>
                  <Form.Control disabled
                    value={getSubClassValue(i)}
                    onChange={(v) => setField('SubClass' + i, v.target.value)} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId={"PackingGroup" + i}>
                  <Form.Label>Packing Group (Paketleme Grubu)</Form.Label>
                  <Form.Control disabled
                    value={getPackingGroup(i)}
                    onChange={(v) => setField('PackingGroup' + i, v.target.value)} />
                </Form.Group>

                <Form.Group as={Col} controlId={"FlashPoint" + i}>
                  <Form.Label>Flash Point (Parlama Noktası)</Form.Label>
                  <Form.Control
                    value={form.FlashPoint}
                    onChange={(v) => setField('FlashPoint' + i, v.target.value)} />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} className="row" controlId={"MarinePollutant" + i}>
                  <Form.Label>Marine Pollutant (Deniz Kirleticisi)</Form.Label>

                  <Form.Group className="col-3" >
                    <Form.Check type="radio"
                      inline
                      label="YES"
                      name={"MarinePollutant"+i}
                      id="yes"
                      value={true}
                      onChange={e => onOptionChange(i, e)}
                    />
                  </Form.Group>
                  <Form.Group className="col-3">
                    <Form.Check type="radio"
                      inline
                      id="no"
                      label="NO"
                      name={"MarinePollutant"+i}
                      value={false}
                      onChange={e => onOptionChange(i, e)}
                    />
                  </Form.Group>
                </Form.Group>

                <Form.Group as={Col} controlId={"EMS" + i}>
                  <Form.Label>EMS (EMS) </Form.Label>
                  <Form.Control  disabled
                    value={getEMS0(i)}
                    onChange={(v) => setField('EMS' + i, v.target.value)} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId={"PackageCount" + i}>
                  <Form.Label>Package (Paket Sayısı)</Form.Label>
                  <Form.Control 
                    value={getPackage0(i)}
                    onChange={(v) => setField('PackageCount' + i, v.target.value)} type="number" />
                </Form.Group>

                <Form.Group as={Col} controlId={"PackageType" + i}>
                  <Form.Label>Package Type (Paket Tipi)</Form.Label>
                  <Form.Control 
                    value={getPackageType0(i)}
                    onChange={(v) => setField('PackageType' + i, v.target.value)} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="row" controlId={"LimitedQuantity" + i}>
                  <Form.Label>Limited Quantity (Sınırlı Miktar)</Form.Label>
                  <Form.Group className="col-3" >
                    <Form.Check type="radio"
                      inline
                      label="YES"
                      name={"LimitedQuantity" + i}
                      id="yes"
                      value={true}
                      onChange={e => onOptionChanges(i, e)}
                    />
                  </Form.Group>
                  <Form.Group className="col-3">
                    <Form.Check type="radio"
                      inline
                      id="no"
                      label="NO"
                      name={"LimitedQuantity" + i}
                      value={false}
                      onChange={e => onOptionChanges(i, e)}
                    />
                  </Form.Group>

                </Form.Group>

                <Form.Group as={Col} controlId={"ProperShippingName" + i}>
                  <Form.Label>Proper Shipping Name (Uygun Sevkiyat Adı)  </Form.Label>
                  <Form.Control required
                    value={form["ProperShippingName" + i]}
                    onChange={(v) => setField('ProperShippingName' + i, v.target.value)} />
                  <p><strong>Suggestion (Öneri):</strong> {getUnNameValue(i)}</p>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId={"GrossMass" + i}>
                  <Form.Label>Gross Mass (Brüt Kütle)</Form.Label>
                  <Form.Control required
                    value={form["GrossMass" + i]}
                    onChange={(v) => setField('GrossMass' + i, v.target.value)} type="number" />
                </Form.Group>
                <Form.Group as={Col} controlId={"NetMass" + i}>
                  <Form.Label>Net Mass (Net Kütle)</Form.Label>
                  <Form.Control required
                    value={form["NetMass" + i]}
                    onChange={(v) => setField('NetMass' + i, v.target.value)} type="number" />
                </Form.Group>

                <Form.Group as={Col} controlId={"Cube" + i}>
                  <Form.Label>Cube (Küp)</Form.Label>
                  <Form.Control required
                    value={form["Cube" + i]}
                    onChange={(v) => setField('Cube' + i, v.target.value)} type="number" />
                </Form.Group>
              </Row>

              <hr />

            </div>
          })
          }


          <hr />
          <Button variant="secondary" onClick={onAddBtnClick}>Add Product (Ürün Ekle)</Button>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Save (Kaydet)
            </Button>

          </Modal.Footer>
        </Form>
      </CardContent>
    </Card>
  );

};


import { Datagrid, TextField, } from 'react-admin';
import {
    useList,
    ListContextProvider,
    Title,FunctionField,
    useDataProvider,Link
  } from "react-admin";
  import 'bootstrap/dist/css/bootstrap.min.css';
  import React, { useEffect, useState,  } from "react";
  import {Card, CardContent } from "@mui/material";

export const TasimaEvrakListesi = () => {
  const dataProvider = useDataProvider();
  const [data, setData] = useState(null);
  const sort = { field: 'Id', order: 'ASC' };
  useEffect(() => {
if(!data)
      dataProvider.TasimaEvraklari()
          .then(({ data  }) => {
              setData(data);
          });
        
},[ data,dataProvider] );

const listContext = useList({ data,sort});

    return data && (
        
      <Card style={{ marginTop: "1em" }} >
      <Title title={"Document List"} />
      <CardContent>
      <ListContextProvider value={listContext} >
      <Datagrid bulkActionButtons={false} rowClick={false}
    
      >
        <TextField source="Id" label="ID" />
          <TextField label="Form Name" source="FormName" />
          <TextField label="Date" source="FormDate" />
          <TextField label="Sender Company Name" source="SenderCompanyName" />
          <TextField label="Receiver Company Name" source="ReceiverCompanyName" />
          
          <FunctionField
            render={(item) => (
              <Link to={"/TasimaEvrak2?a=1&pid=" +  item.Id}   >Print Preview</Link>
            )}
            source="Print"
            textAlign="center"
            className="button"
          />         
           {/* <Button variant="primary" onClick={() =>{setShow(true)}} >
            Print Preview
             </Button> */}
          </Datagrid>
          </ListContextProvider>
          </CardContent>
          {/* <MydModalWithGrid show={show} handleClose={handleClose} gelenveri={selectedId}/> */}
        </Card>
  );
};

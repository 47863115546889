import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { forwardRef } from 'react';
import { Menu, useLogout, useUserMenu } from 'react-admin';
import MenuItem from '@mui/material/MenuItem';
import PermContactCalendarOutlined from '@mui/icons-material/PermContactCalendarOutlined';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';

const MyLogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const { onClose } = useUserMenu();
    const handleClick = () => logout();
    return (
        <Menu>
         <MenuItem
            
            // It's important to pass the props to allow MUI to manage the keyboard navigation
            {...props}
            sx={{ color: 'text.secondary' }}
            onClick={event => {
              
                onClose(); // Close the menu
            }}
        >
            
        </MenuItem>
        <Menu.Item to="/Company" primaryText="Firma Profili" style={{marginLeft:"32px"}} leftIcon={<PermContactCalendarOutlined/>}/> 
        <Menu.Item to="/User" primaryText="Kullanıcı Profili" style={{marginLeft:"32px"}} leftIcon={<AccountCircleOutlined/>}/> 
        
        <MenuItem onClick={handleClick} style={{marginLeft:"32px"}} ref={ref} >
            <ExitIcon style={{marginRight:"15px"}} /> Logout
        </MenuItem>
        </Menu>
    );
});

export default MyLogoutButton;
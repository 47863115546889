import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/style.css';
import { useLogin, useNotify } from 'react-admin';
import register from './admin/register';
export default function (props) {
  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();

  const [Name, setFirstName] = useState(null);
  const [Surname, setLastName] = useState(null);
  const [Email, setEmailR] = useState(null);
  const [Password,setPasswordR] = useState(null);
  const [MobilePhone,setPhone] = useState(null);

  let [authMode, setAuthMode] = useState("signin")
  const handleSubmit = e => {
    console.log("bak:",username+"-"+password);
    e.preventDefault();
    login({ username, password }).catch(() =>
        notify('Invalid email or password')
    );
};
const handlesSubmit = e => {
  console.log("bak:",username+"-"+password);
  e.preventDefault();
  register({ Name, Surname, MobilePhone, Email, Password   }).catch(() =>
      notify('Invalid email or password')
  );
};
     const changeAuthMode = () => {
       setAuthMode(authMode === "signin" ? "signup" : "signin")
     }
   if (authMode === "signin") {
    
     return (
       <div className="Auth-form-container">
         <form className="Auth-form" >
           <div className="Auth-form-content">
             <h3 className="Auth-form-title">Sign In</h3>
             <div className="text-center">
               Not registered yet?{" "}
               <span className="link-primary" onClick={changeAuthMode}>
                 Sign Up
               </span>
             </div>
             <div className="form-group mt-3">
               <label>Email address</label>
               <input
                value={username}
                 name="username"
                 type="email"
                 className="form-control mt-1"
                 onChange={e => setEmail(e.target.value)}
               />
             </div>
             <div className="form-group mt-3">
               <label>Password</label>
               <input
               value={password}
                name="password"
                 type="password"
                 className="form-control mt-1"
                 onChange={e => setPassword(e.target.value)}
               />
             </div>
             <div className="d-grid gap-2 mt-3">
               <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                 Submit
               </button>
             </div>
           </div>
         </form>
       </div>
   )
   }
  
   return (
     <div className="Auth-form-container">
       <form className="Auth-form">
         <div className="Auth-form-content">
           <h3 className="Auth-form-title">Sign In</h3>
           <div className="text-center">
             Already registered?{" "}
             <span className="link-primary" onClick={changeAuthMode}>
               Sign In
             </span>
           </div>
           <div className="form-group mt-3">
             <label>Name</label>
             <input
              name='Name'
              value={Name}
              type="text"
              className="form-control mt-1"
              onChange={e => setFirstName(e.target.value)}
             />
           </div>
           <div className="form-group mt-3">
             <label>Surname</label>
             <input
              name='Surname'
              type="text"
              className="form-control mt-1"
              value={Surname}
              onChange={e => setLastName(e.target.value)}
             />
           </div>
           <div className="form-group mt-3">
             <label>Mobil Phone</label>
             <input
              name='MobilePhone'
              type="text"
              className="form-control mt-1"
              value={MobilePhone}
              onChange={e => setPhone(e.target.value)}
             />
           </div>
           <div className="form-group mt-3">
             <label>Email address</label>
             <input
              name='Email'
              type="email"
              className="form-control mt-1"
              placeholder="Email Address"
              value={Email}
              onChange={e => setEmailR(e.target.value)}
             />
           </div>
           <div className="form-group mt-3">
             <label>Password</label>
             <input
              name='Password'
              type="password"
              className="form-control mt-1"
              placeholder="Password"
              value={Password}
              onChange={e => setPasswordR(e.target.value)}
             />
           </div>
           <div className="d-grid gap-2 mt-3">
             <button type="submit" className="btn btn-primary" onClick={handlesSubmit}>
               Submit
             </button>
           </div>
         </div>
       </form>
     </div>
   )
 };
import axios from 'axios';
import { setAuthorizationToken } from './setAuthorizationToken';

const sleep = ms => new Promise(r => setTimeout(r, ms));


const authProvider = {

    login: ({ username, password }) =>  {
        const request = new Request('https://uranus.logfiks.com/api/User/Login', {
            method: 'POST',
            body: JSON.stringify( { Email:username, Password:password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(async (user) => {

                console.log("user",user);


                if (user.IsActive === 1) {

                    //console.log("giriş",user.data.Token);
                    const  gelenToken  = user.Token;
                    const userName = user.Name;
                    const surName = user.Surname;
                    const userID = user.Id;
                    localStorage.setItem("jwtToken", `Bearer ${gelenToken}`);
                    localStorage.setItem("userName", userName);
                    localStorage.setItem("surName", surName);
                    localStorage.setItem("userID", userID);
                    setAuthorizationToken(gelenToken);

                    await sleep(1000);
                    return Promise.resolve();
                }


            })
            .catch(() => {
                throw new Error('Network error');
            });
    },


   
    logout: () => {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('userName');
            localStorage.removeItem('surName');
            localStorage.removeItem('userID');
        return Promise.resolve();
    },
        checkAuth: () =>
        localStorage.getItem('jwtToken') ? Promise.resolve() : Promise.reject(),
    checkError:  (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            window.location.reload();
            // localStorage.removeItem('jwtToken');
            // localStorage.removeItem('userName');
            // localStorage.removeItem('surName');
            // localStorage.removeItem('userID');
            // return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>
        Promise.resolve( {
            id: localStorage.getItem("userID"),
            fullName: localStorage.getItem("userName")+" "+localStorage.getItem("surName"),}),
    getPermissions: () => Promise.resolve(''),
  };
  
  export default authProvider;
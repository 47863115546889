import React, { useState, } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Title,
    useDataProvider, useNotify
} from "react-admin";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card, CardContent, Typography } from "@mui/material";
import Modal from 'react-bootstrap/Modal';
export const YuklemeSertifika = (props) => {
    const notify = useNotify();
    const [validated, setValidated] = useState(null);
    const dataProvider = useDataProvider();
    const [data, setData] = useState(null);
    const [datas, setDatas] = useState(null);
    const [dataUn, setDataUn] = useState(null);
    const [form, setForm] = useState({});
    const [IMDGCodeClass, setIMDGCodeClass] = useState(null)
    const [IMDGCodeClass0, setIMDGCodeClass0] = useState([])
    const [IMDGclass0, setIMDGclass0] = useState([])
    const [ProperShippingName0, setProperShippingName0] = useState([])
    const [UnCodeId0, setUnCodeId0] = useState([])
    const [inputList, setInputList] = useState([]);
    const [UnName, setUnName] = useState([]);
    const [vehicleType, setVehicleType] = useState(null);
    const [trailerType, settrailerType] = useState(null);
    const [vehicleSubType, setVehicleSubType] = useState(null);
    const [vehicleContainerType, setVehicleContainerType] = useState(null);
    const [Varmi, setVarmi] = useState(true);
    const [vehicleTypeId, setVehicleTypeId] = useState(null);
    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value,
        })
       // console.log("secenek=", value);
        if (value == "no")
            alert("Hayır Seçeneği İşaretlendiğinde From Kullanılamaz!");
    }
   
    const onOptionChange = (event) => {
        var isaretlemes = event.target.id;
       // console.log("radio=", isaretlemes);
        if (isaretlemes === "IsFormPackingCertificate") {
            setField("IsFormPackingCertificate", true);
            setField("IsFormContainerOrVehicleLoadingCertificate", false);
        }
        if (isaretlemes === "IsFormContainerOrVehicleLoadingCertificate") {
            setField("IsFormPackingCertificate", false);
            setField("IsFormContainerOrVehicleLoadingCertificate", true);
        }

    };
    const [cevap1, setCevap1] = useState([]);
    const [cevap2, setCevap2] = useState([]);
    const [cevap3, setCevap3] = useState([]);
    const [cevap4, setCevap4] = useState([]);
    const [cevap5, setCevap5] = useState([]);
    const [cevap6, setCevap6] = useState([]);
    const [cevap7, setCevap7] = useState([]);
    const [cevap8, setCevap8] = useState([]);
    const [cevap9, setCevap9] = useState([]);

    const { sonuc } = cevap1;
    const { sonuc1 } = cevap2;
    const { sonuc2 } = cevap3;
    const { sonuc3 } = cevap4;
    const { sonuc4 } = cevap5;
    const { sonuc5 } = cevap6;
    const { sonuc6 } = cevap7;
    const { sonuc7 } = cevap8;
    const { sonuc8 } = cevap9;

    const handleChange = e => {
        e.persist();
        //console.log(e.target);

        setCevap1(prevState => ({
            ...prevState,
            sonuc: e.target.value,
        }));
        setField('IsContainerClean', e.target.value);
    };
    const handleChange2 = e => {
        e.persist();
        setCevap2(prevState => ({
            ...prevState,
            sonuc1: e.target.value,
        }));
        setField('IsPackagesSegregated', e.target.value);
    };
    const handleChange3 = e => {
        e.persist();
        setCevap3(prevState => ({
            ...prevState,
            sonuc2: e.target.value,
        }));
        setField('IsPackagesInspectedForDamage', e.target.value);
    };
    const handleChange4 = e => {
        e.persist();
        setCevap4(prevState => ({
            ...prevState,
            sonuc3: e.target.value,
        }));
        setField('IsDrumsStowed', e.target.value);
    };
    const handleChange5 = e => {
        e.persist();
        setCevap5(prevState => ({
            ...prevState,
            sonuc4: e.target.value,
        }));
        setField('IsGoodsEvenlyDistributed', e.target.value);
    };
    const handleChange6 = e => {
        e.persist();
        setCevap6(prevState => ({
            ...prevState,
            sonuc5: e.target.value,
        }));
        setField('IsContainerStructurallyServiceable', e.target.value);
    };
    const handleChange7 = e => {
        e.persist();
        setCevap7(prevState => ({
            ...prevState,
            sonuc6: e.target.value,
        }));
        setField('IsContainerAndPackagesProperlyMarked', e.target.value);
    };
    const handleChange8 = e => {
        e.persist();
        setCevap8(prevState => ({
            ...prevState,
            sonuc7: e.target.value,
        }));
        setField('Is5536Compliant', e.target.value);
    };
    const handleChange9 = e => {
        e.persist();
        setCevap9(prevState => ({
            ...prevState,
            sonuc8: e.target.value,

        }));
        setField('IsTransportDocumentsAvailable', e.target.value);
    };
    const handleCheck = (event) => {
        var isaretleme = event.target.value;
        if (event.target.checked) {
            isaretleme = true
        } else {
            isaretleme = false
        }
        console.log("VanningSurvey=",event.target.name,"-",isaretleme);
        setField(event.target.name, Boolean(isaretleme))
    };
    const getUnNameValue = (index) => {
        //console.log("veriCssName=", UnName);
        const val = UnName.filter(x => x.key === index);
        if (val.length > 0)
            return val[0].value;

        return "";

    }
    /* const getValue = (index) => {
        const val = ProperShippingName0.filter(x => x.key === index);
        if (val.length > 0)
            return val[0].value;

        return "";

    } */

    const getValuess = (index) => {

        const val = IMDGclass0.filter(x => x.key === index);
        if (val.length > 0)
            return val[0].value;

        return "";

    }
    const getValuesss = (index) => {
        const val = UnCodeId0.filter(x => x.key === index);
        if (val.length > 0)
            return val[0].value;

        return "";

    }

    const getValuessss = (index) => {
        const val = IMDGCodeClass0.filter(x => x.key === index);
        if (val.length > 0)
            return val[0].value;

        return "";

    }

    const getProduct = () => {

        const product = inputList.map((item, i) => {
            //console.log("urunler=>", item, "-", i)
            return {
                'UNCodeId': getValuesss(i),
                'ProperShippingName': form["ProperShippingName" + i],
                'NumberOfPackage': form["NumberOfPackage" + i],
                'GrossWeightOfEachPackage': form["GrossWeightOfEachPackage" + i],
                'IMDGCodeClass': getValuess(i),
                'IMDGCodeDivision': getValuessss(i),
                'UNHomologationOfPackage': form["UNHomologationOfPackage" + i],
                'Specifications': form["Specifications" + i],
            }

        });

        return product;
    }

    const formdatas = {
        'Form': {
            'FormName': form.FormName,
            'IsFormPackingCertificate': form.IsFormPackingCertificate,
            'IsFormContainerOrVehicleLoadingCertificate': form.IsFormContainerOrVehicleLoadingCertificate,
            'IsContainerClean': form.IsContainerClean,
            'IsPackagesSegregated': form.IsPackagesSegregated,
            'IsPackagesInspectedForDamage': form.IsPackagesInspectedForDamage,
            'IsDrumsStowed': form.IsDrumsStowed,
            'IsGoodsEvenlyDistributed': form.IsGoodsEvenlyDistributed,
            'IsContainerStructurallyServiceable': form.IsContainerStructurallyServiceable,
            'IsContainerAndPackagesProperlyMarked': form.IsContainerAndPackagesProperlyMarked,
            'Is5536Compliant': form.Is5536Compliant,
            'IsTransportDocumentsAvailable': form.IsTransportDocumentsAvailable,
            'PackagingPlace': form.PackagingPlace,
            'PackagingDate': form.PackagingDate,
            'PackagingConditions': form.PackagingConditions,
            'PackagingCompany': form.PackagingCompany,
            'PackagingNameOfDeclarant': form.PackagingNameOfDeclarant,
            'PackagingCTUCertificateNumber': form.PackagingCTUCertificateNumber,
            'LoadingConditions': form.LoadingConditions,
            'LoadingPlace': form.LoadingPlace,
            'LoadingDate': form.LoadingDate,
            'DriverName': form.DriverName,
            'VehiclePlate': form.VehiclePlate,
            'VehicleTypeId': vehicleTypeId,
            'VehicleSubTypeId': form.VehicleSubTypeId,
            'VehicleSealNumber': form.VehicleSealNumber,
            'VehicleContainer1TypeId': form.VehicleContainerTypeId,
            'VehicleContainer1Number': form.VehicleContainerNumber,
            'VehicleContainer1SealNumber': form.VehicleContainer1SealNumber,
            'VehicleContainer2TypeId': form.VehicleContainer2TypeId,
            'VehicleContainer2SealNumber': form.VehicleContainer2SealNumber,
            'VehicleContainer2Number': form.VehicleContainer2Number,
            'VehicleTrailer1TypeId': form.VehicleTrailer1TypeId,
            'VehicleTrailer1ContainerTypeId': form.VehicleTrailer1ContainerTypeId,
            'VehicleTrailer1ContainerNumber': form.VehicleTrailer1ContainerNumber,
            'VehicleTrailer1Plate': form.VehicleTrailer1Plate,
            'VehicleTrailer1SealNumber': form.VehicleTrailer1ContainerSealNumber,
            'VehicleTrailer2TypeId': form.VehicleTrailer2TypeId,
            'VehicleTrailer2ContainerTypeId': form.VehicleTrailer2ContainerTypeId,
            'VehicleTrailer2ContainerNumber': form.VehicleTrailer2ContainerNumber,
            'VehicleTrailer2Plate': form.VehicleTrailer2Plate,
            'VehicleTrailer2SealNumber': form.VehicleTrailer2ContainerSealNumber,
            'LoadingPortOfLoading': form.LoadingPortOfLoading,
            'LoadingPortOfUnloading': form.LoadingPortOfUnloading,
            'LoadingCompany': form.LoadingCompany,
            'LoadingNameOfDeclarant': form.LoadingNameOfDeclarant,
            'LoadingCTUCertificateNumber': form.LoadingCTUCertificateNumber,
            'VanningSurvey': form.VanningSurvey,
        },
        'Products': [
            {
                'UNCodeId': form.UNCodeId,
                'ProperShippingName': form.ProperShippingName,
                'NumberOfPackage': form.NumberOfPackage,
                'TypeOfPackage': form.TypeOfPackage,
                'GrossWeightOfEachPackage': form.GrossWeightOfEachPackage,
                'IMDGCodeClass': form.IMDGCodeClass,
                'IMDGCodeDivision': form.IMDGCodeDivision,
                'UNHomologationOfPackage': form.UNHomologationOfPackage,
                'Specifications': form.Specifications,
            }
        ]

    };
    formdatas.Products = getProduct();
    const handleSubmit = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
        dataProvider
            .YuklemeSertifikasi(
                JSON.stringify(formdatas)
            ).catch((error) => {
                console.log('Error ' + error);
                alert("Eksik Alanları Doldurunuz... ("+error+")");
                //window.location.reload();
              })
            .then(({ data }) => {
                notify(`Form Saved Successfully`, { type: "success", undoable: false });
            });
    };

    useState(() => {
        if (!datas)
            dataProvider.DataUNCode()
                .then(({ datas }) => {
                    setDatas(datas.data);
                });
    });

    const handleOnChanges = (key, e) => {
        const tempDataUN = [...UnCodeId0, { key: key, value: e.target.value }];
        setUnCodeId0(tempDataUN);

        dataProvider.DataUNCodeGetir(e.target.value)

            .then(({ dataUn }) => {
                if (dataUn.IsSubjectForADR === 0) {
                    alert("ADR'YE TABİ DEĞİLDİR.")
                } else {
                    setDataUn(dataUn);
                    const resultUnName = dataUn.Name;
                    const results = dataUn.Name.split(/(?=[a-z])/);
                    const resultss = dataUn.ClassCode.split(/(?=[a-z])/);
                    const resultsss = dataUn.Class.split(/(?=[a-z])/);
                    const tempDataUNName = [...UnName, { key: key, value: resultUnName }];
                    setUnName(tempDataUNName);
                    const tempData = [...ProperShippingName0, { key: key, value: results[0] }];
                    setProperShippingName0(tempData);
                    const tempDatas = [...IMDGclass0, { key: key, value: resultsss[0] }];
                    setIMDGclass0(tempDatas);
                    const tempDatass = [...IMDGCodeClass0, { key: key, value: resultss[0] }];
                    setIMDGCodeClass0(tempDatass);

                }

            });
    }

    const onAddBtnClick = event => {
        setInputList(inputList.concat(1
        ));
    };
    const vehicleOnChanges = (e) => {
        setVehicleTypeId(e.target.value)
        dataProvider.DataVehicleSubType(e.target.value)
    
          .then(({ datavehicle }) => {
            //console.log("arac=>", datavehicle);
    
            setVehicleSubType(datavehicle);
          });
    
      }
      useState(() => {
        if (!data)
          dataProvider.DataVehicleType()
            .then(({ data }) => {
              setVehicleType(data);
    
              setVehicleSubType("");
              setVehicleContainerType("");
    
            });
    
      });
    
      useState(() => {
        if (!data)
          dataProvider.DataVehicleContainerType()
            .then(({ data }) => {
              setVehicleContainerType(data);
              //console.log("container=>",data)
            });
      });
      useState(() => {
        if (!data)
          dataProvider.DataTrailerTypes()
            .then(({ data }) => {
              settrailerType(data);
            });
      });
      const sorucevap = (event) => {
        setVarmi(true)
      }
      const sorucevap1 = (event) => {
        setVarmi(false)
      }
    return datas && (
        <Card style={{ marginTop: "1em" }} >
            <Title title={"Packaging/Packing Certificate"} />
            <CardContent>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div>
                        <Row className="mb-12">
                            <Form.Group as={Col} controlId="FormName" style={{ paddingTop: 20 }}>
                                <Form.Label>Form Name</Form.Label>
                                <Form.Control required
                                    value={form.FormName}
                                    onChange={(v) => setField('FormName', v.target.value)}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3" style={{ paddingTop: 20, textAlign: "center" }}>

                            <Form.Group as={Col} controlId="IsFormContainerOrVehicleLoadingCertificate">
                                <Form.Check type="radio" checked
                                    inline
                                    id="IsFormContainerOrVehicleLoadingCertificate"
                                    label="Container-Vehicle Packing Certificate (Konteyner-Araç Yükleme Sertifikası)"
                                    name="grup2"
                                    value={"true"}
                                    onChange={onOptionChange}
                                />
                            </Form.Group>
                        </Row>
                        <hr />
                        <Row className="mb3">
                            <div className=" col-2"></div>
                            <Typography variant="h5" gutterBottom className="mb-4 col-5">
                                <strong>CONTAINER / VEHICLE  PACKING CERTIFICATE </strong>
                            </Typography>
                            <Typography variant="h5" gutterBottom className="mb-4 col-5">
                                <strong> KONTEYNER/ARAÇ YÜKLEME SERTİFİKASI</strong>
                            </Typography>
                        </Row>
                        <hr />
                        <Row className="mb-6">
                            <Form.Group className="col-2" >

                            </Form.Group>
                            <Form.Group className="col-5">
                                <p>Whenever hazardous goods are packed or loaded into a container or on a vehicle, those responsible for packing the container or vehicle shall provide a CONTAINER/VEHICLE PACKING CERTIFICATE specifying the container/vehicle identification number(s) and certifying that the operation has been carried out in accordance with the following conditions (cf. cl. 5.4.2.1 of the IMDG code). </p>
                            </Form.Group>
                            <Form.Group className="col-5" >
                                <p>Konteynere veya araca tehlikeli yük yüklenmesi durumunda yükün konteyner veya aracın yüklenmesinden sorumlu kişi / kişiler tarafından; konteyner numaraları / araç plakaları da belirtilen bu KONTEYNER / ARAÇ YÜKLEME SERTİFİKASI düzenlenmiştir ve aşağıda belirtilen şartların IMDG Kod 5.4.2.1 hükümleri uyarınca uygun olarak yüklemenin yapıldığını onaylanmıştır.</p>
                            </Form.Group>
                        </Row>
                        <hr />
                        <Row className="mb-6" >
                            <Form.Group className="col-2" style={{ textAlign: "center" }}><Form.Label>Check</Form.Label>
                            </Form.Group>
                            <Form.Group className="col-5">
                                <p><strong>Mark the items below by when the conditions have been met. </strong></p>
                            </Form.Group>
                            <Form.Group className="col-5" >
                                <p><strong>Aşağıda belirtilen şartlardan sağlananları işaretleyin.</strong></p>
                            </Form.Group>
                        </Row>
                        <hr />
                        <Row className="mb-6" >
                            <Form.Group className="col-2" style={{ textAlign: "center" }} controlId="IsContainerClean">
                                <Form.Check inline type="radio"
                                    value="yes"
                                    label="Yes"
                                    checked={sonuc === "yes"}
                                    onChange={handleChange}
                                />
                                <Form.Check inline type="radio"
                                    label="No"
                                    value={"no"}
                                    checked={sonuc === "no"}
                                    onChange={handleChange} />

                                <Form.Check inline
                                    type="radio"
                                    label="N/A"
                                    value={"na"}
                                    onChange={handleChange}
                                    checked={sonuc === "na"} />
                            </Form.Group>
                            <Form.Group className="col-5">
                                <p>The container/vehicle was clean, dry, and apparently fit to receive the goods. </p>
                            </Form.Group>
                            <Form.Group className="col-5" >
                                <p>Konteyner / araç, temiz, kuru ve yüklerin yerleştirilmesine uygundur.</p>
                            </Form.Group>
                        </Row>
                        <hr />
                        <Row className="mb-6" >
                            <Form.Group className="col-2" style={{ textAlign: "center" }} controlId="IsPackagesSegregated">
                                <Form.Check inline type="radio"
                                    value="yes"
                                    label="Yes"
                                    checked={sonuc1 === "yes"}
                                    onChange={handleChange2}
                                />
                                <Form.Check inline type="radio"
                                    label="No"
                                    value={"no"}
                                    checked={sonuc1 === "no"}
                                    onChange={handleChange2} />

                                <Form.Check inline
                                    type="radio"
                                    label="N/A"
                                    value={"na"}
                                    onChange={handleChange2}
                                    checked={sonuc1 === "na"} />
                            </Form.Group>
                            <Form.Group className="col-5">
                                <p>Packages, which need to be segregated in according with applicable segregation requirements, have not been packed together onto or in the container/vehicle (unless approved by the competent authority concerned in accordance with cl. 7.3.4.1 (in the IMDG code). </p>
                            </Form.Group>
                            <Form.Group className="col-5" >
                                <p>Uygulanabilir ayrıştırma kuralları uyarınca birbirinden ayrıştırılması gereken paketler ilgili düzenlemelere uygun olarak konteyner / araç içinde ayrıştırılmıştır. (Bu duruma IMDG Kod 7.3.4.1 göre ilgili yetkili otorite tarafından onay verilmişse mümkündür.)</p>
                            </Form.Group>
                        </Row>
                        <hr />
                        <Row className="mb-6" >
                            <Form.Group className="col-2" style={{ textAlign: "center" }} controlId="IsPackagesInspectedForDamage">
                                <Form.Check inline type="radio"
                                    label="Yes"
                                    value={"yes"}
                                    onChange={handleChange3}
                                    checked={sonuc2 === "yes"} />
                                <Form.Check inline type="radio"
                                    label="No"
                                    value={"no"}
                                    onChange={handleChange3}
                                    checked={sonuc2 === "no"} />

                                <Form.Check inline
                                    type="radio"
                                    label="N/A"
                                    value={"na"}
                                    checked={sonuc2 === "na"}
                                    onChange={handleChange3} />
                            </Form.Group>
                            <Form.Group className="col-5" >
                                <p>All packages have been externally inspected for damage, and only sound packages have been loaded. </p>
                            </Form.Group>
                            <Form.Group className="col-5" >
                                <p>Tüm paketler için gözle dıştan hasar kontrolü yapılmış ve sadece sağlam olan paketler yüklenmiştir.</p>
                            </Form.Group>
                        </Row>
                        <hr />
                        <Row className="mb-6" >
                            <Form.Group className="col-2" style={{ textAlign: "center" }} controlId="IsDrumsStowed">
                                <Form.Check inline type="radio"
                                    label="Yes"
                                    value={"yes"}
                                    onChange={handleChange4}
                                    checked={sonuc3 === "yes"} />

                                <Form.Check inline type="radio"
                                    label="No"
                                    value={"no"}
                                    onChange={handleChange4}
                                    checked={sonuc3 === "no"} />

                                <Form.Check inline
                                    type="radio"
                                    label="N/A"
                                    value={"na"}
                                    checked={sonuc3 === "na"}
                                    onChange={handleChange4} />
                            </Form.Group>

                            <Form.Group className="col-5" >
                                <p>Drums have been stowed in and upright position, unless otherwise authorized by the competent authority, and all goods have been properly loaded and, where necessary, adequately braced with securing material to suit the mode(s) of transport for the intended journey.</p>
                            </Form.Group>
                            <Form.Group className="col-5" >
                                <p>Yetkili otorite tarafından onaylanmış bir hüküm bulunmuyorsa, yüklenen variller, dik konumda istiflenmiştir ve tüm yükler doğru yerleştirilmiştir ve gerekli olduğu durumlarda taşıma sırasında taşıma modu/modlarına uygun güvenlik ve emniyet şartları sağlanmıştır.</p>
                            </Form.Group>
                        </Row>
                        <hr />
                        <Row className="mb-6" >
                            <Form.Group className="col-2" style={{ textAlign: "center" }} controlId="IsGoodsEvenlyDistributed">
                                <Form.Check inline type="radio"
                                    label="Yes"
                                    value={"yes"}
                                    onChange={handleChange5}
                                    checked={sonuc4 === "yes"} />

                                <Form.Check inline type="radio"
                                    label="No"
                                    value={"no"}
                                    onChange={handleChange5}
                                    checked={sonuc4 === "no"} />

                                <Form.Check inline
                                    type="radio"
                                    label="N/A"
                                    value={"na"}
                                    checked={sonuc4 === "na"}
                                    onChange={handleChange5} />
                            </Form.Group>
                            <Form.Group className="col-5" >
                                <p>Goods loaded in bulk have been evenly distributed within the container/vehicle.</p>
                            </Form.Group>
                            <Form.Group className="col-5" >
                                <p>Dökme olarak yüklenen yükler konteyner / araç içinde düzgün olarak dağıtılmıştır.</p>
                            </Form.Group>
                        </Row>
                        <hr />
                        <Row className="mb-6" >
                            <Form.Group className="col-2" style={{ textAlign: "center" }} controlId="IsContainerStructurallyServiceable">
                                <Form.Check inline type="radio"
                                    label="Yes"
                                    value={"yes"}
                                    onChange={handleChange6}
                                    checked={sonuc5 === "yes"} />

                                <Form.Check inline type="radio"
                                    label="No"
                                    value={"no"}
                                    onChange={handleChange6}
                                    checked={sonuc5 === "no"} />

                                <Form.Check inline
                                    type="radio"
                                    label="N/A"
                                    value={"na"}
                                    checked={sonuc5 === "na"}
                                    onChange={handleChange6} />

                            </Form.Group>
                            <Form.Group className="col-5">
                                <p>For consignments including goods of class 1 other than division 1.4, the container/vehicle is structurally serviceable in accordance with 7.1.2 (in the IMDG code).</p>
                            </Form.Group>
                            <Form.Group className="col-5" >
                                <p>Alt bölüm 1.4 dışında sınıf 1 olarak sınıflandırılmış yükler için, konteyner / araç IMDG Kod 7.1.2. maddesine uyarınca yapısal uygunluktadır. </p>
                            </Form.Group>
                        </Row>
                        <hr />
                        <Row className="mb-6" >
                            <Form.Group className="col-2" style={{ textAlign: "center" }} controlId="IsContainerAndPackagesProperlyMarked">
                                <Form.Check inline type="radio"
                                    label="Yes"
                                    value={"yes"}
                                    onChange={handleChange7}
                                    checked={sonuc6 === "yes"} />

                                <Form.Check inline type="radio"
                                    label="No"
                                    value={"no"}
                                    onChange={handleChange7}
                                    checked={sonuc6 === "no"} />

                                <Form.Check inline
                                    type="radio"
                                    label="N/A"
                                    value={"na"}
                                    checked={sonuc6 === "na"}
                                    onChange={handleChange7} />
                            </Form.Group>
                            <Form.Group className="col-5">
                                <p>The container/vehicle and packages are properly marked, labelled and placarded, as appropriate.  </p>
                            </Form.Group>
                            <Form.Group className="col-5" >
                                <p>Konteyner / araç ve paketler uygun şekilde işaretlenmiş, etiketlenmiş ve plakartlandırılmıştır.  </p>
                            </Form.Group>
                        </Row>
                        <hr />
                        <Row className="mb-6" >
                            <Form.Group className="col-2" style={{ textAlign: "center" }} controlId="Is5536Compliant">
                                <Form.Check inline type="radio"
                                    label="Yes"
                                    value={"yes"}
                                    onChange={handleChange8}
                                    checked={sonuc7 === "yes"} />

                                <Form.Check inline type="radio"
                                    label="No"
                                    value={"no"}
                                    onChange={handleChange8}
                                    checked={sonuc7 === "no"} />

                                <Form.Check inline
                                    type="radio"
                                    label="N/A"
                                    value={"na"}
                                    checked={sonuc7 === "na"}
                                    onChange={handleChange8} />
                            </Form.Group>
                            <Form.Group className="col-5">
                                <p>When substances presenting a risk of asphyxiation are used for cooling or conditioning purposes (such as dry ice (UN 1845) or nitrogen, refrigerated liquid (UN 1977) or argon, refrigerated liquid (UN 1951)), the container /vehicle is externally marked in accordance with 5.5.3.6; and;  </p>
                            </Form.Group>
                            <Form.Group className="col-5" >
                                <p>Soğutma ve iklimlendirme amacı ile kullanılan maddeler (KURU BUZ (UN 1845) veya AZOT, SOĞUTULMUŞ SIVI (UN 1977) veya ARGON, SOĞUTULMUŞ SIVI (UN 1951) gibi) yüklendiğinde 5.5.3.6 uyarınca konteyner/aracın dışında boğucu özellik riskini belirten işaret bulunmaktadır.  </p>
                            </Form.Group>
                        </Row>
                        <hr />
                        <Row className="mb-6" >
                            <Form.Group className="col-2" style={{ textAlign: "center" }} controlId="IsTransportDocumentsAvailable">
                                <Form.Check inline type="radio"
                                    label="Yes"
                                    value={"yes"}
                                    onChange={handleChange9}
                                    checked={sonuc8 === "yes"} />

                                <Form.Check inline type="radio"
                                    label="No"
                                    value={"no"}
                                    onChange={handleChange9}
                                    checked={sonuc8 === "no"} />

                                <Form.Check inline
                                    type="radio"
                                    label="N/A"
                                    value={"na"}
                                    checked={sonuc8 === "na"}
                                    onChange={handleChange9} />
                            </Form.Group>
                            <Form.Group className="col-5" >
                                <p>A dangerous goods transport document, as indicated in 5.4.1 (in the IMDG code) has been received for each dangerous goods consignment loaded in the container/vehicle. </p>
                            </Form.Group>
                            <Form.Group className="col-5" >
                                <p>Konteyner / Araca yüklenen her bir tehlikeli yük, IMDG Kod 5.4.1’de belirtilen tehlikeli yükler için bir taşıma belgesine sahiptir. </p>
                            </Form.Group>
                        </Row>
                        <hr />
                    </div>
                    <Typography variant="h5" gutterBottom className="mb-4">
                        <strong> Responsible Person of Packaging (Paketlemeden Sorumlu Kişi)</strong>
                    </Typography>
                    <div>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="PackagingPlace">
                                <Form.Label>Place (Yer) </Form.Label>
                                <Form.Control
                                    value={form.PackagingPlace}
                                    onChange={(v) => setField('PackagingPlace', v.target.value)} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="PackagingDate">
                                <Form.Label>Date (Tarih) </Form.Label>
                                <Form.Control
                                    value={form.PackagingDate}
                                    onChange={(v) => setField('PackagingDate', v.target.value)} type="date" />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group className="col-1" style={{ textAlign: "center" }} controlId="PackagingConditions">
                                <Form.Check inline name="PackagingConditions" onChange={handleCheck} />
                            </Form.Group>
                            <Form.Group className="col-10">
                                <Form.Label>The conditions above have been met at time of packing. (Paketleme sırasında yukarıda yer alan koşullar yerine getirilmiştir.)</Form.Label>

                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="PackagingCompany">
                                <Form.Label>Company (Firma)  </Form.Label>
                                <Form.Control
                                    value={form.PackagingCompany}
                                    onChange={(v) => setField('PackagingCompany', v.target.value)} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="PackagingNameOfDeclarant">
                                <Form.Label>Name of Declarant (Beyan Edenin Adı Soyadı)  </Form.Label>
                                <Form.Control
                                    value={form.PackagingNameOfDeclarant}
                                    onChange={(v) => setField('PackagingNameOfDeclarant', v.target.value)} />
                            </Form.Group>
                        </Row>
                        
                    </div>
                    <Typography variant="h5" gutterBottom className="mb-4">
                        <strong>Products (Ürünler)</strong>
                    </Typography>
                    <div>

                        <hr />
                        {inputList.map((item, i) => {
                            return <div key={inputList.length} >
                                <Typography variant="h5" gutterBottom className="mb-4">
                                    <strong>Product {i + 1} (Ürün {i + 1})</strong>
                                </Typography>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId={"UNCodeId" + i}>
                                        <Form.Label>UN No. (BM Nr) </Form.Label>
                                        <Form.Control
                                            as="select" required
                                            value={getValuesss(i)}
                                            onChange={e => handleOnChanges(i, e)}>
                                            <option >Select</option>
                                            {Object.keys(datas).map(elements => <option key={elements} value={datas[elements].Id}>{datas[elements].UNNumber} - {datas[elements].MinistryCode} - {datas[elements].Name}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId={"ProperShippingName" + i}>
                                        <Form.Label>Proper Shipping Name (Uygun Taşımacılık Adı): </Form.Label>
                                        <Form.Control required
                                            value={form["ProperShippingName" + i]}
                                            onChange={(v) => setField('ProperShippingName' + i, v.target.value)} />
                                        <p><strong>Suggestion (Öneri):</strong> {getUnNameValue(i)}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId={"GrossWeightOfEachPackage" + i}>
                                        <Form.Label>Gross Weight of Each Package (Her Bir Paketin Brüt Ağırlığı)</Form.Label>
                                        <Form.Control required
                                            value={form["GrossWeightOfEachPackage" + i]}
                                            onChange={(v) => setField('GrossWeightOfEachPackage' + i, v.target.value)} type="number" />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId={"NumberOfPackage" + i}>
                                        <Form.Label>Number of Package (Paket Sayısı) </Form.Label>
                                        <Form.Control required
                                            value={form["NumberOfPackage" + i]}
                                            onChange={(v) => setField('NumberOfPackage' + i, v.target.value)} type="number" />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId={"TypeOfPackage" + i}>
                                        <Form.Label>Type of Package (Paket Türü)</Form.Label>
                                        <Form.Control disabled
                                            value={form["TypeOfPackage" + i]}
                                            onChange={(v) => setField('TypeOfPackage' + i, v.target.value)} />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    {/*  {JSON.stringify(ProperShippingName0)} */}

                                    <Form.Group as={Col} controlId={"IMDGCodeClass" + i}>
                                        <Form.Label>IMDG Code Class (IMDG Kod Tehlike Sınıfı)</Form.Label>
                                        <Form.Control disabled
                                            value={getValuess(i)}
                                            onChange={(v) => setField('IMDGCodeClass' + i, v.target.value)} />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId={"IMDGCodeDivision" + i}>
                                        <Form.Label>IMDG Code Division (IMDG Kod Bölümü)</Form.Label>
                                        <Form.Control required disabled
                                            value={getValuessss(i)}
                                            onChange={(v) => setField('IMDGCodeDivision' + i, IMDGCodeClass)} />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId={"UNHomologationOfPackage" + i}>
                                        <Form.Label>UN Homologation of Package (Paketin UN Onay Kodu) </Form.Label>
                                        <Form.Control
                                            value={form["UNHomologationOfPackage" + i]}
                                            onChange={(v) => setField('UNHomologationOfPackage' + i, v.target.value)} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId={"Specifications" + i}>
                                        <Form.Label>Specifications (Özellikleri)</Form.Label>
                                        <Form.Control
                                            value={form["Specifications" + i]}
                                            onChange={(v) => setField('Specifications' + i, v.target.value)} />
                                    </Form.Group>
                                </Row>

                                <hr />

                            </div>
                        })}
                        <Button variant="secondary" onClick={onAddBtnClick}>Add Product (Ürün Ekle)</Button>
                    </div>
                    <hr />

                    <Typography variant="h5" gutterBottom className="mb-4">
                        <strong> Responsible Person of Loading (Yüklemeden Sorumlu Kişi)</strong>
                    </Typography>
                    <div>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="LoadingPlace">
                                <Form.Label>Place (Yer)</Form.Label>
                                <Form.Control
                                    value={form.LoadingPlace}
                                    onChange={(v) => setField('LoadingPlace', v.target.value)} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="LoadingDate">
                                <Form.Label>Date (Tarih) </Form.Label>
                                <Form.Control
                                    value={form.LoadingDate}
                                    onChange={(v) => setField('LoadingDate', v.target.value)} type="date" />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group className="col-1" style={{ textAlign: "center" }} controlId="LoadingConditions">
                                <Form.Check inline name="LoadingConditions" onChange={handleCheck} />
                            </Form.Group>
                            <Form.Group className="col-10">
                                <Form.Label>The conditions above have been met at time of loading the container/vehicle. (Konteyner/Araç yükleme sırasında yukarıda yer alan koşullar yerine getirilmiştir.)</Form.Label>

                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="DriverName">
                                <Form.Label>Driver Name (Sürücü Adı Soyadı)</Form.Label>
                                <Form.Control
                                    value={form.DriverName}
                                    onChange={(v) => setField('DriverName', v.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="TransporterTitle">
                                <Form.Label>Transporter Title (Taşıyıcı Adı)</Form.Label>
                                <Form.Control
                                    value={form.TransporterTitle}
                                    onChange={(v) => setField('TransporterTitle', v.target.value)} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">

                            <Form.Group as={Col} controlId="VehicleTypeId">
                                <Form.Label>Vehicle Type (Araç Tipi)</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={vehicleTypeId}
                                    onChange={e => vehicleOnChanges(e)}
                                >
                                    <option >Select</option>
                                    {Object.keys(vehicleType).map(elements => <option key={elements} value={vehicleType[elements].Id}>{vehicleType[elements].Name}</option>)}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="VehicleSubTypeId">
                                <Form.Label>Vehicle Subtype (Araç Alt Tipi)</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={form.VehicleSubTypeId}
                                    onChange={(v) => setField('VehicleSubTypeId', v.target.value)}
                                >
                                    <option value={""}>Select</option>
                                    {Object.keys(vehicleSubType).map(elements => <option key={elements} value={vehicleSubType[elements].Id}>{vehicleSubType[elements].Name}</option>)}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="VehiclePlate">
                                <Form.Label>Vehicle Plate (Araç Plakası)</Form.Label>
                                <Form.Control
                                    value={form.VehiclePlate1}
                                    onChange={(v) => setField('VehiclePlate', v.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="VehicleSealNumber">
                                <Form.Label>Vehicle Seal Numbers (Araç Mühür Numarası)</Form.Label>
                                <Form.Control
                                    value={form.VehicleSealNumber}
                                    onChange={(v) => setField('VehicleSealNumber', v.target.value)} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="row" >
                                <Form.Label>Is There a Container in the Vehicle? (Araçta Konteyner Var mı ?)</Form.Label>

                                <Form.Group className="col-3" >
                                    <Form.Check type="radio"
                                        inline
                                        label="YES"
                                        name="sorucevap"
                                        onChange={sorucevap}
                                        checked
                                    />
                                </Form.Group>
                                <Form.Group className="col-3">
                                    <Form.Check type="radio"
                                        inline
                                        label="No"
                                        name="sorucevap"
                                        onChange={sorucevap1}
                                    />
                                </Form.Group>
                            </Form.Group>

                        </Row>
                        {Varmi ? (
                            <div>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="VehicleContainerTypeId">
                                        <Form.Label>Vehicle 1. Container Type (Araç 1.Konteyner Tipi)</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={form.VehicleContainerTypeId}
                                            onChange={(v) => setField('VehicleContainerTypeId', v.target.value)}
                                        >
                                            <option value={""}>Select</option>
                                            {Object.keys(vehicleContainerType).map(elements => <option key={elements} value={vehicleContainerType[elements].Id}>{vehicleContainerType[elements].Name}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="VehicleContainerNumber">
                                        <Form.Label>Vehicle 1. Container Number (Araç 1.Konteyner Numarası)</Form.Label>
                                        <Form.Control
                                            value={form.VehicleContainerNumber}
                                            onChange={(v) => setField('VehicleContainerNumber', v.target.value)} />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="VehicleContainer1SealNumber">
                                        <Form.Label>Vehicle 1. Container Seal Numbers (Araç 1.Konteyner Mühür Numarası)</Form.Label>
                                        <Form.Control
                                            value={form.VehicleContainer1SealNumber}
                                            onChange={(v) => setField('VehicleContainer1SealNumber', v.target.value)} />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="VehicleContainer2TypeId">
                                        <Form.Label>Vehicle 2. Container Type (Araç 2.Konteyner Tipi)</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={form.VehicleContainer2TypeId}
                                            onChange={(v) => setField('VehicleContainer2TypeId', v.target.value)}
                                        >
                                            <option value={""}>Select</option>
                                            {Object.keys(vehicleContainerType).map(elements => <option key={elements} value={vehicleContainerType[elements].Id}>{vehicleContainerType[elements].Name}</option>)}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="VehicleContainer2Number">
                                        <Form.Label>Vehicle 2. Container Number (Araç 2.Konteyner Numarası)</Form.Label>
                                        <Form.Control
                                            value={form.VehicleContainer2Number}
                                            onChange={(v) => setField('VehicleContainer2Number', v.target.value)} />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="VehicleContainer2SealNumber">
                                        <Form.Label>Vehicle 2. Container Seal Numbers (Araç 2.Konteyner Mühür Numarası)</Form.Label>
                                        <Form.Control
                                            value={form.VehicleContainer2SealNumber}
                                            onChange={(v) => setField('VehicleContainer2SealNumber', v.target.value)} />
                                    </Form.Group>
                                </Row>
                            </div>
                        ) : null}
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="VehicleTrailer1Plate">
                                <Form.Label>1. Semi Trailer Plate (1. Römork Plakası)</Form.Label>
                                <Form.Control
                                    value={form.VehicleTrailer1Plate}
                                    onChange={(v) => setField('VehicleTrailer1Plate', v.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="VehicleTrailer1TypeId" className="col-2">
                                <Form.Label>1. Semi Trailer Type (1. Römork Tipi)</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={form.VehicleTrailer1TypeId}
                                    onChange={(v) => setField('VehicleTrailer1TypeId', v.target.value)}
                                >
                                    <option >Select</option>
                                    {Object.keys(trailerType).map(elements => <option key={elements} value={trailerType[elements].Id}>{trailerType[elements].Name}</option>)}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="VehicleTrailer1ContainerTypeId">
                                <Form.Label>1. Semi Trailer Container Type (1. Römork Konteyner Tipi)</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={form.VehicleTrailer1ContainerTypeId}
                                    onChange={(v) => setField('VehicleTrailer1ContainerTypeId', v.target.value)}
                                >
                                    <option value={""}>Select</option>
                                    {Object.keys(vehicleContainerType).map(elements => <option key={elements} value={vehicleContainerType[elements].Id}>{vehicleContainerType[elements].Name}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="VehicleTrailer1ContainerNumber">
                                <Form.Label>1. Semi Trailer Container Number (1. Römork Konteyner Numarası)</Form.Label>
                                <Form.Control
                                    value={form.VehicleTrailer1ContainerNumber}
                                    onChange={(v) => setField('VehicleTrailer1ContainerNumber', v.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="VehicleTrailer1ContainerSealNumber">
                                <Form.Label>1. Semi Trailer Container Seal Numbers (1. Römork Konteyner Mühür Numarası)</Form.Label>
                                <Form.Control
                                    value={form.VehicleTrailer1ContainerSealNumber}
                                    onChange={(v) => setField('VehicleTrailer1ContainerSealNumber', v.target.value)} />
                            </Form.Group>
                        </Row><hr />
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="VehicleTrailer2Plate">
                                <Form.Label>2. Trailer Plate (2. Römork Plakası)</Form.Label>
                                <Form.Control
                                    value={form.VehicleTrailer2Plate}
                                    onChange={(v) => setField('VehicleTrailer2Plate', v.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="VehicleTrailer2TypeId" >
                                <Form.Label>2. Trailer Type (2. Römork Tipi)</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={form.VehicleTrailer2TypeId}
                                    onChange={(v) => setField('VehicleTrailer2TypeId', v.target.value)}
                                >
                                    <option >Select</option>
                                    {Object.keys(trailerType).map(elements => <option key={elements} value={trailerType[elements].Id}>{trailerType[elements].Name}</option>)}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="VehicleTrailer2ContainerTypeId">
                                <Form.Label>2. Trailer Container Type (2. Römork Konteyner Tipi)</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={form.VehicleTrailer2ContainerTypeId}
                                    onChange={(v) => setField('VehicleTrailer2ContainerTypeId', v.target.value)}
                                >
                                    <option value={""}>Select</option>
                                    {Object.keys(vehicleContainerType).map(elements => <option key={elements} value={vehicleContainerType[elements].Id}>{vehicleContainerType[elements].Name}</option>)}
                                </Form.Control>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="VehicleTrailer1ContainerNumber">
                                <Form.Label>2. Trailer Container Number (2. Römork Konteyner Numarası)</Form.Label>
                                <Form.Control
                                    value={form.VehicleTrailer2ContainerNumber}
                                    onChange={(v) => setField('VehicleTrailer2ContainerNumber', v.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="VehicleTrailer2ContainerSealNumber">
                                <Form.Label>2. Trailer Container Seal Numbers (2. Römork Konteyner Mühür Numarası)</Form.Label>
                                <Form.Control
                                    value={form.VehicleTrailer2ContainerSealNumber}
                                    onChange={(v) => setField('VehicleTrailer2ContainerSealNumber', v.target.value)} />
                            </Form.Group>
                        </Row><hr />
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="TareMass">
                                <Form.Label>Tare Mass (Dara Kütlesi)</Form.Label>
                                <Form.Control
                                    value={form.TareMass}
                                    onChange={(v) => setField('TareMass', v.target.value)} type="number" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="TotalGross">
                                <Form.Label>Total Gross (Toplam Brüt)</Form.Label>
                                <Form.Control
                                    value={form.TotalGross}
                                    onChange={(v) => setField('TotalGross', v.target.value)} type="number" />
                            </Form.Group>

                        </Row>
                        <hr />
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="LoadingPortOfLoading">
                                <Form.Label>Port of Loading (Yükleme Limanı)  </Form.Label>
                                <Form.Control
                                    value={form.LoadingPortOfLoading}
                                    onChange={(v) => setField('LoadingPortOfLoading', v.target.value)} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="LoadingPortOfUnloading">
                                <Form.Label>Port of Unloading (Teslim Limanı)  </Form.Label>
                                <Form.Control
                                    value={form.LoadingPortOfUnloading}
                                    onChange={(v) => setField('LoadingPortOfUnloading', v.target.value)} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="LoadingCompany">
                                <Form.Label>Company (Firma)  </Form.Label>
                                <Form.Control
                                    value={form.LoadingCompany}
                                    onChange={(v) => setField('LoadingCompany', v.target.value)} />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="LoadingNameOfDeclarant">
                                <Form.Label>Name of Declarant (Beyan Edenin Adı Soyadı)  </Form.Label>
                                <Form.Control
                                    value={form.LoadingNameOfDeclarant}
                                    onChange={(v) => setField('LoadingNameOfDeclarant', v.target.value)} />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group className="col-1" style={{ textAlign: "center" }} controlId="VanningSurvey">
                                <Form.Check inline name="VanningSurvey" onChange={handleCheck} />
                            </Form.Group>
                            <Form.Group className="col-10">
                                <Form.Label>Vanning Survey</Form.Label>

                            </Form.Group>
                        </Row>
                    </div>

                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            Save (Kaydet)
                        </Button>

                    </Modal.Footer>
                </Form>
            </CardContent>
        </Card>
    );


};
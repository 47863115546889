import { Menu } from 'react-admin';
import ListOutlined from "@mui/icons-material/ListOutlined";
import MarkAsUnreadOutlined from "@mui/icons-material/MarkAsUnreadOutlined";
import LocalShipping from "@mui/icons-material/LocalShipping";
import MarkEmailUnreadOutlined from "@mui/icons-material/MarkEmailUnreadOutlined";


export const MyMenu = () => {
    return (
    <Menu>        
       <Menu.DashboardItem />
        {/*  <Menu.Item to="/" primaryText="Firma İşlemleri" leftIcon={<ListOutlined/>}/>
            <Menu.Item to="/AddFAddress" primaryText="Faaliyet Noktası Ekle"  style={{marginLeft:"32px"}} leftIcon={<MarkEmailUnreadOutlined/>}/>  
            <Menu.Item to="/CompanyAddress" primaryText="Faaliyet Noktaları"  style={{marginLeft:"32px"}} leftIcon={<MarkAsUnreadOutlined/>} /> */}
         <Menu.Item to="/" primaryText="Evrak İşlemleri" leftIcon={<ListOutlined/>}/>
            <Menu.Item to="/KaraDeniz" primaryText="Multi Modal"  style={{marginLeft:"35px"}} leftIcon={<MarkEmailUnreadOutlined/>}/>  
            <Menu.Item to="/TasimaEvrak" primaryText="Taşıma Evrakı"  style={{marginLeft:"35px"}} leftIcon={<MarkAsUnreadOutlined/>} />
            <Menu.Item to="/YuklemeSertifika" primaryText="Yükleme Sertifikası"  style={{marginLeft:"35px"}} leftIcon={<LocalShipping/>} />
         <Menu.Item to="/EvrakListesi" primaryText="Evrak Listesi" leftIcon={<ListOutlined/>} />
            <Menu.Item to="/EvrakListesi" primaryText="Multi Modal"  style={{marginLeft:"35px"}} leftIcon={<MarkEmailUnreadOutlined/>}/>  
            <Menu.Item to="/TaşımaEvrakListesi" primaryText="Taşıma Evrakı"  style={{marginLeft:"35px"}} leftIcon={<MarkAsUnreadOutlined/>} />
            <Menu.Item to="/YuklemeSertifikaListesi" primaryText="Yükleme Sertifikası"  style={{marginLeft:"35px"}} leftIcon={<LocalShipping/>} />
    </Menu>
    
    );
};